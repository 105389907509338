import { IBusinessLoadingReducer } from 'models/Loading'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  SetAcceptLoading,
  SetDeclineLoading,
  SetReviewLoading,
  SetNotificationPreferenceLoading,
  SetManagerActiveStatusLoading,
  SetFavoriteLoading,
  SetAssignedManagerLoading,
  SetUnassignedManagerLoading,
  SetPayInvoiceLoading,
} from './actions'

export type LoadingActions =
  | ReturnType<typeof SetAcceptLoading>
  | ReturnType<typeof SetDeclineLoading>
  | ReturnType<typeof SetReviewLoading>
  | ReturnType<typeof SetNotificationPreferenceLoading>
  | ReturnType<typeof SetManagerActiveStatusLoading>
  | ReturnType<typeof SetFavoriteLoading>
  | ReturnType<typeof SetAssignedManagerLoading>
  | ReturnType<typeof SetUnassignedManagerLoading>
  | ReturnType<typeof SetPayInvoiceLoading>

const initialState: IBusinessLoadingReducer = {
  acceptLoadingIds: {},
  declineLoadingIds: {},
  reviewLoadingIds: {},
  notificationPreferenceLoadingIds: {},
  managerActiveStatusLoadingIds: {},
  favoriteLoadingIds: {},
  assignedManagerLoadingIds: {},
  unassignedManagerLoadingIds: {},
  payInvoiceLoadingIds: {},
}

export const businessLoadingReducer = (
  state = initialState,
  action: Action,
): IBusinessLoadingReducer => {
  if (isType(action, SetAcceptLoading)) {
    const { payload } = action
    return {
      ...state,
      acceptLoadingIds: {
        ...state.acceptLoadingIds,
        [payload.id]: payload.value,
      },
    }
  }
  if (isType(action, SetDeclineLoading)) {
    const { payload } = action
    return {
      ...state,
      declineLoadingIds: {
        ...state.declineLoadingIds,
        [payload.id]: payload.value,
      },
    }
  }
  if (isType(action, SetReviewLoading)) {
    const { payload } = action
    return {
      ...state,
      reviewLoadingIds: {
        ...state.reviewLoadingIds,
        [payload.id]: payload.value,
      },
    }
  }
  if (isType(action, SetNotificationPreferenceLoading)) {
    const { payload } = action
    return {
      ...state,
      notificationPreferenceLoadingIds: {
        ...state.notificationPreferenceLoadingIds,
        [payload.id]: payload.value,
      },
    }
  }
  if (isType(action, SetManagerActiveStatusLoading)) {
    const { payload } = action
    return {
      ...state,
      managerActiveStatusLoadingIds: {
        ...state.managerActiveStatusLoadingIds,
        [payload.id]: payload.value,
      },
    }
  }
  if (isType(action, SetFavoriteLoading)) {
    const { payload } = action
    return {
      ...state,
      favoriteLoadingIds: {
        ...state.favoriteLoadingIds,
        [payload.id]: payload.value,
      },
    }
  }
  if (isType(action, SetAssignedManagerLoading)) {
    const { payload } = action
    return {
      ...state,
      assignedManagerLoadingIds: {
        ...state.assignedManagerLoadingIds,
        [payload.id]: payload.value,
      },
    }
  }
  if (isType(action, SetUnassignedManagerLoading)) {
    const { payload } = action
    return {
      ...state,
      unassignedManagerLoadingIds: {
        ...state.unassignedManagerLoadingIds,
        [payload.id]: payload.value,
      },
    }
  }
  if (isType(action, SetPayInvoiceLoading)) {
    const { payload } = action
    return {
      ...state,
      payInvoiceLoadingIds: {
        ...state.payInvoiceLoadingIds,
        [payload.id]: payload.value,
      },
    }
  }
  return state
}
