import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import { IPayload } from 'models/Global'
import {
  IGetGroupInvitesParams,
  IGroupInvite,
  IPostGroupInviteParams,
  IPutGroupInviteParams,
} from 'models/GroupInvite'

export class GroupInvitesApi {
  constructor(private axios: AxiosService) {}

  getGroupInvites = async (params?: IGetGroupInvitesParams) => {
    const response = await this.axios.get<IPayload<IGroupInvite[]>>(
      Endpoints.getGroupInvites,
      params,
    )
    return response
  }

  getGroupInvite = async (id: number, params?: IGetGroupInvitesParams) => {
    const response = await this.axios.get<IPayload<IGroupInvite>>(
      Endpoints.getGroupInvite(id),
      params,
    )
    return response
  }

  postGroupInvite = async (params?: IPostGroupInviteParams) => {
    const response = await this.axios.post<IPayload<IGroupInvite>>(
      Endpoints.postGroupInvite,
      params,
    )
    return response
  }

  putGroupInvite = async (id: number, params?: IPutGroupInviteParams) => {
    const response = await this.axios.put<IPayload<IGroupInvite>>(
      Endpoints.putGroupInvite(id),
      params,
    )
    return response
  }
}
