import history from '../history'

interface IParams {
  [key: string]: string | number
}

export class URLManager {
  public searchParams: URLSearchParams

  constructor(url?: string) {
    // When we resolve issues with handling url params with history next line should be uncommented
    // this.searchParams = new URLSearchParams(url || history.location.search)
    this.searchParams = new URLSearchParams(url || history.location.search)
  }

  // Get all params
  getParams() {
    const entries = this.searchParams.entries()
    let entriesObject = {}
    for (const entry of entries) {
      const [key, value] = entry
      entriesObject = {
        ...entriesObject,
        [key]: value,
      }
    }
    return entriesObject
  }

  // Get single parameter
  getParam(param: string): string | null {
    return this.searchParams.get(param)
  }

  //   Set single parameter in url
  setParam(param: string, value: string | number) {
    this.searchParams.set(param, value.toString())
  }

  // Sending object with key values that need to be appended to the url
  setParams(params: IParams) {
    for (const [key, value] of Object.entries(params)) {
      this.searchParams.set(key.toString(), value.toString())
    }
  }
  // Remove single parametar
  removeParam(param: any) {
    this.searchParams.delete(param.toString())
  }

  // Remove multiple parameters
  removeParams(params: string[]) {
    params.forEach(param => this.searchParams.delete(param))
  }

  // Remove multiple parameters and push state
  removeParamsAndPush(params: string[]) {
    params.forEach(param => this.searchParams.delete(param))
    this.pushState()
  }

  // Remove single parameter and push state
  removeParamAndPush(param: any) {
    this.searchParams.delete(param.toString())
    this.pushState()
  }

  // Removes all params just after this method you should use push state if you want to update the state
  removeAllParams() {
    const currentParams = this.getParams()
    const array = Object.entries(currentParams)
    array.forEach(element => {
      const [key] = element
      this.searchParams.delete(key)
    })
  }

  // Set params and push the state
  setParamsAndPush(params: IParams) {
    this.setParams(params)
    this.pushState()
  }

  // Removes all params and push the state
  removeAllParamsAndPush() {
    this.removeAllParams()
    this.pushState()
  }

  //  Append state to url
  pushState() {
    // const { pathname } = history.location
    // const query = `?${this.searchParams}`
    // const newUrl = `${pathname}${query}`
    // window.history.pushState({ path: newUrl }, '', newUrl)
    // When we resolve issues with handling url params with history next 2 lines should be uncommented
    const { pathname } = history.location
    history.push(`${pathname}?${this.searchParams}`)
  }
}
