import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import { IPayload } from 'models/Global'
import {
  IGetStatesParams,
  IPutStateParams,
  IState,
  IPostStateParams,
} from 'models/State'

export class StateApi {
  constructor(private axios: AxiosService) {}

  getStates = async (params?: IGetStatesParams) => {
    const response = await this.axios.get<IPayload<IState[]>>(
      Endpoints.getStates,
      params,
    )
    return response
  }

  getState = async (id: number, params?: IGetStatesParams) => {
    const response = await this.axios.get<IPayload<IState>>(
      Endpoints.getState(id),
      params,
    )
    return response
  }

  postState = async (params?: IPostStateParams) => {
    const response = await this.axios.post<IPayload<IState>>(
      Endpoints.postState,
      params,
    )
    return response
  }

  putState = async (id: number, params: IPutStateParams) => {
    const response = await this.axios.put<IPayload<IState>>(
      Endpoints.putState(id),
      params,
    )
    return response
  }

  deleteState = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteState(id))
    return response
  }
}
