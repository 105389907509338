import { IShiftAutoAcceptCertificateReducer } from 'models/ShiftAutoAcceptCertificate'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  GetShiftAutoAcceptCertificates,
  ShiftAutoAcceptCertificatesFetching,
  SetSelectedShiftAutoAcceptCertificates,
  ClearShiftAutoAcceptCertificates,
} from './actions'

const initialState: IShiftAutoAcceptCertificateReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const shiftAutoAcceptCertificatesReducer = (
  state = initialState,
  action: Action,
): IShiftAutoAcceptCertificateReducer => {
  if (isType(action, GetShiftAutoAcceptCertificates)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, SetSelectedShiftAutoAcceptCertificates)) {
    const { payload } = action
    const selectedCertificatesArray = state.data.map(autoAcceptCertificate => {
      for (let index = 0; index < payload.data.length; index += 1) {
        if (
          autoAcceptCertificate.certificate.id ===
          payload.data[index].certificate.id
        )
          return { ...autoAcceptCertificate, selected: true }
      }
      return {
        ...autoAcceptCertificate,
        selected: false,
      }
    })
    return {
      ...state,
      data: selectedCertificatesArray,
    }
  }
  if (isType(action, ClearShiftAutoAcceptCertificates)) {
    return {
      ...initialState,
    }
  }
  if (isType(action, ShiftAutoAcceptCertificatesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
