import styled, { keyframes } from 'styled-components'

export const Pulse = keyframes`
    0%{
        opacity:.1;
        transform:scale(1);
    }
    50%{
        opacity:0;
        transform:scale(.7);
    }
    100%{
        opacity:.1;
        transform:scale(1);
    }
`

export const ChunksLoaderWrap = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 50px);
`

export const Logo = styled.img`
  width: 100px;
  animation-name: ${Pulse};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
`
