import { IInvoiceReducer, IInvoiceData } from 'models/Invoice'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  GetInvoiceBatch,
  GetInvoice,
  GetInvoiceGroup,
  GetPropertiesForInvoice,
  GetTimesheetsForInvoice,
  GetAdditionalExpenseTypesForInvoice,
  GetCreditsForInvoice,
  InvoiceFetching,
  GetInvoiceErrors,
} from './actions'

const initialState: IInvoiceReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: {} as IInvoiceData,
}

export const invoiceReducer = (
  state = initialState,
  action: Action,
): IInvoiceReducer => {
  if (isType(action, GetInvoiceBatch)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: { ...payload.data },
    }
  }

  if (isType(action, GetInvoice)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: { ...state.data, invoice: payload.data },
    }
  }

  if (isType(action, GetInvoiceGroup)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: { ...state.data, invoiceGroup: payload.data },
    }
  }

  if (isType(action, GetPropertiesForInvoice)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: { ...state.data, properties: payload.data },
    }
  }

  if (isType(action, GetTimesheetsForInvoice)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: { ...state.data, timesheets: payload.data },
    }
  }

  if (isType(action, GetAdditionalExpenseTypesForInvoice)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: { ...state.data, additionalExpenseTypes: payload.data },
    }
  }

  if (isType(action, GetCreditsForInvoice)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: { ...state.data, credits: payload.data },
    }
  }

  if (isType(action, InvoiceFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, GetInvoiceErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }

  return state
}
