import {
  BUSINESS_PORTAL_LINK,
  UPSHIFTER_PORTAL_LINK,
} from 'config/envVariables'
import { FEATURE_TOGGLE_RESTRICT_LAST_MINUTE_CANDIDATE_ADDITIONS_IN_THE_VMS } from 'config/featureToggles'
import { ResetAuthReducer as ResetAuthReducerAction } from 'data/common/Auth/actions'
import { AuthActions } from 'data/common/Auth/thunks'
import {
  isUserBusiness,
  isUserUpshifter,
} from 'helpers/permissionsHelpers/roleHelpers'
import { IAchAccount } from 'models/AchAccount'
import { USER_ROLE } from 'models/Auth'
import { IBusinessEntityIncludeParams } from 'models/BusinessEntity'
import { TypeOrNull } from 'models/Common'
import { ICreditCard } from 'models/CreditCard'
import { IInvoice, INVOICE_PAID_STATUS } from 'models/Invoice'
import { PAYMENT_GATEWAY_ENUM } from 'models/Payment'
import { POPUP_ACTION_TYPE } from 'models/Shift'
import { IUser, UserRole } from 'models/User'
import { Dispatch } from 'react'
import ROUTES from 'routes/routes'

export interface IGeolocationPositionOptions {
  timeout?: number
  maximumAge?: number
  enableHighAccuracy?: boolean
  distanceFilter?: number
  useSignificantChanges?: boolean
}

export interface ILocation {
  latitude: TypeOrNull<number>
  longitude: TypeOrNull<number>
  status: boolean
  userPickedLocation: boolean
  timestamp: number
  city?: string
  regionId?: number
}

export type Procedure = (...args: any[]) => void

export type Options = {
  isImmediate: boolean
}
/**
 * A function that emits a side effect and does not return anything.
 */
export function debounce<F extends Procedure>(
  func: F,
  waitMilliseconds = 50,
  options: Options = {
    isImmediate: false,
  },
): (this: ThisParameterType<F>, ...args: Parameters<F>) => void {
  let timeoutId: ReturnType<typeof setTimeout> | undefined

  return function(this: ThisParameterType<F>, ...args: Parameters<F>) {
    const context = this

    const doLater = function() {
      timeoutId = undefined
      if (!options.isImmediate) {
        func.apply(context, args)
      }
    }

    const shouldCallNow = options.isImmediate && timeoutId === undefined

    if (timeoutId !== undefined) {
      clearTimeout(timeoutId)
    }

    timeoutId = setTimeout(doLater, waitMilliseconds)

    if (shouldCallNow) {
      func.apply(context, args)
    }
  }
}

// Redux helper functions
export const updateArray = <T extends { id: number; [key: string]: any }>(
  data: T[],
  payload: T,
): T[] => {
  const updatedArray = data.map(item => {
    if (item.id !== payload.id) {
      return item
    }
    return payload
  })
  return updatedArray
}

export const deleteFromArray = <T extends { id: number; [key: string]: any }>(
  data: T[],
  id: number,
): T[] => {
  const updatedArray = data.filter(item => item.id !== id)
  return updatedArray
}

export const addToArray = <T extends { id: number; [key: string]: any }>(
  item: T,
  data: T[],
): T[] => [item, ...data]

export const maybeAddToArray = <T extends { id: number; [key: string]: any }>(
  data: T[],
  item: T,
): T[] => {
  const exists = data.find(element => element.id === item.id)

  if (!exists) {
    return [item, ...data]
  } else return data
}

/**
 * Creates a file from a Blob received from an API and downloads it
 * @param response The response that should be put in a file
 * @param fileName How should the file be named when downloaded
 */
export const createAndDownloadFile = (response: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([response]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}

/**
 * Create valid form data from a simple or a complex/nested object
 * @param object
 * @param form
 * @param namespace
 */
export const createFormDataFromObject = (
  object: Object,
  form?: FormData,
  namespace?: string,
): FormData => {
  const formData = form || new FormData()
  for (const property in object) {
    if (!object.hasOwnProperty(property) || !(object as any)[property]) {
      continue
    }
    const formKey = namespace ? `${namespace}[${property}]` : property
    if ((object as any)[property] instanceof Date) {
      formData.append(formKey, (object as any)[property].toISOString())
    } else if (
      typeof (object as any)[property] === 'object' &&
      !((object as any)[property] instanceof File)
    ) {
      createFormDataFromObject((object as any)[property], formData, formKey)
    } else {
      formData.append(formKey, (object as any)[property])
    }
  }
  return formData
}

/**
 * This function transforms the user object we fetch from BND
 * by adding a role attribute
 * @param user User object which we fetch from the BND
 * @returns User object with role attribute added
 */
export const addRoleToUser = (user: IUser) => {
  let updatedUser = user
  switch (user.roles[0].name) {
    case UserRole.BUSINESS:
      updatedUser = { ...updatedUser, role: updatedUser.roles[0].name }
      break
    case UserRole.APPROVER:
    case UserRole.VIEWER:
    case UserRole.FINANCE_MANAGER:
    case UserRole.EMPLOYEE:
      updatedUser = {
        ...updatedUser,
        role: updatedUser.roles[0].name,
        business_id: user.employer_business_id,
      }
      break
    case 'labor':
      updatedUser = { ...updatedUser, role: USER_ROLE.UPSHIFTER }
      break
    default:
      break
  }
  return updatedUser
}

/**
 * Generates and Downloads a file
 * @param response (Blob)
 * @param fileName (File name string excluding the extension)
 * @param ext (Downloaded file extension)
 */
export const createAndDownloadReport = (
  response: Blob,
  fileName: string,
  ext: 'xls' | 'csv' | 'zip',
) => {
  const url = window.URL.createObjectURL(new Blob([response]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${fileName}.${ext}`)
  document.body.appendChild(link)
  link.click()
}

/**
 * Promisified geolocation api
 *
 * @param options Geolocation options
 * @returns
 */
export const getPosition = (
  options?: IGeolocationPositionOptions,
): Promise<GeolocationPosition> => {
  return new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject, options),
  )
}

/**
 * Set location object in local storage
 *
 * @param location Location object
 */
export const setLocation = (location: ILocation) => {
  localStorage.setItem('location', JSON.stringify(location))
}

/**
 * Fetches a file from URL. It is converted to blob and the blob is used to construct a File object (ready to use in Dropzone).
 * We need the file in this format so we can preview the attachment within Dropzone component from the API request.
 * @param {*} attachmentUrl
 * @param {*} fileName
 * @returns File || null
 */
export const convertFromUrlToObject = async (
  attachmentUrl: string,
  fileName: string,
) => {
  try {
    const attachment = await fetch(attachmentUrl)
    const blob = await attachment.blob()
    const file = new File([blob], fileName, { type: blob.type })
    return file
  } catch (error) {
    return null
  }
}

/**
 * This function returns the users location if location permission is allowed
 * and returns an object with latitude: null and longitude: null
 * if the users' location permission is declined
 */
export const fetchLocation = async (): Promise<ILocation> => {
  const { geolocation } = navigator
  return new Promise((resolve, reject) => {
    const currentDate = new Date()
    geolocation.getCurrentPosition(
      // This executes when the user clicks Allow location
      position => {
        const { coords } = position
        const { latitude, longitude } = coords
        const locationLocked = {
          latitude,
          longitude,
          status: true,
          userEnabled: true,
          userPickedLocation: false,
          timestamp: currentDate.getTime(),
        }
        localStorage.setItem('location', JSON.stringify(locationLocked))
        resolve(locationLocked)
      },
      // This executes when the user clicks Decline location
      () => {
        const locationLocked = {
          latitude: null,
          longitude: null,
          userEnabled: false,
          status: false,
          userPickedLocation: false,
          timestamp: currentDate.getTime(),
        }
        resolve(locationLocked)
      },
    )
  })
}

/**
 * This function checks if the logged in user is on the correct domain,
 * if he's not on the correct domain, he's being redirected and logged in automatically
 * using the access token we get from the BND which we pass to the authentication route
 * of the specified project
 * @param role - the role of the user that is logging into the application
 * @param token - the access token of the user that is logging into the application
 * @returns boolean - returns a boolean value which gives the information if the user is being redirected
 */
export const redirectUserToSplitDomains = (
  role: USER_ROLE,
  token: string,
  dispatch: Dispatch<AuthActions>,
) => {
  if (isUserBusiness(role) && window.location.origin !== BUSINESS_PORTAL_LINK) {
    dispatch(ResetAuthReducerAction())
    window.location.assign(
      `${BUSINESS_PORTAL_LINK}${ROUTES.AUTHENTICATION}?token=${token}`,
    )
    return true
  } else if (
    isUserUpshifter(role) &&
    window.location.origin !== UPSHIFTER_PORTAL_LINK
  ) {
    dispatch(ResetAuthReducerAction())
    window.location.assign(
      `${UPSHIFTER_PORTAL_LINK}${ROUTES.AUTHENTICATION}?token=${token}`,
    )
    return true
  }
  return false
}

export const transformSelectedRowsToArray = (rowSelection: {}) => {
  return Object.keys(rowSelection)
}

// Function that checks if background check is needed when apply/claim for a shift
export const checkIfShouldAskForBackgroundCheck = (
  pop_up_action_type?: POPUP_ACTION_TYPE,
) =>
  pop_up_action_type !== undefined &&
  (pop_up_action_type === POPUP_ACTION_TYPE.POP_UP_ACTION_OKAY ||
    pop_up_action_type === POPUP_ACTION_TYPE.POP_UP_ACTION_SUBMIT_CONSENT ||
    pop_up_action_type ===
      POPUP_ACTION_TYPE.POP_UP_ACTION_WOULD_LIKE_TO_SUBMIT_CONSENT)

// Function that checks if drug testing is needed when apply/claim for a shift
export const checkIfShouldAskForDrugTesting = (
  pop_up_action_type?: POPUP_ACTION_TYPE,
) =>
  pop_up_action_type !== undefined &&
  pop_up_action_type === POPUP_ACTION_TYPE.POP_UP_ACTION_DRUG_SCREENING

/**
 * Returns includes needed for BusinessEntity when using thunks like getBusinessEntity, putBusinessEntity
 * @returns IBusinessEntityIncludeParams['include']
 */
export const getBusinessEntityIncludes = () => {
  const businessEntityIncludes: IBusinessEntityIncludeParams['include'] = [
    'gig_identifier_policy',
    'account_manager',
    'ach_account',
    'business_setting',
  ]
  if (FEATURE_TOGGLE_RESTRICT_LAST_MINUTE_CANDIDATE_ADDITIONS_IN_THE_VMS) {
    businessEntityIncludes.push('vendor_management_system')
  }
  return businessEntityIncludes
}

export const maskCreditCardNumber = (creditCard?: TypeOrNull<ICreditCard>) => {
  return creditCard?.last4 ? `**** **** **** ${creditCard.last4}` : '/'
}

export const maskAchAccountNumber = (achAccount?: TypeOrNull<IAchAccount>) => {
  return achAccount?.account_number_last_two
    ? `******${achAccount.account_number_last_two}`
    : '/'
}

/**
 * Returns the total of the Invoice depending on the paid status and the payment method used
 * @param invoice
 * @returns invoice total
 */
export const getInvoiceTotal = (
  invoice: IInvoice,
  paymentGatewayEnum?: PAYMENT_GATEWAY_ENUM,
) => {
  // When the user pays Invoice with NET payment type by Credit Card, he pays an additional fee (invoice.payment_fee).
  // Because of this, we are showing the invoice.total_with_payment_fee as Invoice total when Invoice was paid with NET-Credit Card.
  // Otherwise we are showing invoice.total as Invoice total on Invoices & View Invoice pages.
  if (
    invoice.paid_status !== INVOICE_PAID_STATUS.NOT_PAID &&
    paymentGatewayEnum === PAYMENT_GATEWAY_ENUM.CREDIT_CARD_WITH_FEE &&
    invoice.total_with_payment_fee
  ) {
    return invoice.total_with_payment_fee
  }
  return invoice.total
}

/**
 * Determines whether properties can be assigned for specific business user role
 * @param businessUserRole - specific business user role
 * @returns boolean - whether properties can be assigned to the business user role sent as param
 */
export const canAssignPropertiesToBusinessUser = (
  businessUserRole?: USER_ROLE,
) => {
  if (typeof businessUserRole === 'undefined') return false
  return [USER_ROLE.EMPLOYEE, USER_ROLE.VIEWER].includes(businessUserRole)
}
