import { TEMPLATES_ALLOWED } from 'config/envVariables'
import AuthService from 'helpers/authService'
import {
  isUserPendingSuspension,
  isUserTemporarilySuspended,
} from 'helpers/permissionsHelpers/roleHelpers'
import { USER_ROLE } from 'models/Auth'
import { UserRole } from 'models/User'

export const employeeAllowedRoutes = [
  'shifts',
  'shift',
  'shift/edit',
  'shift/edit/:id',
  'shift/duplicate',
  'shift/duplicate/:id',
  'locations',
  'locations/add',
  'locations/:id',
  'my_upshifters',
  'my_managers',
  'my_managers/add',
  'my_managers/edit',
  'users',
  'users/add',
  'users/:id',
  'reports',
  'reports/my_reports',
  'reports/return_rate',
  'settings',
  'notifications',
  'timesheets',
  'timesheets/:id',
  'timesheet',
  'templates',
  'templates/add',
  'templates/edit/:id',
  'invoices',
  'invoices/:id',
  'pending_verifications',
  'active_shift',
  'logout',
  'consolidated_view',
  'acknowledged_documents',
  'contact_us',
  'access_restricted',
]

export const approverAllowedRoutes = [
  'shifts',
  'shift',
  'my_upshifters',
  'settings',
  'notifications',
  'timesheets',
  'timesheets/:id',
  'timesheet',
  'pending_verifications',
  'active_shift',
  'logout',
  'consolidated_view',
  'contact_us',
  'access_restricted',
]

export const viewerAllowedRoutes = [
  'shifts',
  'shift',
  'settings',
  'notifications',
  'timesheets',
  'timesheets/:id',
  'timesheet',
  'pending_verifications',
  'active_shift',
  'consolidated_view',
  'logout',
  'contact_us',
  'access_restricted',
]

export const businessAllowedRoutes = [
  'shifts',
  'shift',
  'shift/edit',
  'shift/edit/:id',
  'shift/duplicate',
  'shift/duplicate/:id',
  'locations',
  'locations/add',
  'locations/:id',
  'my_upshifters',
  'reports',
  'reports/my_reports',
  'reports/return_rate',
  'settings',
  'notifications',
  'my_managers',
  'my_managers/add',
  'my_managers/edit',
  'users',
  'users/add',
  'users/:id',
  'timesheets',
  'timesheets/:id',
  'timesheet',
  'invoices',
  'invoices/:id',
  'pending_verifications',
  'templates',
  'templates/add',
  'templates/edit/:id',
  'active_shift',
  'logout',
  'consolidated_view',
  'acknowledged_documents',
  'contact_us',
  'access_restricted',
]

export const financeManagerAllowedRoutes = [
  'contact_us',
  'invoices',
  'invoices/:id',
  'pending_verifications',
  'settings',
  'logout',
  'access_restricted',
]

export const defaultRedirectRoutePerRole = {
  [USER_ROLE.BUSINESS]: '/shifts',
  [USER_ROLE.EMPLOYEE]: '/shifts',
  [USER_ROLE.UPSHIFTER]: '/shifts',
  [USER_ROLE.APPROVER]: '/shifts',
  [USER_ROLE.VIEWER]: '/shifts',
  [USER_ROLE.LABOR]: '/shifts',
  [USER_ROLE.FINANCE_MANAGER]: '/invoices',
}

/**
 * Returns the default redirect route for a given role.
 * If no role is specified, it returns the '/login' route as default
 * @param {string} role The role to get the default route for
 */
export const getDefaultRedirectRoutePerRole = role => {
  if (!role) {
    return '/login'
  }

  return defaultRedirectRoutePerRole[role]
}

export const specialUsersAllowedRoutes = {
  templates: TEMPLATES_ALLOWED.split(','),
}

// TODO: Refactor this function
export const checkIfRouteIsAllowed = (
  userRole,
  route,
  checkSpecialUserAllowed = false,
  email = '',
) => {
  const businessEntity = AuthService.getBusinessEntity()

  if (businessEntity) {
    if (
      businessEntity.enabled_documents_count < 1 &&
      route === 'acknowledged_documents'
    )
      return false

    if (!businessEntity.who_is_coming_tomorrow && route === 'consolidated_view')
      return false
  }

  if (isUserTemporarilySuspended() || isUserPendingSuspension()) {
    return tempSuspendedUpshifterAllowedRoutes.includes(route)
  }

  if (userRole === null) return false
  switch (userRole) {
    case UserRole.APPROVER:
      if (checkSpecialUserAllowed) {
        return (
          approverAllowedRoutes.includes(route) &&
          specialUsersAllowedRoutes[route].includes(email)
        )
      }
      return approverAllowedRoutes.includes(route)
    case UserRole.VIEWER:
      if (checkSpecialUserAllowed) {
        return (
          viewerAllowedRoutes.includes(route) &&
          specialUsersAllowedRoutes[route].includes(email)
        )
      }
      return viewerAllowedRoutes.includes(route)
    case UserRole.EMPLOYEE:
      if (checkSpecialUserAllowed) {
        return (
          employeeAllowedRoutes.includes(route) &&
          specialUsersAllowedRoutes[route].includes(email)
        )
      }
      return employeeAllowedRoutes.includes(route)

    case UserRole.BUSINESS:
      if (checkSpecialUserAllowed) {
        return (
          businessAllowedRoutes.includes(route) &&
          specialUsersAllowedRoutes[route].includes(email)
        )
      }
      return businessAllowedRoutes.includes(route)
    case UserRole.FINANCE_MANAGER:
      if (checkSpecialUserAllowed) {
        const result =
          financeManagerAllowedRoutes.includes(route) &&
          specialUsersAllowedRoutes[route].includes(email)
        return result
      }
      const outResult = financeManagerAllowedRoutes.includes(route)
      return outResult
    case UserRole.UPSHIFTER:
      return upshifterAllowedRoutes.includes(route)
    default:
      return false
  }
}

export default '*'
