import {
  ITimesheetReducer,
  ITimesheetData,
  ITimesheetShift,
  ITimesheetApplication,
  ITimesheetIPunchCard,
  IGigDayExtended,
  ITimesheet,
} from 'models/Timesheet'
import { IShift } from 'models/Shift'
import { IApplication } from 'models/Application'
import { IPunchCard } from 'models/PunchCard'
import _ from 'lodash'
import { IBonus, IDeleteBonusParams } from 'models/Bonus'
import { IError, IPayload } from 'models/Global'
import { IProperty } from 'models/Property'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  AddTimesheetBonus,
  AddTimesheetPunchCard,
  AddTimesheetPunchCardError,
  ClearTimesheet,
  DeleteTimesheetBonus,
  DeleteTimesheetPunchCard,
  GetTimesheet,
  GetTimesheetApplications,
  GetTimesheetApplicationsFetching,
  GetTimesheetBonuses,
  GetTimesheetErrors,
  GetTimesheetGigDays,
  GetTimesheetProperty,
  GetTimesheetPropertyFetching,
  GetTimesheetPunchCards,
  GetTimesheetPunchCardsFetching,
  GetTimesheetShifts,
  GetTimesheetShiftsFetching,
  TimesheetFetching,
  UpdateApplicationBlockPropertiesCount,
  UpdateTimesheet,
  UpdateTimesheetBonus,
  UpdateTimesheetPunchCard,
} from './actions'

const initialState: ITimesheetReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: {
    shifts: {},
  } as ITimesheetData,
  error: {} as IError,
}

const transformShifts: (
  shifts: IShift[],
) => {
  [key: number]: ITimesheetShift
} = shifts => {
  const timesheetShifts = shifts.reduce(
    (result: { [key: number]: ITimesheetShift }, current: IShift) => {
      return {
        ...result,
        [current.id]: {
          ...current,
          applications: {} as { [key: number]: ITimesheetApplication },
        },
      }
    },
    {} as { [key: number]: ITimesheetShift },
  )

  return timesheetShifts
}

const transformApplications: (
  applications: IApplication[],
) => ITimesheetApplication[] = applications => {
  const timesheetApplications = applications.map(
    (application: IApplication) => {
      return {
        ...application,
        punchcards: {} as { [key: number]: ITimesheetIPunchCard },
        bonuses: {} as { [key: number]: IBonus },
      }
    },
  )

  return timesheetApplications
}

const connectShiftsAndApplications: (
  applications: ITimesheetApplication[],
  state: ITimesheetReducer,
) => { [key: number]: ITimesheetShift } = (applications, state) => {
  const {
    data: { shifts },
  } = state

  const applicationsByShift = applications.reduce(
    (
      result: {
        [key: number]: {
          applications: { [key: number]: ITimesheetApplication }
        }
      },
      current: ITimesheetApplication,
    ) => {
      const shiftId = current['gig_id']
      const applicationId = current.id

      if (!result[shiftId]) {
        result[shiftId] = {} as {
          applications: { [key: number]: ITimesheetApplication }
        }
      }
      if (!result[shiftId]['applications']) {
        result[shiftId]['applications'] = {}
      }
      result[shiftId]['applications'][applicationId] = current

      return result
    },
    {} as {
      [key: number]: {
        applications: { [key: number]: ITimesheetApplication }
        [key: string]: any
      }
    },
  )

  const shiftsWithApplications = { ...shifts }

  for (const key in shiftsWithApplications) {
    shiftsWithApplications[key] = {
      ...shiftsWithApplications[key],
      ...(applicationsByShift[key] && {
        applications: applicationsByShift[key]['applications'],
      }),
    }
  }

  return shiftsWithApplications
}

const connectApplicationsAndPunchCards: (
  punchcards: IPunchCard[],
  state: ITimesheetReducer,
) => { [key: number]: ITimesheetShift } = (
  punchcards: ITimesheetIPunchCard[],
  state: ITimesheetReducer,
) => {
  const {
    data: { shifts },
  } = state

  const punchcardsByShiftAndApplication = punchcards.reduce(
    (
      result: {
        [key: number]: {
          applications: {
            [key: number]: { punchcards: { [key: number]: IPunchCard } }
          }
        }
      },
      current: IPunchCard,
    ) => {
      const shiftId = current['gig_id']
      const applicationId = current['application_id']
      const punchcardId = current.id as number

      if (!result[shiftId]) {
        result[shiftId] = {} as {
          applications: { [key: number]: ITimesheetApplication }
        }
      }
      if (!result[shiftId]['applications']) {
        result[shiftId]['applications'] = {}
      }
      if (!result[shiftId]['applications'][applicationId]) {
        result[shiftId]['applications'][applicationId] = {} as {
          punchcards: { [key: number]: IPunchCard }
        }
      }
      if (!result[shiftId]['applications'][applicationId]['punchcards']) {
        result[shiftId]['applications'][applicationId]['punchcards'] = {} as {
          [key: number]: IPunchCard
        }
      }

      result[shiftId]['applications'][applicationId]['punchcards'][
        punchcardId
      ] = current

      return result
    },
    {} as {
      [key: number]: {
        applications: {
          [key: number]: { punchcards: { [key: number]: IPunchCard } }
        }
      }
    },
  )

  const shiftsWithApplicationsAndPunchCards = _.cloneDeep(shifts)

  for (const shiftKey in punchcardsByShiftAndApplication) {
    for (const appKey in punchcardsByShiftAndApplication[shiftKey]
      .applications) {
      shiftsWithApplicationsAndPunchCards[shiftKey]['applications'][appKey][
        'punchcards'
      ] =
        punchcardsByShiftAndApplication[shiftKey]['applications'][appKey][
          'punchcards'
        ]
    }
  }

  return shiftsWithApplicationsAndPunchCards
}

const connectApplicationsAndBonuses: (
  bonuses: IBonus[],
  state: ITimesheetReducer,
) => { [key: number]: ITimesheetShift } = (
  bonuses: IBonus[],
  state: ITimesheetReducer,
) => {
  const {
    data: { shifts },
  } = state

  const bonusesByShiftAndApplication = bonuses.reduce(
    (
      result: {
        [key: number]: {
          applications: {
            [key: number]: { bonuses: { [key: number]: IBonus } }
          }
        }
      },
      current: IBonus,
    ) => {
      const shiftId = (current as IBonus & { application: IApplication })
        .application.gig_id
      const applicationId = current['application_id']
      const bonusId = current.id as number

      if (!result[shiftId]) {
        result[shiftId] = {} as {
          applications: { [key: number]: ITimesheetApplication }
        }
      }
      if (!result[shiftId]['applications']) {
        result[shiftId]['applications'] = {}
      }
      if (!result[shiftId]['applications'][applicationId]) {
        result[shiftId]['applications'][applicationId] = {} as {
          bonuses: { [key: number]: IBonus }
        }
      }
      if (!result[shiftId]['applications'][applicationId]['bonuses']) {
        result[shiftId]['applications'][applicationId]['bonuses'] = {} as {
          [key: number]: IBonus
        }
      }

      result[shiftId]['applications'][applicationId]['bonuses'][
        bonusId
      ] = current

      return result
    },
    {} as {
      [key: number]: {
        applications: {
          [key: number]: { bonuses: { [key: number]: IBonus } }
        }
      }
    },
  )

  const shiftsWithApplicationsAndBonuses = _.cloneDeep(shifts)

  for (const shiftKey in bonusesByShiftAndApplication) {
    for (const appKey in bonusesByShiftAndApplication[shiftKey].applications) {
      // Not all bonuses can be paired with a shift, because the API doesn't return timesheet Shifts with no punch cards.
      // There can be bonuses for shifts with no punch cards. In this case the app crashes without this condition.
      if (shiftsWithApplicationsAndBonuses[shiftKey]) {
        shiftsWithApplicationsAndBonuses[shiftKey]['applications'][appKey][
          'bonuses'
        ] =
          bonusesByShiftAndApplication[shiftKey]['applications'][appKey][
            'bonuses'
          ]
      }
    }
  }

  return shiftsWithApplicationsAndBonuses
}

const processGetTimesheet = (
  payload: IPayload<ITimesheet>,
  state: ITimesheetReducer,
) => {
  const { data: timesheet } = payload
  const { data: currentData } = state

  return {
    ...currentData,
    timesheet,
  }
}

const processUpdateTimesheet = (
  payload: IPayload<ITimesheet>,
  state: ITimesheetReducer,
) => {
  const { data: timesheet } = payload
  const { data: currentData } = state

  return {
    ...currentData,
    timesheet,
  }
}

const processGetProperty = (
  payload: IPayload<IProperty>,
  state: ITimesheetReducer,
) => {
  const { data } = payload
  const { data: currentData } = state

  const property = data

  return {
    ...currentData,
    property,
  }
}

const processGetShifts = (
  payload: IPayload<IShift[]>,
  state: ITimesheetReducer,
) => {
  const { data } = payload
  const { data: currentData } = state

  const shifts = transformShifts(data)

  return {
    ...currentData,
    shifts,
  }
}

const processGetPunchCards = (
  payload: IPayload<IPunchCard[]>,
  state: ITimesheetReducer,
) => {
  const { data } = payload
  const { data: currentData } = state

  const punchcards = data
  const shifts = connectApplicationsAndPunchCards(punchcards, state)
  return {
    ...currentData,
    shifts,
  }
}

const processGetBonuses = (
  payload: IPayload<IBonus[]>,
  state: ITimesheetReducer,
) => {
  const { data } = payload
  const { data: currentData } = state

  const bonuses = data
  const shifts = connectApplicationsAndBonuses(bonuses, state)
  return {
    ...currentData,
    shifts,
  }
}

const processAddBonus = (
  payload: IPayload<IBonus & { application: IApplication }>,
  state: ITimesheetReducer,
) => {
  const { data } = payload
  const {
    application: { gig_id: shiftId },
    id: bonusId,
    application_id: applicationId,
  } = data

  const { data: currentData } = state
  const { shifts: oldShifts } = currentData

  const shifts = _.cloneDeep(oldShifts)

  if (shifts[shiftId])
    shifts[shiftId]['applications'][applicationId]['bonuses'][
      bonusId as number
    ] = data

  return {
    ...currentData,
    shifts,
  }
}

const processUpdateBonus = (
  payload: IPayload<IBonus & { application: IApplication }>,
  state: ITimesheetReducer,
) => {
  const { data } = payload
  const {
    application: { gig_id: shiftId },
    id: bonusId,
    application_id: applicationId,
  } = data

  const { data: currentData } = state
  const { shifts: oldShifts } = currentData

  const shifts = _.cloneDeep(oldShifts)

  if (shifts[shiftId])
    shifts[shiftId]['applications'][applicationId]['bonuses'][
      bonusId as number
    ] = data

  return {
    ...currentData,
    shifts,
  }
}

const processDeleteBonus = (
  payload: IDeleteBonusParams,
  state: ITimesheetReducer,
) => {
  const {
    id: bonusId,
    gig_id: shiftId,
    application_id: applicationId,
  } = payload

  const { data: currentData } = state
  const { shifts: oldShifts } = currentData

  const shifts = _.cloneDeep(oldShifts)

  if (shifts[shiftId])
    delete shifts[shiftId]['applications'][applicationId]['bonuses'][bonusId]

  return {
    ...currentData,
    shifts,
  }
}

const processAddPunchCard = (
  payload: IPayload<IPunchCard>,
  state: ITimesheetReducer,
) => {
  const { data } = payload
  const {
    gig_id: shiftId,
    id: punchcardId,
    application_id: applicationId,
  } = data

  const { data: currentData } = state
  const { shifts: oldShifts } = currentData

  const shifts = _.cloneDeep(oldShifts)

  shifts[shiftId]['applications'][applicationId]['punchcards'][
    punchcardId as number
  ] = data

  return {
    ...currentData,
    shifts,
  }
}

const processUpdatePunchCard = (
  payload: IPayload<IPunchCard>,
  state: ITimesheetReducer,
) => {
  const { data } = payload
  const {
    gig_id: shiftId,
    id: punchcardId,
    application_id: applicationId,
  } = data

  const { data: currentData } = state
  const { shifts: oldShifts } = currentData

  const shifts = _.cloneDeep(oldShifts)

  shifts[shiftId]['applications'][applicationId]['punchcards'][
    punchcardId as number
  ] = data

  return {
    ...currentData,
    shifts,
  }
}

const processGetApplications = (
  payload: IPayload<IApplication[]>,
  state: ITimesheetReducer,
) => {
  const { data } = payload
  const { data: currentData } = state

  const applications = transformApplications(data)
  const shifts = connectShiftsAndApplications(applications, state)
  return {
    ...currentData,
    shifts,
  }
}

const processGetGigDays = (
  payload: IPayload<IGigDayExtended[]>,
  state: ITimesheetReducer,
) => {
  const { data: gigDays } = payload

  const { data: currentData } = state
  const { shifts: oldShifts } = currentData

  const shifts = _.cloneDeep(oldShifts)
  let shiftId

  const gigDaysObject = gigDays.reduce(
    (
      result: { [key: number]: IGigDayExtended },
      current: IGigDayExtended,
      index: number,
    ) => {
      const { shift_id } = current
      shiftId = shift_id

      result[index] = current

      return result
    },
    {} as { [key: number]: IGigDayExtended },
  )

  shifts[(shiftId as unknown) as number]['gigDays'] = gigDaysObject

  return {
    ...currentData,
    shifts,
  }
}

export const timesheetReducer = (
  state = initialState,
  action: Action,
): ITimesheetReducer => {
  //#region General Timesheet actions
  if (isType(action, GetTimesheet)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processGetTimesheet(payload, state),
    }
  }

  if (isType(action, GetTimesheetErrors)) {
    const { payload } = action
    return {
      ...state,
      error: payload,
    }
  }

  if (isType(action, UpdateTimesheet)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processUpdateTimesheet(payload, state),
    }
  }

  if (isType(action, TimesheetFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, ClearTimesheet)) {
    return initialState
  }
  //#endregion

  //#region Timesheet property actions
  if (isType(action, GetTimesheetProperty)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processGetProperty(payload, state),
    }
  }

  if (isType(action, GetTimesheetPropertyFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  //#endregion

  //#region Timesheet shifts actions
  if (isType(action, GetTimesheetShifts)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processGetShifts(payload, state),
    }
  }

  if (isType(action, GetTimesheetShiftsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  //#endregion

  //#region Timesheet punch cards actions
  if (isType(action, GetTimesheetPunchCards)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processGetPunchCards(payload, state),
    }
  }

  if (isType(action, GetTimesheetPunchCardsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, AddTimesheetPunchCard)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processAddPunchCard(payload, state),
      addPunchCardError: undefined,
    }
  }

  if (isType(action, AddTimesheetPunchCardError)) {
    const { payload } = action
    return {
      ...state,
      addPunchCardError: payload,
    }
  }

  if (isType(action, UpdateTimesheetPunchCard)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processUpdatePunchCard(payload, state),
    }
  }

  if (isType(action, DeleteTimesheetPunchCard)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processUpdatePunchCard(payload, state),
    }
  }
  //#endregion

  //#region Timesheet bonuses actions
  if (isType(action, GetTimesheetBonuses)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processGetBonuses(payload, state),
    }
  }

  if (isType(action, AddTimesheetBonus)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processAddBonus(payload, state),
    }
  }

  if (isType(action, UpdateTimesheetBonus)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processUpdateBonus(payload, state),
    }
  }

  if (isType(action, DeleteTimesheetBonus)) {
    const { payload } = action
    return {
      ...state,
      data: processDeleteBonus(payload, state),
    }
  }
  //#endregion

  //#region Timesheet applications actions
  if (isType(action, GetTimesheetApplications)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processGetApplications(payload, state),
    }
  }

  if (isType(action, GetTimesheetApplicationsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  //#endregion

  //#region Timesheet gig days actions
  if (isType(action, GetTimesheetGigDays)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processGetGigDays(payload, state),
    }
  }
  //#endregion
  if (isType(action, UpdateApplicationBlockPropertiesCount)) {
    const { payload } = action
    const { data } = state
    const updatedData = { ...data }

    for (const shiftKey in updatedData.shifts) {
      const shift = updatedData.shifts[shiftKey]
      const updatedApplications = { ...shift.applications }
      for (const applicationKey in updatedApplications) {
        const application = updatedApplications[applicationKey]
        // Check if the applicant_id matches
        if (application.applicant.id === payload.applicantId) {
          // Update the block_properties_count
          updatedApplications[applicationKey] = {
            ...application,
            block_properties_count: payload.blockPropertiesCount,
          }
        }
      }

      // Update the applications in the shift
      updatedData.shifts[shiftKey] = {
        ...shift,
        applications: updatedApplications,
      }
    }

    return {
      ...state,
      data: updatedData,
    }
  }

  return state
}
