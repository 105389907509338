import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import { IPayload } from 'models/Global'
import {
  IAutoAcceptCertificate,
  IGetAutoAcceptCertificatesParams,
  IPutAutoAcceptCertificateParams,
  IPostAutoAcceptCertificateParams,
} from 'models/AutoAcceptCertificate'

export class AutoAcceptCertificatesApi {
  constructor(private axios: AxiosService) {}

  getAutoAcceptCertificates = async (
    params?: IGetAutoAcceptCertificatesParams,
  ) => {
    const response = await this.axios.get<IPayload<IAutoAcceptCertificate[]>>(
      Endpoints.getAutoAcceptCertificates,
      params,
    )
    return response
  }

  getAutoAcceptCertificate = async (
    id: number,
    params?: IGetAutoAcceptCertificatesParams,
  ) => {
    const response = await this.axios.get<IPayload<IAutoAcceptCertificate>>(
      Endpoints.getAutoAcceptCertificate(id),
      params,
    )
    return response
  }

  postAutoAcceptCertificate = async (
    params: IPostAutoAcceptCertificateParams,
  ) => {
    const response = await this.axios.post<IPayload<IAutoAcceptCertificate>>(
      Endpoints.postAutoAcceptCertificate,
      params,
    )
    return response
  }

  putAutoAcceptCertificate = async (
    id: number,
    params?: IPutAutoAcceptCertificateParams,
  ) => {
    const response = await this.axios.put<IPayload<IAutoAcceptCertificate>>(
      Endpoints.putAutoAcceptCertificate(id),
      params,
    )
    return response
  }

  deleteAutoAcceptCertificate = async (id: number) => {
    const response = await this.axios.delete<{}>(
      Endpoints.deleteAutoAcceptCertificate(id),
    )
    return response
  }
}
