/* eslint-disable no-param-reassign */
import { UserLogout } from 'data/common/Auth/actions'
import { Action, applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistReducer } from 'redux-persist'
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import thunk from 'redux-thunk'
import { AppState } from 'store/types'
import { isType } from 'ts-action'
import combinedReducer from './reducers'

const rootReducer = (state: AppState | undefined, action: Action) => {
  if (isType(action, UserLogout)) {
    state = undefined
  }
  return combinedReducer(state, action)
}

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel1,
  whitelist: ['auth'],
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

export default createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk)),
)
