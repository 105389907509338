import { AddFavorite, DeleteFavorite } from 'data/business/Favorite/actions'
import {
  deleteFromArray,
  maybeAddToArray,
  updateArray,
} from 'helpers/helperFuncs'
import {
  IRequestApplication,
  IRequestApplicationUser,
  IRequestApplicationsReducer,
} from 'models/RequestApplication'
import { IShift } from 'models/Shift'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  AddRequestApplicationShift,
  AddRequestApplicationUser,
  ClearRequestApplicationShifts,
  ClearRequestApplicationUsers,
  DeleteRequestApplication,
  DeleteRequestApplicationUser,
  GetRequestApplications,
  GetRequestApplicationUsers,
  RequestApplicationsFetching,
  UpdateRequestApplication,
  UpdateRequestApplicationShift,
} from './actions'

const initialState: IRequestApplicationsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
  users: [],
  shifts: [],
}

export const requestApplicationsReducer = (
  state = initialState,
  action: Action,
): IRequestApplicationsReducer => {
  if (isType(action, GetRequestApplications)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, GetRequestApplicationUsers)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      users: payload.data,
    }
  }
  if (isType(action, UpdateRequestApplication)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IRequestApplication>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, DeleteRequestApplication)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IRequestApplication>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, RequestApplicationsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, DeleteRequestApplicationUser)) {
    const { payload } = action
    const { users } = state
    const formattedArray = deleteFromArray<IRequestApplicationUser>(
      users || [],
      payload,
    )
    return {
      ...state,
      users: formattedArray,
    }
  }
  if (isType(action, AddRequestApplicationUser)) {
    const { payload } = action
    const { users } = state
    const formattedArray = maybeAddToArray<IRequestApplicationUser>(
      users || [],
      payload,
    )
    return {
      ...state,
      users: formattedArray,
    }
  }

  if (isType(action, AddRequestApplicationShift)) {
    const { payload } = action
    const { shifts } = state
    const formattedArray = [...(shifts as IShift[]), payload].sort(
      (a, b) =>
        new Date(a.date_start).getTime() - new Date(b.date_start).getTime(),
    )
    return {
      ...state,
      shifts: formattedArray,
    }
  }

  if (isType(action, UpdateRequestApplicationShift)) {
    const { payload } = action
    const { shifts } = state
    const formattedArray = updateArray<IShift>(shifts as IShift[], payload)
    return {
      ...state,
      shifts: formattedArray,
    }
  }

  if (isType(action, ClearRequestApplicationShifts)) {
    return {
      ...state,
      shifts: [],
    }
  }
  if (isType(action, ClearRequestApplicationUsers)) {
    return {
      ...state,
      users: [],
    }
  }

  if (isType(action, AddFavorite)) {
    const { users } = state
    const { data } = action.payload
    const newUsers = (users || []).map(user => {
      const { id } = user
      if (id === data.model_favored.id) {
        const modifiedUser = user
        modifiedUser.favorite_id_by_business = data.id
        return modifiedUser
      }
      return user
    })
    return {
      ...state,
      users: newUsers,
    }
  }

  if (isType(action, DeleteFavorite)) {
    const { users } = state
    const { payload } = action
    const newUsers = (users || []).map(user => {
      const { id } = user
      if (id === payload) {
        const modifiedUser = user
        modifiedUser.favorite_id_by_business = null
        return modifiedUser
      }
      return user
    })
    return {
      ...state,
      users: newUsers,
    }
  }

  return state
}
