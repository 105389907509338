import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import { IPayload } from 'models/Global'
import {
  IGetAcknowledgedDocumentsParams,
  IAcknowledgedDocument,
  IPostAcknowledgedDocumentParams,
  IPutAcknowledgedDocumentParams,
  IGetMultipleAcknowledgedDocuments,
} from 'models/AcknowledgedDocument'

export class AcknowledgedDocumentsApi {
  constructor(private axios: AxiosService) {}

  getAcknowledgedDocuments = async (
    params?: IGetAcknowledgedDocumentsParams,
  ) => {
    const response = await this.axios.get<IPayload<IAcknowledgedDocument[]>>(
      Endpoints.getAcknowledgedDocuments,
      params,
    )
    return response
  }

  getAcknowledgedDocument = async (id: number) => {
    const response = await this.axios.get<IPayload<IAcknowledgedDocument>>(
      Endpoints.getAcknowledgedDocument(id),
    )
    return response
  }

  postAcknowledgedDocument = async (
    params: IPostAcknowledgedDocumentParams,
  ) => {
    const response = await this.axios.post<IPayload<IAcknowledgedDocument>>(
      Endpoints.postAcknowledgedDocument,
      params,
    )
    return response
  }

  putAcknowledgedDocument = async (
    id: number,
    params: IPutAcknowledgedDocumentParams,
  ) => {
    const response = await this.axios.put<IPayload<IAcknowledgedDocument>>(
      Endpoints.putAcknowledgedDocument(id),
      params,
    )
    return response
  }

  deleteAcknowledgedDocument = async (id: number) => {
    const response = await this.axios.delete<{}>(
      Endpoints.deleteAcknowledgedDocument(id),
    )
    return response
  }

  getMultipleDocuments = async (params: IGetMultipleAcknowledgedDocuments) => {
    const response = await this.axios.get<Blob>(
      Endpoints.getMultipleAcknowledgedDocuments,
      params,
      { responseType: 'blob' },
    )
    return response
  }
}
