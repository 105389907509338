import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import { IPayload } from 'models/Global'
import {
  IGetRegionsParams,
  IRegion,
  IPostRegionParams,
  IPutRegionParams,
} from 'models/Region'

export class RegionApi {
  constructor(private axios: AxiosService) {}

  getRegions = async (params?: IGetRegionsParams) => {
    const response = await this.axios.get<IPayload<IRegion[]>>(
      Endpoints.getRegions,
      params,
    )
    return response
  }

  getRegion = async (id: number) => {
    const response = await this.axios.get<IPayload<IRegion>>(
      Endpoints.getRegion(id),
    )
    return response
  }

  postRegion = async (params: IPostRegionParams) => {
    const response = await this.axios.post<IPayload<IRegion>>(
      Endpoints.postRegion,
      params,
    )
    return response
  }

  putRegion = async (id: number, params: IPutRegionParams) => {
    const response = await this.axios.put<IPayload<IRegion>>(
      Endpoints.putRegion(id),
      params,
    )
    return response
  }

  deleteRegion = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteRegion(id))
    return response
  }
}
