import { action, payload } from 'ts-action'
import { IInvoice } from 'models/Invoice'
import { IError, IPayload } from 'models/Global'

export const InvoicesFetching = action('InvoicesFetching', payload<boolean>())

export const GetInvoices = action(
  'GetInvoices',
  payload<IPayload<IInvoice[]>>(),
)

export const GetOverdueInvoices = action(
  'GetOverdueInvoices',
  payload<number>(),
)

export const GetInvoicesErrors = action('GetInvoicesErrors', payload<IError>())
