import { IShift } from 'models/Shift'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  GetCurrentlyActiveShifts,
  CurrentlyActiveShiftsFetching,
  ClearCurrentlyActiveShiftsReducer,
  CurrentlyActiveShiftsSetHasMore,
  CurrentlyActiveShiftsSetInitialFetchClockedIn,
} from './actions'
import { IBaseReducer } from 'models/BaseReducer'

interface IShiftsReducer extends IBaseReducer<IShift[]> {
  hasMore: boolean
  initialFetchClockedIn: boolean
}

const initialState: IShiftsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
  hasMore: false,
  initialFetchClockedIn: false,
}

export const currentlyActiveShiftsReducer = (
  state = initialState,
  action: Action,
): IShiftsReducer => {
  if (isType(action, GetCurrentlyActiveShifts)) {
    const { data, meta } = action.payload
    const hasMore = meta.pagination
      ? meta.pagination.total_pages > meta.pagination.current_page
      : false
    return {
      ...state,
      meta: meta,
      data,
      hasMore,
      error: undefined,
    }
  }
  if (isType(action, CurrentlyActiveShiftsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, CurrentlyActiveShiftsSetHasMore)) {
    const { payload } = action
    return {
      ...state,
      hasMore: payload,
    }
  }
  if (isType(action, CurrentlyActiveShiftsSetInitialFetchClockedIn)) {
    return {
      ...state,
      initialFetchClockedIn: true,
    }
  }
  if (isType(action, ClearCurrentlyActiveShiftsReducer)) {
    return initialState
  }
  return state
}
