import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import { IPayload } from 'models/Global'
import {
  IDocument,
  IGetDocumentsParams,
  IPutDocumentParams,
  IPostDocumentParams,
} from 'models/Document'

export class DocumentsApi {
  constructor(private axios: AxiosService) {}

  getDocuments = async (params?: IGetDocumentsParams) => {
    const response = await this.axios.get<IPayload<IDocument[]>>(
      Endpoints.getDocuments,
      params,
    )
    return response
  }

  getDocument = async (id: number) => {
    const response = await this.axios.get<IPayload<IDocument>>(
      Endpoints.getDocument(id),
    )
    return response
  }

  postDocument = async (params: IPostDocumentParams) => {
    const response = await this.axios.post<IPayload<IDocument>>(
      Endpoints.postDocument,
      params,
    )
    return response
  }

  putDocument = async (id: number, params?: IPutDocumentParams) => {
    const reponse = await this.axios.put<IPayload<IDocument>>(
      Endpoints.putDocument(id),
      params,
    )
    return reponse
  }

  deleteDocument = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteDocument(id))
    return response
  }
}
