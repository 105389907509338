import {
  IBlockProperty,
  TGetBlockPropertiesParams,
  IPostBlockPropertiesParams,
  IDeleteBlockPropertiesParams,
  TGetBlockPropertiesUsersParams,
  IBlockPropertyUser,
} from 'models/BlockProperty'
import { IPayload } from 'models/Global'
import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'

export class BlockPropertiesApi {
  constructor(private axios: AxiosService) {}

  getBlockProperties = async (params?: TGetBlockPropertiesParams) => {
    const response = await this.axios.get<IPayload<IBlockProperty[]>>(
      Endpoints.getBlockProperties,
      params,
    )
    return response
  }

  postBlockProperties = async (params: IPostBlockPropertiesParams) => {
    const response = await this.axios.post<IPayload<IBlockProperty[]>>(
      Endpoints.postBlockProperties,
      params,
    )

    return response
  }

  deleteBlockProperties = async (params: IDeleteBlockPropertiesParams) => {
    const response = await this.axios.delete<{}>(
      Endpoints.deleteBlockProperties,
      params,
    )

    return response
  }

  getBlockPropertiesUsers = async (params: TGetBlockPropertiesUsersParams) => {
    const response = await this.axios.get<IPayload<IBlockPropertyUser[]>>(
      Endpoints.getBlockPropertiesUsers,
      params,
    )

    return response
  }
}
