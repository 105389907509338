import { action, payload } from 'ts-action'
import { IAchAccount } from 'models/AchAccount'
import { IError, IPayload } from 'models/Global'

export const AchAccountsFetching = action(
  'AchAccountsFetching',
  payload<boolean>(),
)

export const GetAchAccounts = action(
  'GetAchAccounts',
  payload<IPayload<IAchAccount[]>>(),
)

export const AddAchAccount = action(
  'AddAchAccount',
  payload<IPayload<IAchAccount>>(),
)

export const DeleteAchAccount = action('DeleteAchAccount', payload<number>())

export const GetAchAccountsErrors = action(
  'GetAchAccountsErrors',
  payload<IError>(),
)
