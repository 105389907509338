import { IAchAccountsReducer, IAchAccount } from 'models/AchAccount'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { deleteFromArray, addToArray } from 'helpers/helperFuncs'
import {
  GetAchAccounts,
  AchAccountsFetching,
  DeleteAchAccount,
  AddAchAccount,
} from './actions'

const initialState: IAchAccountsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const achAccountsReducer = (
  state = initialState,
  action: Action,
): IAchAccountsReducer => {
  if (isType(action, GetAchAccounts)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, AddAchAccount)) {
    const {
      payload: { data: item },
    } = action
    const { data } = state
    return {
      ...state,
      data: addToArray<IAchAccount>(item, data),
    }
  }

  if (isType(action, DeleteAchAccount)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IAchAccount>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, AchAccountsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
