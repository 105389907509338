import {
  IModalDuplicateShiftValues,
  IModalEditShiftValues,
} from 'helpers/shiftHelpers/types'
import { IBaseReducer } from '../BaseReducer'
import { TypeOrNull } from '../Common'
import { IDay } from '../Day'
import { IBaseGetParams, IPayload } from '../Global'
import { ITimesheet } from '../Timesheet'

export enum BUSINESS_FILTER {
  POSTED = 'posted',
  DRAFT = 'draft',
  IN_PROGRESS = 'in_progress',
  FINISHED = 'finished',
}

export enum SHIFT_FILL_STATUS {
  UNFILLED,
  FILLED,
}

export enum SHIFT_TRAINING {
  NON_TRAINING,
  TRAINING,
}

export enum DISTANCE_METRIC {
  MILES = 'miles',
}

export enum SHIFT_PROGRESS {
  NOT_STARTED = 0,
  ACTIVE = 1,
  FINISHED = 2,
  NOT_FINISHED = 3,
  ALL = 4,
}

export enum SHIFT_STATUS {
  DRAFT,
  POSTED,
}

export enum SHIFT_TYPE {
  SINGLEDAY,
  MULTIDAY,
}

export enum POPUP_ACTION_TYPE {
  POP_UP_ACTION_DRUG_SCREENING,
  POP_UP_ACTION_OKAY,
  POP_UP_ACTION_SUBMIT_CONSENT,
  POP_UP_ACTION_WOULD_LIKE_TO_SUBMIT_CONSENT,
}

export interface IShiftIncludeParams {
  include?:
    | {
        gig_type?: Record<any, any>
        timesheets?: Record<any, any>
        geofencing_information?: Record<any, any>
        accepted_applications_count?: {
          gig_day_id: number
        }
      }
    | (
        | 'gig_type'
        | 'timesheets'
        | 'geofencing_information'
        | 'accepted_applications_count'
      )[]
}
export interface IShiftAction {
  name: string
  text: string
  clickable: boolean
  url?: string
  pop_up_action_type?: POPUP_ACTION_TYPE
}

export enum SHIFT_ACTION_NAME {
  ACCEPT = 'accept',
  APPLY = 'apply',
  APPLY_AS_GROUP = 'apply_as_group',
  CLAIM = 'claim',
  CONFIRM = 'confirm',
  CLOCK_IN = 'clock_in',
  CLOCK_OUT = 'clock_out',
  REVIEW = 'review',
  CANCEL = 'cancel',
  DECLINE = 'decline',
}

export interface IShiftApplicants {
  pending_count: number
  accepted_count: number
  confirmed_count: number
}

export interface IShift {
  id: number
  property_id: number
  gig_type_id: number
  description_short: string
  description: string
  description_rich_text: string
  date_start: Date
  date_end: Date
  hour_start: string
  hour_end: string
  fancy_date_start: Date
  fancy_date_end: Date
  fancy_time: Date
  time_start: Date
  time_end: Date
  pay_value: number
  estimated_pay_value: string
  break: number
  positions: number
  available_positions: number
  type: number
  special_instructions: string
  special_instructions_rich_text: string
  arrival_instructions: string
  arrival_instructions_rich_text: string
  what_to_wear: string
  what_to_wear_rich_text: string
  travel_tips: string
  travel_tips_rich_text: string
  auto_accept: boolean
  auto_accept_by_certificates: boolean
  auto_accept_by_favored: boolean
  auto_accept_by_worked_before: boolean
  auto_accept_by_onboarded: boolean
  auto_accept_certificate_ids?: number[]
  hide_auto_accept_certificates: boolean
  fixed_time?: null | boolean
  to_hire: boolean
  shift?: null
  min_age: number
  progress: number
  timetracker_clock_in_only: boolean
  status: number
  applicants: IShiftApplicants
  stats: {
    active_punch_cards_count: number
    punch_cards_count: number
  }
  application_id: number
  application_status: number
  application_confirmed: boolean
  application_waitlisted: boolean
  favored: boolean
  favorite_id: TypeOrNull<number>
  actions: IShiftAction[]
  how_many_weeks: number
  weekly_on: number[]
  active_punch_card_id: number
  training_mode: boolean
  amount_lifted_frequently: TypeOrNull<number>
  amount_lifted_occasionally: TypeOrNull<number>
  amount_of_time_spent_on_feet: TypeOrNull<string>
  physical_requirement: string[]
  suggested_pay_rate: number
  arrival_instructions_attachment_id: TypeOrNull<number>
  timesheets?: IPayload<ITimesheet[]>
  gig_identifier?: TypeOrNull<string>
  group_id?: number // available for Upshifter user only
  requested_upshifters_count?: number
  application_is_requested?: boolean
  geofencing_information?: { geofencing_enabled: boolean }
  is_bench_connect_gig: boolean
  next_working_day_time_start: string
  accepted_applications_count?: number
  description_disclaimer?: string

  /**
   * Start Deprecated
   */

  property_name: string
  property_address: string
  property_latitude: string
  property_longitude: string
  property_timezone: string
  property_rating: string
  property_timetracker_clock_in_only: string
  business_id: number
  business_industry_id: number
  business_name: string
  required_documents_count: number
  pending_applicants_count: number
  accepted_applicants_count: number
  confirmed_applicants_count: number
  punch_cards_count: number
  not_declined_punch_cards_count: number
  active_punch_cards_count: number
  next_day_id: number
  next_day_timestamp_start: string
  drug_screening_url: string
  certificate_ids_with_prices: any
  job_title: string

  /**
   * End Deprecated
   */
}

export interface IGetShiftsParamsBase extends IBaseGetParams {
  status?: SHIFT_STATUS
  user_id?: number
  property_id?: number
  timesheet_id?: number
  progress?: SHIFT_PROGRESS
  sort_by?: string
  longitude?: string
  latitude?: string
  distance?: string
  distance_in?: DISTANCE_METRIC
  filled?: boolean
  type?: SHIFT_TYPE
  price_start?: string
  price_end?: string
  date_start?: Date
  date_end?: Date
  time_start?: string
  time_end?: string
  regions?: number[]
  gig_types?: number[]
  properties?: number[]
  property_categories?: number[]
  reviewed?: boolean
  clocked_in?: boolean
  auto_accept?: boolean
  auto_accept_by_certificates?: boolean
  auto_accept_by_worked_before?: boolean
  to_hire?: boolean
  application_status?: number
  application_confirmed?: boolean
  featured_gigs_campaign_id?: number
  business_id?: number
  short_link_uuid?: string
}

export type IGetShiftsParams = IGetShiftsParamsBase & IShiftIncludeParams

export interface IPostShiftParamsBase {
  property_id: number
  gig_type_id: number
  date_start: Date
  date_end?: Date
  hour_start: string
  hour_end: string
  description: string
  decription_short: string
  type: SHIFT_TYPE
  shift?: number
  pay_value: number
  positions: number
  break?: number
  what_to_wear: number
  special_instructions?: string
  arrival_instructions?: string
  travel_tips?: string
  min_age?: number
  min_age_toggle?: boolean
  fixed_time?: boolean
  to_hire?: boolean
  auto_accept?: boolean
  auto_accept_by_certificates?: boolean
  auto_accept_by_worked_before?: boolean
  auto_accept_certificate_ids: number[]
  status: SHIFT_STATUS
  days?: IDay
  amount_lifted_frequently: number
  amount_lifted_occasionally: number
  amount_of_time_spent_on_feet: number
  skip_gig_unpaid_break_validator?: boolean
  is_duplicate?: boolean
  template_id?: number
}

export type IPostShiftParams = IPostShiftParamsBase & IShiftIncludeParams

export interface IPutShiftParamsBase {
  property_id?: number
  gig_type_id?: number
  date_start?: Date
  date_end?: Date
  hour_start?: string
  hour_end?: string
  description?: string
  decription_short?: string
  type?: SHIFT_TYPE
  shift?: number
  pay_value?: number
  positions?: number
  break?: number
  what_to_wear?: number
  special_instructions?: string
  arrival_instructions?: string
  travel_tips?: string
  min_age?: number
  min_age_toggle?: boolean
  fixed_time?: boolean
  to_hire?: boolean
  auto_accept?: boolean
  auto_accept_by_certificates?: boolean
  auto_accept_by_worked_before?: boolean
  auto_accept_certificate_ids?: number[]
  status?: SHIFT_STATUS
  days?: IDay
  amount_lifted_frequently?: number
  amount_lifted_occasionally?: number
  amount_of_time_spent_on_feet?: number
  skip_posted_gig_with_accepted_applicants_validation?: boolean
  gig_identifier?: TypeOrNull<string>
  skip_gig_unpaid_break_validator?: boolean
}

export type IPutShiftParams = IPutShiftParamsBase & IShiftIncludeParams

export enum SHIFT_CONFIRMATION_STATUS {
  CONFIRMED = 'confirmed',
  NOT_CONFIRMED = 'not_confirmed',
}

export interface ShiftReducerProps {
  shift: IShift
  openedShift: IShift
  loading: boolean
  btnShiftLoading: boolean
  error: boolean
  isActiveShift: boolean
  isShiftScreenActive: boolean
}

export interface IShiftReducer extends IBaseReducer<ShiftReducerProps> {}

export interface ShiftReducerBusinessProps {
  shift: IShift
  openedShift: IShift
  loading: boolean
  btnShiftLoading: boolean
  isActiveShift: boolean
  isShiftScreenActive: boolean
}

export interface IShiftReducerBusiness
  extends IBaseReducer<ShiftReducerBusinessProps> {}
export interface IShiftValuesReducer {
  initialShiftValues: IModalEditShiftValues
  editShiftValues: IModalEditShiftValues
  duplicateShiftValues: IModalDuplicateShiftValues
  shiftFromModal: IShift
}
