import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import { IPayload } from 'models/Global'
import {
  IExperience,
  IGetExperiencesParams,
  IPutExperienceParams,
  IPostExperienceParams,
  IGigTypeExperience,
  IGetGigTypeExperiencesParams,
} from 'models/Experience'

export class ExperiencesApi {
  constructor(private axios: AxiosService) {}

  getExperiences = async (params?: IGetExperiencesParams) => {
    const response = await this.axios.get<IPayload<IExperience[]>>(
      Endpoints.getExperiences,
      params,
    )
    return response
  }

  getGigTypeExperiences = async (params?: IGetGigTypeExperiencesParams) => {
    const response = await this.axios.get<IPayload<IGigTypeExperience[]>>(
      Endpoints.getGigTypeExperiences,
      params,
    )
    return response
  }

  getExperience = async (id: number, params?: IGetExperiencesParams) => {
    const response = await this.axios.get<IPayload<IExperience>>(
      Endpoints.getExperience(id),
      params,
    )
    return response
  }

  postExperience = async (params: IPostExperienceParams) => {
    const response = await this.axios.post<IPayload<IExperience>>(
      Endpoints.postExperience,
      params,
    )
    return response
  }

  putExperience = async (id: number, params?: IPutExperienceParams) => {
    const response = await this.axios.put<IPayload<IExperience>>(
      Endpoints.putExperience(id),
      params,
    )
    return response
  }

  deleteExperience = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteExperience(id))
    return response
  }
}
