import { ITemplate, ITemplateReducer } from 'models/Template'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { GetTemplate, TemplateFetching, GetTemplateErrors } from './actions'

const initialState: ITemplateReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: {} as ITemplate,
}

export const templateReducer = (
  state = initialState,
  action: Action,
): ITemplateReducer => {
  if (isType(action, GetTemplate)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, GetTemplateErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }

  if (isType(action, TemplateFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
