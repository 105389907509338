import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import { IPayload } from 'models/Global'
import {
  IBusinessEntity,
  IGetBusinessEntitiesParams,
  IPutBusinessEntityParams,
  IPostBusinessEntityParams,
} from 'models/BusinessEntity'

export class BusinessEntitiesApi {
  constructor(private axios: AxiosService) {}

  getBusinessEntities = async (params?: IGetBusinessEntitiesParams) => {
    const response = await this.axios.get<IPayload<IBusinessEntity[]>>(
      Endpoints.getBusinessEntities,
      params,
    )
    return response
  }

  getBusinessEntity = async (
    id: number,
    params?: IGetBusinessEntitiesParams,
  ) => {
    const response = await this.axios.get<IPayload<IBusinessEntity>>(
      Endpoints.getBusinessEntity(id),
      params,
    )
    return response
  }

  postBusinessEntity = async (params: IPostBusinessEntityParams) => {
    const response = await this.axios.post<IPayload<IBusinessEntity>>(
      Endpoints.postBusinessEntity,
      params,
    )
    return response
  }

  updateBusinessEntity = async (
    id: number,
    params: Partial<IPutBusinessEntityParams>,
  ) => {
    const response = await this.axios.post<IPayload<IBusinessEntity>>(
      Endpoints.updateBusinessEntity(id),
      params,
    )
    return response
  }

  deleteBusinessEntity = async (id: number) => {
    const response = await this.axios.delete<{}>(
      Endpoints.deleteBusinessEntity(id),
    )
    return response
  }
}
