import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import { IPayload } from 'models/Global'
import {
  INotificationPreference,
  IGetNotificationPreferencesParams,
  IPutNotificationPreferenceParams,
  IPostNotificationPreferenceParams,
} from 'models/NotificationPreference'

export class NotificationPreferencesApi {
  constructor(private axios: AxiosService) {}

  getNotificationPreferences = async (
    params?: IGetNotificationPreferencesParams,
  ) => {
    const response = await this.axios.get<IPayload<INotificationPreference[]>>(
      Endpoints.getNotificationPreferences,
      params,
    )
    return response
  }

  getNotificationPreference = async (
    id: number,
    params?: IGetNotificationPreferencesParams,
  ) => {
    const response = await this.axios.get<IPayload<INotificationPreference>>(
      Endpoints.getNotificationPreference(id),
      params,
    )
    return response
  }

  postNotificationPreference = async (
    params: IPostNotificationPreferenceParams,
  ) => {
    const response = await this.axios.post<IPayload<INotificationPreference>>(
      Endpoints.postNotificationPreference,
      params,
    )
    return response
  }

  putNotificationPreference = async (
    id: number,
    params?: IPutNotificationPreferenceParams,
  ) => {
    const response = await this.axios.put<IPayload<INotificationPreference>>(
      Endpoints.putNotificationPreference(id),
      params,
    )
    return response
  }

  deleteNotificationPreference = async (id: number) => {
    const response = await this.axios.delete<{}>(
      Endpoints.deleteNotificationPreference(id),
    )
    return response
  }
}
