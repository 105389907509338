import { action, payload } from 'ts-action'
import { IInvoice, IInvoiceData } from 'models/Invoice'
import { IError, IPayload } from 'models/Global'
import { IInvoiceGroup } from 'models/InvoiceGroup'
import { IProperty } from 'models/Property'
import { ITimesheet } from 'models/Timesheet'
import { IInvoiceExpenseType } from 'models/InvoiceExpenseType'
import { ICredit } from 'models/Credit'

export const InvoiceFetching = action('InvoiceFetching', payload<boolean>())

export const GetInvoiceBatch = action(
  'GetInvoiceBatch',
  payload<IPayload<IInvoiceData>>(),
)

export const GetInvoice = action('GetInvoice', payload<IPayload<IInvoice>>())

export const GetInvoiceGroup = action(
  'GetInvoiceGroup',
  payload<IPayload<IInvoiceGroup>>(),
)

export const GetPropertiesForInvoice = action(
  'GetPropertiesForInvoice',
  payload<IPayload<IProperty[]>>(),
)

export const GetTimesheetsForInvoice = action(
  'GetTimesheetsForInvoice',
  payload<IPayload<ITimesheet[]>>(),
)

export const GetAdditionalExpenseTypesForInvoice = action(
  'GetAdditionalExpenseTypesForInvoice',
  payload<IPayload<IInvoiceExpenseType[]>>(),
)

export const GetCreditsForInvoice = action(
  'GetCreditsForInvoice',
  payload<IPayload<ICredit[]>>(),
)

export const GetInvoiceErrors = action('GetInvoiceErrors', payload<IError>())
