import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import { IPayload } from 'models/Global'
import {
  IPropertyType,
  IGetPropertyTypesParams,
  IPutPropertyTypeParams,
  IPostPropertyTypeParams,
} from 'models/PropertyType'

export class PropertyTypesApi {
  constructor(private axios: AxiosService) {}

  getPropertyTypes = async (params?: IGetPropertyTypesParams) => {
    const response = await this.axios.get<IPayload<IPropertyType[]>>(
      Endpoints.getPropertyTypes,
      params,
    )
    return response
  }

  getPropertyType = async (id: number, params?: IGetPropertyTypesParams) => {
    const response = await this.axios.get<IPayload<IPropertyType>>(
      Endpoints.getPropertyType(id),
      params,
    )
    return response
  }

  postPropertyType = async (params: IPostPropertyTypeParams) => {
    const response = await this.axios.post<IPayload<IPropertyType>>(
      Endpoints.postPropertyType,
      params,
    )
    return response
  }

  putPropertyType = async (id: number, params?: IPutPropertyTypeParams) => {
    const response = await this.axios.put<IPayload<IPropertyType>>(
      Endpoints.putPropertyType(id),
      params,
    )
    return response
  }

  deletePropertyType = async (id: number) => {
    const response = await this.axios.delete<{}>(
      Endpoints.deletePropertyType(id),
    )
    return response
  }
}
