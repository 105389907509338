import { action, payload } from 'ts-action'
import {
  IRemoveApplicantParams,
  IScheduledUpshifter,
} from 'models/ScheduledUpshifter'
import { IError, IPayload } from 'models/Global'

export const ScheduledUpshiftersFetching = action(
  'ScheduledUpshiftersFetching',
  payload<boolean>(),
)

export const GetScheduledUpshifters = action(
  'GetScheduledUpshifters',
  payload<IPayload<IScheduledUpshifter[]>>(),
)

export const ClearScheduledUpshifters = action('ClearScheduledUpshifters')

export const HasMore = action('HasMore', payload<boolean>())

export const ScheduledUpshiftersRemoveApplicant = action(
  'ScheduledUpshiftersRemoveApplicant',
  payload<IRemoveApplicantParams>(),
)

export const ScheduledUpshiftersRemoveReviewApplicant = action(
  'ScheduledUpshiftersRemoveReviewApplicant',
  payload<IRemoveApplicantParams>(),
)

export const GetScheduledUpshiftersErrors = action(
  'GetScheduledUpshiftersErrors',
  payload<IError>(),
)
