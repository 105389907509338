import 'react-app-polyfill/ie9'
// IMPORTANT DON'T IMPORT whole core-js only things you need
import loadable from '@loadable/component'
import { ChunksLoader } from 'components/common/ChunksLoader'
import 'core-js'
import 'core-js/features/array/find'
import 'core-js/features/array/includes'
import 'core-js/features/map'
import 'core-js/features/number/is-nan'
import 'core-js/features/set'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import store from 'store'
import 'url-search-params-polyfill'
import * as serviceWorker from './serviceWorker'

const App = loadable(() => import(/* webpackChunkName: "App" */ './app/App'), {
  fallback: <ChunksLoader />,
})

const persistor = persistStore(store)

const Application = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
)

ReactDOM.render(<Application />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
