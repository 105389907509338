import { action, payload } from 'ts-action'
import { IShift } from 'models/Shift'
import { IError, IPayload } from 'models/Global'

export const CurrentlyActiveShiftsFetching = action(
  'CurrentlyActiveShiftsFetching',
  payload<boolean>(),
)

export const GetCurrentlyActiveShifts = action(
  'GetCurrentlyActiveShifts',
  payload<IPayload<IShift[]>>(),
)

export const GetCurrentlyActiveShiftsErrors = action(
  'GetCurrentlyActiveShiftsErrors',
  payload<IError>(),
)

export const ClearCurrentlyActiveShiftsReducer = action(
  'ClearCurrentlyActiveShiftsReducer',
)

export const CurrentlyActiveShiftsSetHasMore = action(
  'CurrentlyActiveShiftsSetHasMore',
  payload<boolean>(),
)

export const CurrentlyActiveShiftsSetInitialFetchClockedIn = action(
  'CurrentlyActiveShiftsSetInitialFetchClockedIn',
)
