import { action, payload } from 'ts-action'
import { IShift, IShiftApplicants } from 'models/Shift'
import { IError } from 'models/Global'

interface IShiftsDecrementConfirmedIncrementPending {
  shiftId: number
  confirmed: number
}

interface IShiftsDecrementActive {
  confirmed: number
}

interface IShiftsUpdateApplicants {
  applicants: IShiftApplicants
  shiftId: number
}

export const BusinessShiftLoading = action(
  'BusinessShiftLoading',
  payload<boolean>(),
)

export const GetBusinessOpenShift = action(
  'GetBusinessOpenShift',
  payload<IShift>(),
)

export const CleanBusinessOpenShift = action('CleanBusinessOpenShift')

export const GetBusinessShiftError = action(
  'GetBusinessShiftError',
  payload<IError>(),
)

export const ResetBusinessActiveShift = action('ResetBusinessActiveShift')

export const BusinessShiftUpdateApplicants = action(
  'BusinessShiftUpdateApplicants',
  payload<IShiftsUpdateApplicants>(),
)

export const BusinessShiftDecrementConfirmedIncrementPending = action(
  'BusinessShiftDecrementConfirmedIncrementPending',
  payload<IShiftsDecrementConfirmedIncrementPending>(),
)

export const BusinessShiftDecrementPendingIncrementConfirmed = action(
  'BusinessShiftDecrementPendingIncrementConfirmed',
)

export const BusinessShiftDecrementPending = action(
  'BusinessShiftDecrementPending',
)
export const BusinessShiftDecrementAccepted = action(
  'BusinessShiftDecrementAccepted',
  payload<IShiftsDecrementActive>(),
)
