import { IGoogleCalendar } from 'models/Calendar'
import { IShift } from 'models/Shift'
import { IRoleData, USER_ROLE } from '../Auth'
import { IBaseReducer } from '../BaseReducer'
import { IBaseGetParams } from '../Global'
import { IRegion } from '../Region'
import { IIncompleteWorkflows } from 'models/OnboardingWorkflow'
import { TypeOrNull } from 'models/Common'
import { PAYMENT_TYPE_ID } from 'models/Invoice'

export const UserRole = {
  BUSINESS: 'business',
  EMPLOYEE: 'employee',
  UPSHIFTER: 'upshifter',
  APPROVER: 'approver',
  VIEWER: 'viewer',
  FINANCE_MANAGER: 'finance_manager',
}

export enum USER_STATUS {
  PERMANENTLY_SUSPENDED,
  TEMPORARY_SUSPENDED,
  ACTIVE,
  PENDING,
  PENDING_SUSPENSION,
}

export enum MY_UPSHIFTERS_FILTER {
  ALL_UPSHIFTERS,
  FAVORITE_UPSHIFTERS,
}

export interface IUserIncludeParams {
  include?:
    | {
        finished_punch_cards_sum_hours_total?: {
          business_id?: number
        }
        business?: Record<any, any>
        favorited_by_business?: {
          business_id?: number
        }
        finished_gigs_count: {
          business_id?: number
        }
        incomplete_workflows: string
        not_reviewed_gigs_count: {
          business_id?: number
        }
        not_reviewed_gigs: IShift[]
      }
    | (
        | 'finished_punch_cards_sum_hours_total'
        | 'business'
        | 'favorited_by_business'
        | 'finished_gigs_count'
        | 'incomplete_workflows'
        | 'not_reviewed_gigs_count'
        | 'not_reviewed_gigs'
      )[]
}

export interface IGetUsersParamsBase extends IBaseGetParams {
  uuid?: string
  last_name?: string
  date_of_birth?: string
  ssn_last_four?: string
  certificate_ids?: number[]
  role?: string
  role_id?: number
  regions?: number[]
  properties?: number[]
  property_assigned?: boolean
  block?: boolean
  worked_for_before_id?: number
  worked_for_id?: number
  worked_for_business_id?: number
  worked_for_property_ids?: number[]
  favorite_business_id?: number
  block_owner_id?: number
  block_business_id?: number
  block_user_id?: number
  sales_agent_id?: number
  account_manager_id?: number
  status?: number
  payment_type_id?: number
  order_by?: string
  employer_business_id?: number
  roles?: string[]
  favored?: boolean
  business_id?: number
  site?: boolean
  my_upshifters?: MY_UPSHIFTERS_FILTER
}

export type IGetUsersParams = IGetUsersParamsBase & IUserIncludeParams

export interface IGetUserParamsBase {
  business_id?: number
  favorite_business_id?: number
}

export type IGetUserParams = IGetUserParamsBase & IUserIncludeParams

export interface IPostUserParamsBase {
  title?: string
  first_name: string
  last_name: string
  password?: string
  password_confirmation?: string
  email: string
  role: string
  avatar?: string
  date_of_birth?: Date
  ssn_last_four?: string
  phone?: string
  notification_token?: string
  payment_type_id?: PAYMENT_TYPE_ID
  regions?: number[]
  properties?: number[]
  assign?: boolean
  timetracker_clock_in_only?: boolean
  status?: USER_STATUS
  checkr_candidate_id?: number
  employer_id?: number
  employer_business_id?: number
  site?: boolean
  can_login?: boolean
}

export type IPostUserParams = IPostUserParamsBase & IUserIncludeParams

export interface IPutUserParamsBase {
  first_name?: string
  last_name?: string
  password?: string
  email?: string
  role?: string
  date_of_birth?: Date
  ssn_last_four?: string
  phone?: string
  notification_token?: string
  payment_type_id?: PAYMENT_TYPE_ID
  regions?: number[]
  properties?: number[]
  assign?: boolean
  timetracker_clock_in_only?: boolean
  overdue?: boolean
  status?: USER_STATUS
  checkr_candidate_id?: number
  site?: boolean
  title?: string
  longitude?: number
  latitude?: number
  new_app?: boolean
}

export type IPutUserParams = IPutUserParamsBase & IUserIncludeParams

export interface IUpdateUserAvatarParams {
  avatar: FormData
}

export interface IRole {
  id: number
  name: string
}

interface IUserStats {
  notifications_count: number
  active_punch_cards_count2: number
  active_punch_cards_count: number
  active_strikes_count: number
  expired_strikes_count: number
  pending_gigs_count: number
  accepted_gigs_count: number
  confirmed_gigs_count: number
  active_gigs_count: number
  finished_gigs_count: number
  total_finished_gigs_count: number
  finished_punch_cards_count: number
  hours_worked: string
  total_hours_worked: string
}

export interface ISickPayDayHour {
  state_id?: number
  city_id?: number
  name: string
  total_hours: string
  accrual_rate: string
  yearly_hours: string
  pending_hours: string
  accrued_hours: string
  used_hours: string
  total_worked_hours: string
  yearly_worked_hours: string
}

export interface IUsersReducer extends IBaseReducer<IUser[]> {
  isFavoriteButtonLoadingIds: number[]
}

export interface IUserReducer extends IBaseReducer<IUser> {}

export interface IUserSickPayDayHoursReducer
  extends IBaseReducer<ISickPayDayHour[]> {}

export interface IUpshiftersReducer extends IBaseReducer<IMyUpshiftersUser[]> {}

export interface IUser {
  id: number
  uuid: string
  email: string
  full_name: string
  first_name: string
  last_name: string
  zip_code: string
  phone: string
  phone_formatted?: string
  description: string
  description_rich_text: string
  timezone: string
  avatar_url: string
  avatar_thumb_url: string
  date_of_birth: Date
  age: number
  rating: string
  confirmed: boolean
  status: number
  check_candidate_id: number
  employer_id: number
  employer_business_id: number
  ec_full_name: string
  ec_phone: string
  ec_relationship: string
  created_at: string
  password_last_changed_at: string
  changed_password: boolean
  new_app: boolean
  overdue: boolean
  site: boolean
  roles: IRoleData[]
  role: USER_ROLE
  regions: IRegion[]
  can_login: boolean
  company_name: string
  payment_type_id: number
  apc_email: string
  apc_phone: string
  apc_full_name: string
  sales_agent_id: number
  account_manager_id: number
  timetracker: boolean
  favorite: boolean
  stats: IUserStats
  ssn_last_four: string
  suspended_at: string
  suspended_until: string
  prompt_password_change_after_login: boolean
  upshifter_punch_card_updates_enabled: boolean
  vaccination_form: any
  title: string
  google_calendar: IGoogleCalendar | null
  mobile_phone: string
  phone_type?: string
  incomplete_workflows: IIncompleteWorkflows | boolean
  total_hours_worked_for_a_business?: string
  total_hours_worked?: string
  is_missing_required_documents?: boolean
  favorite_id_by_business?: TypeOrNull<number>
  gamification_enabled?: boolean
  will_be_overtime_for_this_business?: boolean
  availability?: AVAILABILITY_STATUS
  pre_selected?: boolean

  /**
   * Start Deprecated
   */

  hours_worked: number
  business_id: number
  favorite_id: number | null
  finished_gigs_count: number
  not_reviewed_gigs?: IShift[]
  not_reviewed_gigs_count?: number

  /**
   * End Deprecated
   */
}

export interface ILocalStorageUser extends IUser {
  favorite_id: number | null
}
export enum MY_UPSHIFTER_BLOCKED_LOCATIONS {
  SOME,
  ALL,
}
export interface IMyUpshiftersUser extends IUser {
  block_id?: number
  blocked_locations?: MY_UPSHIFTER_BLOCKED_LOCATIONS
}

interface IUserStats {
  notifications_count: number
  active_punch_cards_count2: number
  active_punch_cards_count: number
  active_strikes_count: number
  expired_strikes_count: number
  pending_gigs_count: number
  accepted_gigs_count: number
  confirmed_gigs_count: number
  active_gigs_count: number
  finished_gigs_count: number
  total_finished_gigs_count: number
  finished_punch_cards_count: number
  hours_worked: string
  total_hours_worked: string
}

export enum AVAILABILITY_STATUS {
  UNAVAILABLE,
  INVITED,
  AVAILABLE,
}

export enum UPSHIFTER_VIEW {
  BADGES = 'Badges',
  EXPERIENCE = 'Experience',
  BIO = 'Bio',
}

export type IDirectDepositInformation = {
  routing_number: string
  account_number: string
}

export type IUpdateDirectDepositInformationParams = {
  routing_number: string
  account_number: string
}

export type IAddressInformation = {
  address: string
  city: string
  postal_code: string
  county: string
  state: string
  country: string
}

export type IAddressInformationParams = {
  address: string
  city: string
  postal_code: string
  county: string
  state: string
  country: string
}

export enum MY_PROFILE_TAB {
  INFO = 'Info',
  RELIABILITY_RATING = 'Reliability Rating',
}
