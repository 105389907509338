import { ITemplatesReducer } from 'models/Template'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { GetTemplates, TemplatesFetching, GetTemplatesErrors } from './actions'

const initialState: ITemplatesReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const templatesReducer = (
  state = initialState,
  action: Action,
): ITemplatesReducer => {
  if (isType(action, GetTemplates)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, GetTemplatesErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }

  if (isType(action, TemplatesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
