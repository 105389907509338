import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from '../../models/Global'
import {
  IPunchCard,
  IGetPendingVerificationPunchCardsParam,
  IPutPunchCardParams,
  IPostPunchCardParams,
} from '../../models/PunchCard'

export class PendingVerificationPunchCardsApi {
  constructor(private axios: AxiosService) {}

  getPendingVerificationPunchCards = async (
    params?: IGetPendingVerificationPunchCardsParam,
  ) => {
    const response = await this.axios.get<IPayload<IPunchCard[]>>(
      Endpoints.getPendingVerificationPunchCards,
      params,
    )
    return response
  }

  getPendingVerificationPunchCard = async (
    id: number,
    params?: IGetPendingVerificationPunchCardsParam,
  ) => {
    const response = await this.axios.get<IPayload<IPunchCard>>(
      Endpoints.getPendingVerificationPunchCard(id),
      params,
    )
    return response
  }

  postPendingVerificationPunchCard = async (params: IPostPunchCardParams) => {
    const response = await this.axios.post<IPayload<IPunchCard>>(
      Endpoints.postPendingVerificationPunchCard,
      params,
    )
    return response
  }

  putPendingVerificationPunchCard = async (
    id: number,
    params?: IPutPunchCardParams,
  ) => {
    const response = await this.axios.put<IPayload<IPunchCard>>(
      Endpoints.putPendingVerificationPunchCard(id),
      params,
    )
    return response
  }

  deletePendingVerificationPunchCard = async (id: number) => {
    const response = await this.axios.delete<{}>(
      Endpoints.deletePendingVerificationPunchCard(id),
    )
    return response
  }
}
