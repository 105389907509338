import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import {
  IGetStatsByDateReportParams,
  IGetReturnRateReportParams,
  IReport,
} from 'models/Report'
import { IPayload } from 'models/Global'

export class ReportsApi {
  constructor(private axios: AxiosService) {}

  getStatsByDateReports = async (params?: IGetStatsByDateReportParams) => {
    const response = await this.axios.get<IPayload<IReport[]>>(
      Endpoints.getStatsByDateReport,
      params,
    )
    return response
  }

  downloadStatsByDateReport = async (params: IGetStatsByDateReportParams) => {
    const response = await this.axios.get<Blob>(
      Endpoints.getStatsByDateReport,
      params,
      {
        responseType: 'blob',
      },
    )
    return response
  }

  getReturnRateReport = async (params: IGetReturnRateReportParams) => {
    const response = await this.axios.get<Blob>(
      Endpoints.getReturnRateReport,
      params,
      {
        responseType: 'blob',
      },
    )
    return response
  }
}
