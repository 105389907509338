import { IPayload } from 'models/Global'
import {
  IChangePasswordParams,
  IForgotPasswordParams,
  IResetPasswordParams,
} from 'models/Password'
import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'

export class PasswordApi {
  constructor(private axios: AxiosService) {}

  forgotPassword = async (params: IForgotPasswordParams) => {
    const response = await this.axios.post<{}>(Endpoints.forgotPassword, params)
    return response
  }

  resetPassword = async (params: IResetPasswordParams) => {
    const response = await this.axios.post<{}>(Endpoints.resetPassword, params)
    return response
  }

  changePassword = async (params: IChangePasswordParams) => {
    const response = await this.axios.post<IPayload<{}>>(
      Endpoints.changePassword,
      params,
    )
    return response
  }
}
