import { action, payload } from 'ts-action'
import { IBusinessEntity, IBusinessSetting } from 'models/BusinessEntity'
import { IError, IPayload } from 'models/Global'

export const BusinessEntityFetching = action(
  'BusinessEntityFetching',
  payload<boolean>(),
)

export const GetBusinessEntity = action(
  'GetBusinessEntity',
  payload<IPayload<IBusinessEntity>>(),
)

export const UpdateBusinessEntitySettings = action(
  'UpdateBusinessEntitySettings',
  payload<Partial<IBusinessSetting>>(),
)

export const BusinessEntityHasMore = action(
  'BusinessEntityHasMore',
  payload<boolean>(),
)

export const GetBusinessEntityErrors = action(
  'GetBusinessEntityErrors',
  payload<IError>(),
)
