import { action, payload } from 'ts-action'
import { INotificationPreference } from 'models/NotificationPreference'
import { IError, IPayload } from 'models/Global'

export const NotificationPreferencesFetching = action(
  'NotificationPreferencesFetching',
  payload<boolean>(),
)

export const GetNotificationPreferences = action(
  'GetNotificationPreferences',
  payload<IPayload<INotificationPreference[]>>(),
)

export const AddNotificationPreferences = action(
  'AddNotificationPreferences',
  payload<IPayload<INotificationPreference>>(),
)

export const UpdateNotificationPreferences = action(
  'UpdateNotificationPreferences',
  payload<IPayload<INotificationPreference>>(),
)

export const GetNotificationPreferencesErrors = action(
  'GetNotificationPreferencesErrors',
  payload<IError>(),
)

export const EnableTextNotificationPreferences = action(
  'EnableTextNotificationPreferences',
)
