import { IUser } from 'models/User'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { deleteFromArray } from 'helpers/helperFuncs'
import {
  GetManagers,
  ManagersFetching,
  GetManagersError,
  DeleteManager,
} from './actions'
import { IBaseReducer } from 'models/BaseReducer'

interface IManagersReducer extends IBaseReducer<IUser[]> {}

const initialState: IManagersReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const managersReducer = (
  state = initialState,
  action: Action,
): IManagersReducer => {
  if (isType(action, GetManagers)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, DeleteManager)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IUser>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, ManagersFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, GetManagersError)) {
    const { payload } = action
    return {
      ...state,
      error: payload,
      data: [],
      meta: {
        code: 0,
        message: '',
      },
    }
  }
  return state
}
