import { Dispatch } from 'redux'
import {
  GetCurrentlyActiveShifts,
  GetCurrentlyActiveShiftsErrors,
  CurrentlyActiveShiftsFetching,
  CurrentlyActiveShiftsSetHasMore,
  ClearCurrentlyActiveShiftsReducer,
  CurrentlyActiveShiftsSetInitialFetchClockedIn,
} from './actions'
import { IGetShiftsParams } from 'models/Shift'

import { API } from 'network'

export type TCurrentlyActiveShiftsActions =
  | ReturnType<typeof GetCurrentlyActiveShifts>
  | ReturnType<typeof GetCurrentlyActiveShiftsErrors>
  | ReturnType<typeof CurrentlyActiveShiftsFetching>
  | ReturnType<typeof ClearCurrentlyActiveShiftsReducer>
  | ReturnType<typeof CurrentlyActiveShiftsSetHasMore>
  | ReturnType<typeof CurrentlyActiveShiftsSetInitialFetchClockedIn>

export const getCurrentlyActiveShifts = (params: IGetShiftsParams) => async (
  dispatch: Dispatch<TCurrentlyActiveShiftsActions>,
) => {
  if (!params.page) {
    dispatch(ClearCurrentlyActiveShiftsReducer())
    dispatch(CurrentlyActiveShiftsSetHasMore(true))
  }
  dispatch(CurrentlyActiveShiftsFetching(true))
  try {
    const response = await API.Shifts.getShifts(params)
    dispatch(GetCurrentlyActiveShifts(response))
    return response
  } catch (error) {
    dispatch(
      GetCurrentlyActiveShiftsErrors({
        code: error.code,
        message: error.message,
      }),
    )
    return error
  } finally {
    dispatch(CurrentlyActiveShiftsFetching(false))
  }
}

export const getCurrentlyActiveShiftsWithClockedInFetch = (
  params: IGetShiftsParams,
) => async (dispatch: Dispatch<TCurrentlyActiveShiftsActions>) => {
  dispatch(CurrentlyActiveShiftsSetInitialFetchClockedIn())

  try {
    const response = await API.Shifts.getShifts(params)
    dispatch(GetCurrentlyActiveShifts(response))
  } catch (error) {
    dispatch(
      GetCurrentlyActiveShiftsErrors({
        code: error.code,
        message: error.message,
      }),
    )
  }
}
