import { action, payload } from 'ts-action'
import { IShiftAutoAcceptCertificate } from 'models/ShiftAutoAcceptCertificate'
import { IError, IPayload } from 'models/Global'

export const ShiftAutoAcceptCertificatesFetching = action(
  'ShiftAutoAcceptCertificatesFetching',
  payload<boolean>(),
)

export const GetShiftAutoAcceptCertificates = action(
  'GetShiftAutoAcceptCertificates',
  payload<IPayload<IShiftAutoAcceptCertificate[]>>(),
)

export const SetSelectedShiftAutoAcceptCertificates = action(
  'SetSelectedShiftAutoAcceptCertificates',
  payload<IPayload<IShiftAutoAcceptCertificate[]>>(),
)

export const GetShiftAutoAcceptCertificatesErrors = action(
  'GetShiftAutoAcceptCertificatesErrors',
  payload<IError>(),
)

export const ClearShiftAutoAcceptCertificates = action(
  'ClearShiftAutoAcceptCertificates',
)
