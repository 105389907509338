import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import { IPayload } from 'models/Global'
import {
  IGetPunchCardsParams,
  IPunchCard,
  IPutPunchCardParams,
  IPostPunchCardParams,
} from 'models/PunchCard'

export class PunchCardsApi {
  constructor(private axios: AxiosService) {}

  getPunchCards = async (params?: IGetPunchCardsParams) => {
    const response = await this.axios.get<IPayload<IPunchCard[]>>(
      Endpoints.getPunchCards,
      params,
    )
    return response
  }

  getPunchCard = async (id: number, params?: IGetPunchCardsParams) => {
    const response = await this.axios.get<IPayload<IPunchCard>>(
      Endpoints.getPunchCard(id),
      params,
    )
    return response
  }

  postPunchCard = async (params: IPostPunchCardParams) => {
    const response = await this.axios.post<IPayload<IPunchCard>>(
      Endpoints.postPunchCard,
      params,
    )
    return response
  }

  putPunchCard = async (id: number, params?: IPutPunchCardParams) => {
    const reponse = await this.axios.put<IPayload<IPunchCard>>(
      Endpoints.putPunchCard(id),
      params,
    )
    return reponse
  }

  deletePunchCard = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deletePunchCard(id))
    return response
  }
}
