import {
  USER_ROLE,
  USER_ROLE_IN_APP,
  BUSINESS_ROLES,
  UPSHIFTER_ROLES,
} from 'models/Auth'
import AuthService from '../authService'
import { IUser, USER_STATUS } from 'models/User'

/**
 *
 * @param role Optional role prop, if it's not passed in, the role is being taken from the currently logged in user
 * @returns Whether the role is a business role
 */
export const isUserBusiness = (role?: USER_ROLE): boolean => {
  let mappedUserRole: USER_ROLE_IN_APP
  if (role) {
    mappedUserRole = mapUserRoleToUserRoleInApp(role)
  } else {
    const loggedInUser = AuthService.getLoggedInUser()
    if (!loggedInUser) {
      return false
    }
    mappedUserRole = mapUserRoleToUserRoleInApp(
      loggedInUser.role,
      loggedInUser.site,
    )
  }

  return BUSINESS_ROLES.indexOf(mappedUserRole) >= 0
}

export const isUserFinanceManager = (role?: USER_ROLE): boolean => {
  let roleToCheck = role
  if (!role) {
    const loggedInUser = AuthService.getLoggedInUser()
    if (!loggedInUser) {
      return false
    }
    roleToCheck = loggedInUser.role
  }
  return USER_ROLE.FINANCE_MANAGER === roleToCheck
}

/**
 *
 * @returns Whether the role is a manager
 */
export const isUserManager = (): boolean => {
  if (
    mapUserRoleToUserRoleInApp(
      ((AuthService.getLoggedInUser() as unknown) as IUser).role,
      ((AuthService.getLoggedInUser() as unknown) as IUser).site,
    ) === USER_ROLE_IN_APP.BUSINESS_MANAGER
  )
    return true
  return false
}

/**
 *
 * @returns Whether the role is a site manager
 */
export const isUserSiteManager = (): boolean => {
  if (
    mapUserRoleToUserRoleInApp(
      ((AuthService.getLoggedInUser() as unknown) as IUser).role,
      ((AuthService.getLoggedInUser() as unknown) as IUser).site,
    ) === USER_ROLE_IN_APP.BUSINESS_SITE_MANAGER
  )
    return true
  return false
}

/**
 *
 * @param role Optional role prop, if it's not passed in, the role is being taken from the currently logged in user
 * @returns Whether the role is an upshifter role
 */
export const isUserUpshifter = (role?: USER_ROLE): boolean => {
  let loggedInUserRole: USER_ROLE_IN_APP
  if (role) {
    loggedInUserRole = mapUserRoleToUserRoleInApp(role)
  } else {
    const loggedInUser = AuthService.getLoggedInUser()
    if (!loggedInUser) {
      return false
    }
    loggedInUserRole = mapUserRoleToUserRoleInApp(
      loggedInUser.role,
      loggedInUser.site,
    )
  }
  return UPSHIFTER_ROLES.indexOf(loggedInUserRole) >= 0
}

export const isUserTemporarilySuspended = () => {
  const user = AuthService.getLoggedInUser() as IUser
  if (user) {
    return Number(user.status) === USER_STATUS.TEMPORARY_SUSPENDED
  }
  return false
}

export const isUserPendingSuspension = () => {
  const user = AuthService.getLoggedInUser() as IUser
  if (user) {
    return Number(user.status) === USER_STATUS.PENDING_SUSPENSION
  }
  return false
}

export const getTemporarilySuspendedUserData = () => {
  const {
    suspended_at,
    suspended_until,
  } = AuthService.getLoggedInUser() as IUser

  return {
    suspended_at,
    suspended_until,
  }
}

export const mapUserRoleToUserRoleInApp = (
  role: USER_ROLE,
  site?: boolean,
): USER_ROLE_IN_APP => {
  switch (role) {
    case USER_ROLE.BUSINESS:
      return USER_ROLE_IN_APP.BUSINESS_ADMIN
    case USER_ROLE.EMPLOYEE:
      if (site) return USER_ROLE_IN_APP.BUSINESS_SITE_MANAGER
      return USER_ROLE_IN_APP.BUSINESS_MANAGER
    case USER_ROLE.APPROVER:
      return USER_ROLE_IN_APP.BUSINESS_APPROVER
    case USER_ROLE.VIEWER:
      return USER_ROLE_IN_APP.BUSINESS_VIEWER
    case USER_ROLE.FINANCE_MANAGER:
      return USER_ROLE_IN_APP.BUSINESS_FINANCE_MANAGER
    default:
      return USER_ROLE_IN_APP.UPSHIFTER
  }
}

export const mapUserRoleInAppToUserRole = (
  role: USER_ROLE_IN_APP,
): USER_ROLE => {
  switch (role) {
    case USER_ROLE_IN_APP.BUSINESS_VIEWER:
      return USER_ROLE.VIEWER
    case USER_ROLE_IN_APP.BUSINESS_APPROVER:
      return USER_ROLE.APPROVER
    case USER_ROLE_IN_APP.BUSINESS_FINANCE_MANAGER:
      return USER_ROLE.FINANCE_MANAGER
    case USER_ROLE_IN_APP.BUSINESS_SITE_MANAGER:
      return USER_ROLE.EMPLOYEE
    case USER_ROLE_IN_APP.BUSINESS_MANAGER:
      return USER_ROLE.EMPLOYEE
    case USER_ROLE_IN_APP.BUSINESS_ADMIN:
      return USER_ROLE.BUSINESS
    // If the role is USER_ROLE_IN_APP.UPSHIFTER
    default:
      return USER_ROLE.LABOR
  }
}
