import { IShift, IShiftValuesReducer } from 'models/Shift'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  SaveEditValues,
  SaveDuplicateValues,
  SaveShift,
  ClearEditValues,
  ClearDuplicateValues,
  ClearShift,
} from './actions'
import {
  IModalDuplicateShiftValues,
  IModalEditShiftValues,
} from 'helpers/shiftHelpers/types'

const initialState: IShiftValuesReducer = {
  initialShiftValues: {} as IModalEditShiftValues,
  editShiftValues: {} as IModalEditShiftValues,
  duplicateShiftValues: {} as IModalDuplicateShiftValues,
  shiftFromModal: {} as IShift,
}

export const shiftValuesReducer = (
  state = initialState,
  action: Action,
): IShiftValuesReducer => {
  if (isType(action, SaveEditValues)) {
    const { payload } = action
    return {
      ...state,
      editShiftValues: payload.newValues,
      initialShiftValues: payload.oldValues,
    }
  }
  if (isType(action, SaveDuplicateValues)) {
    const { payload } = action
    return {
      ...state,
      duplicateShiftValues: payload,
    }
  }
  if (isType(action, SaveShift)) {
    const { payload } = action
    return {
      ...state,
      shiftFromModal: payload,
    }
  }
  if (isType(action, ClearEditValues)) {
    return {
      ...state,
      editShiftValues: {} as IModalEditShiftValues,
      initialShiftValues: {} as IModalEditShiftValues,
    }
  }
  if (isType(action, ClearDuplicateValues)) {
    return {
      ...state,
      duplicateShiftValues: {} as IModalDuplicateShiftValues,
    }
  }
  if (isType(action, ClearShift)) {
    return {
      ...state,
      shiftFromModal: {} as IShift,
    }
  }
  return state
}
