import { IBaseReducer } from '../BaseReducer'
import { IBaseGetParams } from '../Global'
import { IInvoiceGroup } from '../InvoiceGroup'
import { IProperty } from '../Property'
import { ITimesheet } from '../Timesheet'
import { ICredit } from '../Credit'
import { IBusinessEntity } from '../BusinessEntity'
import { IUser } from '../User'
import { INote } from '../Note'
import { IInvoiceExpenseType } from '../InvoiceExpenseType'
import { INetOption } from 'models/NetOption'
import { TypeOrUndefined } from 'models/Common'
import { IPayment, PAYMENT_GATEWAY_ENUM, PAYMENT_STATUS } from 'models/Payment'

export enum INVOICE_STATUS {
  PENDING,
  PROCESSED,
  DECLINED,
  ACTIVE,
}

export enum INVOICE_PAID_STATUS {
  NOT_PAID,
  FULLY_PAID,
  PARTIALLY_PAID,
  OVERPAID,
  PROCESSING,
}

export enum INVOICE_DUE_STATUS {
  DUE,
  NOT_DUE,
}

export enum INVOICE_OVERDUE_STATUS {
  NOT_OVERDUE,
  OVERDUE,
}

export enum PAYMENT_TYPE_ID {
  LOW_CREDIT_CARD = 1,
  NET = 2,
}

export enum PAYMENT_TYPE_NAME {
  LOW_CREDIT_CARD = 'low_credit_card',
  NET = 'net_30',
}

export interface IInvoiceIncludeParams {
  include?:
    | {
        business?: Record<any, any>
        net_option?: Record<any, any>
        payment_type?: Record<any, any>
        invoice_group?: Record<any, any>
        last_payment?: Record<any, any>
      }
    | (
        | 'business'
        | 'net_option'
        | 'payment_type'
        | 'invoice_group'
        | 'last_payment'
      )[]
}

export interface IInvoice {
  id: number
  invoice_group_id: number
  property_id: number
  property_name: string
  property_address: string
  invoice_number: number | null
  total: string | null
  pay_total_2: string
  // pay_total: string
  sub_total_2: string
  // sub_total: string
  tax_total_2: string
  // tax_total: string
  commission_total_2: string
  // commission_total: string
  from: Date
  to: Date
  paid: false
  paid_status: INVOICE_PAID_STATUS
  note: string | null
  pdf_url: string | null
  processed_at: string | null
  created_at: Date
  updated_at: Date
  status: INVOICE_STATUS
  total_difference: number
  amount_paid: string
  due_date: Date
  can_be_marked_as_sent: boolean
  sent: boolean
  po_number: string
  sent_at: Date
  payment_type: IPaymentType
  payment_status: PAYMENT_STATUS
  invoice_group: IInvoiceGroup
  is_pay_button_enabled: boolean
  payment_fee?: string
  total_with_payment_fee?: string
  net_option?: TypeOrUndefined<INetOption>
  payment_type_name: string
  last_payment?: IPayment

  /**
   * Start Deprecated
   */

  owner_id: number
  owner_full_name: string
  owner_company_name: string
  net_option_name: string

  /**
   * End Deprecated
   */
}

export interface ILedgerInvoice {
  business: IBusinessEntity
  credits: ICredit[]
  expense_types: IInvoiceExpenseType[]
  invoice: IInvoice
  invoice_group: IInvoiceGroup
  properties: IProperty[]
  timesheets: ITimesheet[]
  user: IUser
  payment_type: IPaymentType
  net_option?: TypeOrUndefined<INetOption>
  last_payment?: IPayment
}

export interface IGetInvoicesParamsBase extends IBaseGetParams {
  invoice_group_id?: number
  business_id?: number
  payment_type?: string
  payment_status?: PAYMENT_STATUS
  regions?: number[]
  status?: INVOICE_STATUS
  due_date_start?: string
  due_date_end?: string
  due_status?: INVOICE_DUE_STATUS
  paid_status?: INVOICE_PAID_STATUS | INVOICE_PAID_STATUS[]
  overdue?: INVOICE_OVERDUE_STATUS
}

export type IGetInvoicesParams = IGetInvoicesParamsBase & IInvoiceIncludeParams

export interface IGetInvoiceParamsBase {
  ledger?: boolean
}

export type IGetInvoiceParams = IGetInvoiceParamsBase & IInvoiceIncludeParams

export interface IGetInvoiceBatch {
  invoice_id: number
  invoice_group_id: number
}

export interface IPostInvoiceParamsBase {
  property_id: number
  from: Date
  to: Date
  status?: INVOICE_STATUS
  note?: string
  paid_status?: INVOICE_PAID_STATUS
}

export type IPostInvoiceParams = IPostInvoiceParamsBase & IInvoiceIncludeParams

export interface IPutInvoiceParamsBase {
  status?: number
  note?: string
  amount_paid?: number
  paid_status?: INVOICE_PAID_STATUS
}

export type IPutInvoiceParams = IPutInvoiceParamsBase & IInvoiceIncludeParams

export interface IInvoiceData {
  business?: IBusinessEntity
  invoice: IInvoice
  invoiceGroup: IInvoiceGroup
  properties: IProperty[]
  timesheets: ITimesheet[]
  credits: ICredit[]
  note: INote[]
  additionalExpenseTypes: IInvoiceExpenseType[]
  pre_ledger_invoice_payment_type_name?: string
  /**
   * [PAYMENT FLOW] For Processed Invoices, ledger invoices are fetched.
   * regularInvoice state is used to store the regular invoice alongside ledger invoice data.
   * Needed for PaymentFlow.
   */
  regularInvoice?: IInvoice
}

export interface IGetInvoicesStatementParams {
  business_id: number
  status?: INVOICE_STATUS
  due_date_start?: string
  due_date_end?: string
  due_status?: INVOICE_DUE_STATUS
  paid_status?: INVOICE_PAID_STATUS
}

export interface IInvoicesReducer extends IBaseReducer<IInvoice[]> {
  overdueInvoices: number
}

export interface IInvoiceReducer extends IBaseReducer<IInvoiceData> {}

export interface IPaymentType {
  id: PAYMENT_TYPE_ID
  name: PAYMENT_TYPE_NAME
  status: number
}

export interface IPayInvoiceParams {
  payment_gateway_enum: PAYMENT_GATEWAY_ENUM
}
