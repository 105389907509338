import { SLACK_CHANNEL } from 'config/envVariables'
import {
  slackLongRequestsChannelWebHook,
  slackWebHook,
} from 'network/config/utils'

interface ISlackLog {
  title: string
  color: string
  text: string
}

interface ISlackLogParams {
  attachments: ISlackLog[]
  channel: string
}

/**
 * [GET] Log to Slack.
 */
export const logToSlack = ({ title, color, text }: ISlackLog) => {
  const parameters: ISlackLogParams = {
    attachments: [
      {
        title,
        color,
        text,
      },
    ],
    channel: `${SLACK_CHANNEL}`,
  }
  slackWebHook
    .post('', parameters, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then(response => {})
    .catch(error => {})
    .then(() => {})
}

/**
 * [GET] Log to Slack.
 * This is experimental logger that logs extra long requests ( more than 3s)
 */
export const logLongRequestsToSlack = ({ title, color, text }: ISlackLog) => {
  const parameters: ISlackLogParams = {
    attachments: [
      {
        title,
        color,
        text,
      },
    ],
    channel: `#logs_rct_prod_long_requests`,
  }
  slackLongRequestsChannelWebHook
    .post('', parameters, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then(response => {})
    .catch(error => {})
    .then(() => {})
}
