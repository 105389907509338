import { action, payload } from 'ts-action'
import { ICreditCard } from 'models/CreditCard'
import { IError, IPayload } from 'models/Global'

export const CreditCardsFetching = action(
  'CreditCardsFetching',
  payload<boolean>(),
)

export const GetCreditCards = action(
  'GetCreditCards',
  payload<IPayload<ICreditCard[]>>(),
)

export const AddCreditCard = action(
  'AddCreditCard',
  payload<IPayload<ICreditCard>>(),
)

export const UpdateCreditCard = action(
  'UpdateCreditCard',
  payload<IPayload<ICreditCard>>(),
)

export const DeleteCreditCard = action('DeleteCreditCard', payload<number>())

export const GetCreditCardsErrors = action(
  'GetCreditCardsErrors',
  payload<IError>(),
)
