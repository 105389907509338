import { IBaseReducer } from 'models/BaseReducer'
import { IBusinessEntity } from 'models/BusinessEntity'
import { IBaseGetParams } from 'models/Global'

export enum ACH_ACCOUNT_ACCOUNT_TYPE {
  SAVING = 'saving',
  CHECKING = 'checking',
}

export enum ACH_ACCOUNT_AUTHORIZATION_METHOD {
  TELEPHONE = 'telephone',
  PAPER = 'paper',
}

export interface IAchAccount {
  id: number
  bank_name: string
  account_number_last_two: string
  ach_account: string
  account_type: ACH_ACCOUNT_ACCOUNT_TYPE
  account_holder_name: string
  offline_account_pdf: string
  authorization_method: ACH_ACCOUNT_AUTHORIZATION_METHOD
  business_id: IBusinessEntity['id']
  status: number
}

export interface IAchAccountBillingRequestId {
  billing_request_id: string
}

export interface IPostAchAccountInitParams {
  business_id: IBusinessEntity['id']
  authorization_method?: ACH_ACCOUNT_AUTHORIZATION_METHOD // Required only for Admins
}

export interface IPostAchAccountCustomerDetailsParams
  extends IAchAccountBillingRequestId {
  business_id: IBusinessEntity['id']
  company_name: string
  email: string
  billing_address: string
  billing_address2: string
  city: string
  zip_code: string
  state: string
}

export interface IPostAchAccountBankDetailsParams
  extends IAchAccountBillingRequestId {
  account_number: string
  account_type: ACH_ACCOUNT_ACCOUNT_TYPE
  routing_number: string
  account_holder_name: string
}

export interface IPostAchAccountConfirmParams
  extends IAchAccountBillingRequestId {
  business_id: IBusinessEntity['id']
  authorization_method?: ACH_ACCOUNT_AUTHORIZATION_METHOD // Required only for Admins
  offline_account_pdf?: string // Required only for Admins
}

export interface IGetAchAccountsParams extends IBaseGetParams {
  business_id: IBusinessEntity['id']
}

export interface IAchAccountsReducer extends IBaseReducer<IAchAccount[]> {}
