import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import { IPayload } from 'models/Global'
import {
  IPostSickLeaveTemplateParams,
  IGetSickLeaveTemplatesParams,
  ISickLeaveTemplate,
  IPutSickLeaveTemplateParams,
} from 'models/SickLeaveTemplate'

export class SickLeaveTemplatesApi {
  constructor(private axios: AxiosService) {}

  getSickLeaveTemplates = async (params?: IGetSickLeaveTemplatesParams) => {
    const response = await this.axios.get<IPayload<ISickLeaveTemplate[]>>(
      Endpoints.getSickLeaveTemplates,
      params,
    )
    return response
  }

  postSickLeaveTemplate = async (params: IPostSickLeaveTemplateParams) => {
    const response = await this.axios.post<IPayload<ISickLeaveTemplate>>(
      Endpoints.postSickLeaveTemplate,
      params,
    )
    return response
  }

  putSickLeaveTemplate = async (
    id: number,
    params: IPutSickLeaveTemplateParams,
  ) => {
    const response = await this.axios.put<IPayload<ISickLeaveTemplate>>(
      Endpoints.putSickLeaveTemplate(id),
      params,
    )
    return response
  }

  deleteSickLeaveTemplate = async (id: number) => {
    const response = await this.axios.delete<{}>(
      Endpoints.deleteSickLeaveTemplate(id),
    )
    return response
  }
}
