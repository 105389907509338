import { action, payload } from 'ts-action'
import { INotification } from 'models/Notification'
import { IError, IPayload } from 'models/Global'

export const UnreadNotificationsFetching = action(
  'UnreadNotificationsFetching',
  payload<boolean>(),
)

export const GetUnreadNotifications = action(
  'GetUnreadNotifications',
  payload<IPayload<INotification[]>>(),
)

export const GetUnreadNotificationsErrors = action(
  'GetUnreadNotificationsErrors',
  payload<IError>(),
)

export const DeleteUnreadNotification = action(
  'DeleteUnreadNotification',
  payload<number>(),
)

export const UpdateUnreadNotification = action(
  'UpdateUnreadNotification',
  payload<IPayload<INotification>>(),
)

export const UpdateAllUnreadNotificationsStatus = action(
  'UpdateAllUnreadNotificationsStatus',
)

export const ClearUnreadNotifications = action('ClearUnreadNotifications')
