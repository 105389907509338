import { action, payload } from 'ts-action'
import {
  IModalDuplicateShiftValues,
  IModalEditShiftValues,
} from 'helpers/shiftHelpers/types'
import { IShift } from 'models/Shift'

export const SaveEditValues = action(
  'SaveEditValues',
  payload<{
    newValues: IModalEditShiftValues
    oldValues: IModalEditShiftValues
  }>(),
)

export const SaveDuplicateValues = action(
  'SaveDuplicateValues',
  payload<IModalDuplicateShiftValues>(),
)

export const SaveShift = action('SaveShift', payload<IShift>())

export const ClearEditValues = action('ClearEditValues')

export const ClearDuplicateValues = action('ClearDuplicateValues')

export const ClearShift = action('ClearShift')
