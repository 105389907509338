import {
  AddFavorite as FavoriteUpshifter,
  DeleteFavorite,
  UnfavoriteUpshifter,
} from 'data/business/Favorite/actions'
import { addToArray, deleteFromArray } from 'helpers/helperFuncs'
import { IMyUpshiftersUser, IUpshiftersReducer } from 'models/User'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  GetUpshifters,
  UpshiftersFetching,
  UpshiftersDeleteUpshifter,
  AddUpshifter,
} from './actions'

const initialState: IUpshiftersReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const upshiftersReducer = (
  state = initialState,
  action: Action,
): IUpshiftersReducer => {
  if (isType(action, GetUpshifters)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UpshiftersFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, FavoriteUpshifter)) {
    const {
      payload: { data },
    } = action
    const updatedUpshifters = state.data.map(upshifter => {
      if (upshifter.id === data.model_favored.id) {
        return {
          ...upshifter,
          favorite: true,
          favorite_id: data.id,
        }
      }
      return upshifter
    })

    // If the Business user Favorites an Upshifter via All/Blocked tabs, then the Upshifter remains in the UpshiftersList list and here we just return the same list but with changed 'favorite' property.
    if (state.data.find(item => item.id === data.model_favored.id)) {
      return {
        ...state,
        data: updatedUpshifters,
      }
    }
    // Otherwise, if the Business user Favorites an Upshifter via Favorites (My Team) tab, then the Upshifter needs to be added in the Upshifters List, since the list displayed in My Team tab is changing its list items depending on the Favorite/Unfavorite actions.
    return {
      ...state,
      data: [
        ...updatedUpshifters,
        (data.model_favored as unknown) as IMyUpshiftersUser,
      ],
    }
  }
  if (isType(action, DeleteFavorite)) {
    const { payload } = action
    const { data } = state
    const formattedArray = data.map(item => {
      if (item.id !== payload) {
        return item
      }
      return { ...item, favorite: false, favorite_id: null }
    })

    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, UnfavoriteUpshifter)) {
    const { payload } = action
    const { data, meta } = state
    const formattedArray = deleteFromArray<IMyUpshiftersUser>(data, payload)
    return {
      ...state,
      data: formattedArray,
      meta: {
        ...meta,
        pagination: {
          ...meta.pagination,
          count: meta.pagination ? meta.pagination.count - 1 : 0,
          total: meta.pagination ? meta.pagination.total - 1 : 0,
          per_page: meta.pagination?.per_page || 50,
          current_page: meta.pagination?.current_page || 1,
          total_pages: meta.pagination?.total_pages || 1,
          links: meta.pagination?.links || [],
        },
      },
    }
  }
  if (isType(action, AddUpshifter)) {
    const {
      payload: { data: item },
    } = action
    const { data } = state
    return {
      ...state,
      data: addToArray<IMyUpshiftersUser>(item, data),
    }
  }
  if (isType(action, UpshiftersDeleteUpshifter)) {
    const { payload } = action
    const { data, meta } = state
    const formattedArray = deleteFromArray<IMyUpshiftersUser>(data, payload)
    return {
      ...state,
      data: formattedArray,
      meta: {
        ...meta,
        // Adjusting pagination counts after blocking/unblocking a user from My Upshifters page tabs
        pagination: {
          ...meta.pagination,
          count: meta.pagination ? meta.pagination.count - 1 : 0,
          total: meta.pagination ? meta.pagination.total - 1 : 0,
          per_page: meta.pagination?.per_page || 50,
          current_page: meta.pagination?.current_page || 1,
          total_pages: meta.pagination?.total_pages || 1,
          links: meta.pagination?.links || [],
        },
      },
    }
  }
  return state
}
