import { action, payload } from 'ts-action'
import { IUserCertificate } from 'models/UserCertificate'
import { IError, IPayload } from 'models/Global'

export const UserCertificatesFetching = action(
  'UserCertificatesFetching',
  payload<boolean>(),
)

export const GetUserCertificates = action(
  'GetUserCertificates',
  payload<IPayload<IUserCertificate[]>>(),
)

export const GetUserCertificatesErrors = action(
  'GetUserCertificatesErrors',
  payload<IError>(),
)
