import { Dispatch } from 'redux'
import {
  ClearUnreadNotifications,
  DeleteUnreadNotification,
  GetUnreadNotifications as GetUnreadNotificationsAction,
  GetUnreadNotificationsErrors,
  UnreadNotificationsFetching,
  UpdateUnreadNotification,
} from './actions'
import { NotificationManager } from 'helpers/NotificationsManager'
import {
  IGetNotificationsParams,
  NOTIFICATION_STATUS,
} from 'models/Notification'
import { API } from 'network'
import {
  DeleteNotification,
  UpdateAllNotificationsStatus,
  UpdateNotification,
} from 'data/business/Notifications/actions'

export type UnreadNotificationsActions =
  | ReturnType<typeof GetUnreadNotificationsAction>
  | ReturnType<typeof GetUnreadNotificationsErrors>
  | ReturnType<typeof UnreadNotificationsFetching>
  | ReturnType<typeof ClearUnreadNotifications>
  | ReturnType<typeof UpdateAllNotificationsStatus>
  | ReturnType<typeof UpdateNotification>
  | ReturnType<typeof DeleteNotification>
  | ReturnType<typeof DeleteUnreadNotification>
  | ReturnType<typeof UpdateUnreadNotification>

export const getUnreadNotifications = (
  params?: IGetNotificationsParams,
) => async (dispatch: Dispatch<UnreadNotificationsActions>) => {
  let response = null
  try {
    response = await API.Notifications.getNotifications({
      ...params,
      status: NOTIFICATION_STATUS.NOT_READ,
    })
    dispatch(GetUnreadNotificationsAction(response))
  } catch (error) {
    dispatch(
      GetUnreadNotificationsErrors({
        code: error.code,
        message: error.message,
      }),
    )
    NotificationManager.error(
      'A problem occurred while getting the Notifications.',
    )
  } finally {
  }
  return response
}

export const readNotifications = () => async (
  dispatch: Dispatch<UnreadNotificationsActions>,
) => {
  dispatch(UnreadNotificationsFetching(true))
  let response = null
  try {
    response = await API.Notifications.readNotifications()
    dispatch(UpdateAllNotificationsStatus())
    dispatch(ClearUnreadNotifications())
  } catch (error) {
    dispatch(
      GetUnreadNotificationsErrors({
        code: error.code,
        message: error.message,
      }),
    )
    NotificationManager.error(
      'A problem occurred while reading the Notifications.',
    )
  } finally {
    dispatch(UnreadNotificationsFetching(false))
  }
  return response
}

export const readNotification = (id: number) => async (
  dispatch: Dispatch<UnreadNotificationsActions>,
) => {
  let response = null
  try {
    response = await API.Notifications.putNotification(id, {
      status: NOTIFICATION_STATUS.READ,
    })
    dispatch(UpdateNotification(response))
    dispatch(DeleteUnreadNotification(id))
  } catch (error) {
    dispatch(
      GetUnreadNotificationsErrors({
        code: error.code,
        message: error.message,
      }),
    )
    NotificationManager.error(
      'A problem occurred while reading the Notification.',
    )
  }
  return response
}

export const deleteNotification = (id: number) => async (
  dispatch: Dispatch<UnreadNotificationsActions>,
) => {
  let response = null
  try {
    response = await API.Notifications.deleteNotification(id)
    dispatch(DeleteNotification(id))
    dispatch(DeleteUnreadNotification(id))
  } catch (error) {
    dispatch(
      GetUnreadNotificationsErrors({
        code: error.code,
        message: error.message,
      }),
    )
    NotificationManager.error(
      'A problem occurred while deleting the Notification.',
    )
  }
  return response
}

export const clearUnreadNotifications = () => (
  dispatch: Dispatch<UnreadNotificationsActions>,
) => {
  dispatch(ClearUnreadNotifications())
}
