import { IBaseGetParams } from '../Global'
import { IBaseReducer } from '../BaseReducer'

export enum NOTIFICATION_ACTION {
  VIEW_APPLICATION = 'view_applications',
  VIEW_USER = 'view_user',
  VIEW_PROPERTY = 'view_property',
  VIEW_SHIFT = 'view_shift',
  VIEW_SHIFTS = 'view_shifts',
  VIEW_PAYMENT = 'view_payment',
  VIEW_PENDING_APPLICANTS = 'view_pending_applicants',
  VIEW_PROPERTIES = 'view_properties',
  VIEW_RELIABILITY_RATING = 'view_reliability_rating',
  VIEW_INTERCOM_SUPPORT_TICKET = 'view_intercom_support_ticket',
  VIEW_INTERCOM_OUTBOUND_MESSAGE = 'view_intercom_outbound_message',
  VIEW_ACCEPTED_SHIFTS = 'view_accepted_shifts',
  VIEW_BUSINESS_SHIFTS = 'view_business_shifts',
  EDIT_SHIFT = 'edit_shift',
  VIEW_GROUP_INVITES = 'view_group_invites',
  VIEW_GROUPS = 'view_groups',
  VIEW_ACKNOWLEDGE_DOCUMENTS = 'view_acknowledge_documents',
  VIEW_ACHIEVEMENT_COMPLETED = 'view_achievement_completed',
}

export enum NOTIFICATION_TYPE {
  DEFAULT,
  GIG,
  CLOCK_IN,
  CLOCK_OUT,
  CERTIFICATE,
  PROPERTY,
  RELIABILITY_RATING,
  ZENDESK,
  INTERCOM,
  GROUP_INVITE,
  GROUP,
  ACKNOWLEDGE_DOCUMENT,
  ACHIEVEMENT_COMPLETE,
}

export enum NOTIFICATION_STATUS {
  NOT_READ,
  READ,
}

export interface INotificationIncludeParams {
  include?:
    | {
        receiver?: Record<any, any>
        sender?: Record<any, any>
      }
    | ('receiver' | 'sender')[]
}

export interface INotification {
  id: number
  receiver_id: number
  sender_id: number
  payload: INotificationPayload
  status: number
  received: boolean
  sent_at: string

  /**
   * Start Deprecated
   */

  receiver_full_name: string
  receiver_avatar_thumb_url: string
  sender_full_name: string
  sender_avatar_thumb_url: string

  /**
   * End Deprecated
   */
}

export interface IGetNotificationsParamsBase extends IBaseGetParams {
  notification_channel_id?: number
  notification_channel_code?: string
  receiver_id?: number
  sender_id?: number
  status?: NOTIFICATION_STATUS
}

export type IGetNotificationsParams = IGetNotificationsParamsBase &
  INotificationIncludeParams

export interface IPostNotificationParamsBase {
  receiver_id: number
  payload: IPostNotificationPayload
  gig_filters?: any[]
}

export type IPostNotificationParams = IPostNotificationParamsBase &
  INotificationIncludeParams
export interface IPutNotificationParamsBase {
  status: number
}

export type IPutNotificationParams = IPutNotificationParamsBase &
  INotificationIncludeParams

export interface INotificationsReducer extends IBaseReducer<INotification[]> {}

interface INotificationPayload {
  title: string
  body: string
  action: string
  body_template: string
  type: string
  gig_id?: number
  application_id?: number
  business_id?: number
  business_name?: string
  achievement_id?: number
}

interface IPostNotificationPayload {
  title?: string
  body: string
  action?: string
  type?: string
}
