import { Action } from 'redux'
import { isType } from 'ts-action'
import { IScheduledUpshifterReducer } from 'models/ScheduledUpshifter'
import {
  ScheduledUpshiftersFetching,
  GetScheduledUpshifters,
  ClearScheduledUpshifters,
  HasMore,
  ScheduledUpshiftersRemoveApplicant,
  ScheduledUpshiftersRemoveReviewApplicant,
  GetScheduledUpshiftersErrors,
} from './actions'
import { filterUpshifters, removeReviewActionForUpshifter } from './utils'

const initialState: IScheduledUpshifterReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const scheduledUpshiftersReducer = (
  state = initialState,
  action: Action,
): IScheduledUpshifterReducer => {
  if (isType(action, GetScheduledUpshifters)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, ClearScheduledUpshifters)) {
    return {
      ...state,
      data: [],
    }
  }
  if (isType(action, HasMore)) {
    const { payload } = action
    return {
      ...state,
      hasMore: payload,
    }
  }
  if (isType(action, ScheduledUpshiftersRemoveApplicant)) {
    const { propertyId, gigTypeId, upshifterId, gigId } = action.payload
    const _gigId = gigId ? gigId : 0
    const scheduledUpshifters = filterUpshifters(
      state.data,
      propertyId,
      gigTypeId,
      upshifterId,
      _gigId,
    )
    return {
      ...state,
      data: scheduledUpshifters,
    }
  }
  if (isType(action, ScheduledUpshiftersRemoveReviewApplicant)) {
    const { propertyId, gigTypeId, upshifterId, gigId } = action.payload
    const _gigId = gigId ? gigId : 0
    const scheduledUpshifters = removeReviewActionForUpshifter(
      state.data,
      propertyId,
      gigTypeId,
      upshifterId,
      _gigId,
    )
    return {
      ...state,
      data: [...scheduledUpshifters],
    }
  }
  if (isType(action, GetScheduledUpshiftersErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }
  if (isType(action, ScheduledUpshiftersFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
