import { action, payload } from 'ts-action'
import { IApplication } from 'models/Application'
import { IError, IPayload } from 'models/Global'

export const ApplicationsFetching = action(
  'ApplicationsFetching',
  payload<boolean>(),
)

export const GetApplications = action(
  'GetApplications',
  payload<IPayload<IApplication[]>>(),
)

export const GetApplication = action(
  'GetApplication',
  payload<IPayload<IApplication>>(),
)

export const AddApplication = action(
  'AddApplication',
  payload<IPayload<IApplication>>(),
)

export const UpdateApplication = action(
  'UpdateApplication',
  payload<IPayload<IApplication>>(),
)

export const DeleteApplication = action('DeleteApplication', payload<number>())

export const DeleteApplicationByUserId = action(
  'DeleteApplicationByUserId',
  payload<number>(),
)

export const GetApplicationsErrors = action(
  'GetApplicationsErrors',
  payload<IError>(),
)

export const CleanApplications = action('CleanApplications')
