import { datadogRum } from '@datadog/browser-rum'
import {
  DATADOG_APP_KEY,
  DATADOG_CLIENT_TOKEN,
  ENV_NAME,
  VERSION_COMMIT_HASH,
} from 'config/envVariables'
import { IUser } from 'models/User'

export default class MonitoringService {
  /**
   * Initialization of the monitoring services
   */
  static initialization = () => {
    // Datadog
    datadogRum.init({
      applicationId: DATADOG_APP_KEY as string,
      clientToken: DATADOG_CLIENT_TOKEN as string,
      // TODO: Once the repos are split we need to change the service
      service: 'spectre',
      env: ENV_NAME,
      allowedTracingUrls: [
        'https://api.staging.upshift.work',
        'https://api.upshift.work',
        'https://api.release.upshift.work',
      ],
      // Specify a version number to identify the deployed version of your application in Datadog
      version: VERSION_COMMIT_HASH,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 5,
      traceSampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      enableExperimentalFeatures: ['clickmap'],
    })

    datadogRum.startSessionReplayRecording()
  }

  /**
   * Logs errors to monitoring services
   * @param error Error object
   */
  static logError = (error: any) => {
    // Datadog
    datadogRum.addError(error)
  }

  /**
   * Sets the logged in user in monitoring services
   * @param user Currently logged in user
   */
  static setUser = (user: IUser) => {
    // Datadog
    datadogRum.setUser({
      email: user.email,
      id: user.id.toString(),
      name: user.full_name,
    })
    datadogRum.setUserProperty('role', user.role)
  }

  /**
   * Removes the current logged in user from monitoring services
   */
  static clearUser = () => {
    // Datadog
    datadogRum.clearUser()
  }

  /**
   * Sets transaction correlation id
   * @param transactionId
   */
  static setCorrelationId = (transactionId: string | number) => {
    // TODO: Datadog implementation
  }
}
