import { action, payload } from 'ts-action'
import { IAcknowledgedDocument } from 'models/AcknowledgedDocument'
import { IError, IPayload } from 'models/Global'

export const AcknowledgedDocumentsFetching = action(
  'AcknowledgedDocumentsFetching',
  payload<boolean>(),
)

export const GetAcknowledgedDocuments = action(
  'GetAcknowledgedDocuments',
  payload<IPayload<IAcknowledgedDocument[]>>(),
)

export const AddAcknowledgedDocument = action(
  'AddAcknowledgedDocument',
  payload<IPayload<IAcknowledgedDocument>>(),
)

export const UpdateAcknowledgedDocument = action(
  'UpdateAcknowledgedDocument',
  payload<IPayload<IAcknowledgedDocument>>(),
)

export const DeleteAcknowledgedDocument = action(
  'DeleteAcknowledgedDocument',
  payload<number>(),
)

export const GetAcknowledgedDocumentsErrors = action(
  'GetAcknowledgedDocumentsErrors',
  payload<IError>(),
)
