import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from '../../models/Global'
import {
  IRequestApplication,
  IPreselectedRequestApplicationUsersResponse,
  IGetRequestApplicationsParams,
  IPostRequestApplicationParams,
  IRequestApplicationUser,
} from '../../models/RequestApplication'

export class RequestApplicationsApi {
  constructor(private axios: AxiosService) {}

  getRequestApplications = async (params?: IGetRequestApplicationsParams) => {
    const response = await this.axios.get<IPayload<IRequestApplication[]>>(
      Endpoints.getRequestApplications,
      params,
    )
    return response
  }

  getRequestApplicationsUsers = async (
    params?: IGetRequestApplicationsParams,
  ) => {
    const response = await this.axios.get<IPayload<IRequestApplicationUser[]>>(
      Endpoints.getRequestApplicationsUsers,
      params,
    )
    return response
  }

  postRequestApplication = async (params: IPostRequestApplicationParams) => {
    const response = await this.axios.post<IPayload<IRequestApplication[]>>(
      Endpoints.postRequestApplication,
      params,
    )
    return response
  }

  deleteRequestApplication = async (id: number) => {
    const response = await this.axios.delete<{}>(
      Endpoints.deleteRequestApplication(id),
    )
    return response
  }

  getPreselectedRequestApplicationsUsers = async (
    params?: IGetRequestApplicationsParams,
  ) => {
    const response = await this.axios.get<
      IPayload<IPreselectedRequestApplicationUsersResponse>
    >(Endpoints.getPreselectedRequestApplicationUsers, params)
    return response
  }
}
