import { IApplicationsReducer, IApplication } from 'models/Application'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { deleteFromArray, updateArray } from 'helpers/helperFuncs'
import {
  GetApplications,
  ApplicationsFetching,
  UpdateApplication,
  DeleteApplication,
  DeleteApplicationByUserId,
  CleanApplications,
} from './actions'
import { AddFavorite, DeleteFavorite } from 'data/business/Favorite/actions'

const initialState: IApplicationsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const applicationsReducer = (
  state = initialState,
  action: Action,
): IApplicationsReducer => {
  if (isType(action, GetApplications)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UpdateApplication)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IApplication>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, DeleteApplicationByUserId)) {
    const { payload } = action
    const { data } = state
    const formattedArray = data.filter(item => item.applicant.id !== payload)
    // The application was not deleted
    if (data.length === formattedArray.length) {
      return {
        ...state,
        data: formattedArray,
      }
    }
    // It was successfully deleted
    else {
      return {
        ...state,
        data: formattedArray,
        meta: {
          ...state.meta,
          pagination: {
            ...state.meta.pagination,
            count: state.meta.pagination ? state.meta.pagination.count - 1 : 0,
            total: state.meta.pagination ? state.meta.pagination.total - 1 : 0,
            per_page: state.meta.pagination?.per_page || 50,
            current_page: state.meta.pagination?.current_page || 1,
            total_pages: state.meta.pagination?.total_pages || 1,
            links: state.meta.pagination?.links || [],
          },
        },
      }
    }
  }
  if (isType(action, DeleteApplication)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IApplication>(data, payload)
    // The application was not deleted
    if (data.length === formattedArray.length) {
      return {
        ...state,
        data: formattedArray,
      }
    }
    // It was successfully deleted
    else {
      return {
        ...state,
        data: formattedArray,
        meta: {
          ...state.meta,
          pagination: {
            ...state.meta.pagination,
            count: state.meta.pagination ? state.meta.pagination.count - 1 : 0,
            total: state.meta.pagination ? state.meta.pagination.total - 1 : 0,
            per_page: state.meta.pagination?.per_page || 50,
            current_page: state.meta.pagination?.current_page || 1,
            total_pages: state.meta.pagination?.total_pages || 1,
            links: state.meta.pagination?.links || [],
          },
        },
      }
    }
  }

  if (isType(action, AddFavorite)) {
    const newApplications = state.data.map(application => {
      if (application.group_application) {
        application.group_application.applications.data.map(
          groupApplication => {
            if (
              groupApplication.applicant.id ===
              action.payload.data.model_favored.id
            ) {
              const modifiedApplication = groupApplication
              modifiedApplication.applicant.favorite = true
              modifiedApplication.applicant.favorite_id = action.payload.data.id
              return modifiedApplication
            }
            return groupApplication
          },
        )
      } else if (
        application.applicant.id === action.payload.data.model_favored.id
      ) {
        const modifiedApplication = application
        modifiedApplication.applicant.favorite = true
        modifiedApplication.applicant.favorite_id = action.payload.data.id
        return modifiedApplication
      }
      return application
    })
    return {
      ...state,
      data: newApplications,
    }
  }

  if (isType(action, DeleteFavorite)) {
    const newApplications = state.data.map(application => {
      if (application.group_application) {
        application.group_application.applications.data.map(
          groupApplication => {
            if (groupApplication.applicant.id === action.payload) {
              const modifiedApplication = groupApplication
              modifiedApplication.applicant.favorite = false
              modifiedApplication.applicant.favorite_id = null
              return modifiedApplication
            }
            return groupApplication
          },
        )
      } else if (application.applicant.id === action.payload) {
        const modifiedApplication = application
        modifiedApplication.applicant.favorite = false
        modifiedApplication.applicant.favorite_id = null
        return modifiedApplication
      }
      return application
    })
    return {
      ...state,
      data: newApplications,
    }
  }

  if (isType(action, ApplicationsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, CleanApplications)) {
    return initialState
  }

  return state
}
