import { action, payload } from 'ts-action'
import { IReport } from 'models/Report'
import { IError, IPayload } from 'models/Global'

export const ReportsFetching = action('ReportsFetching', payload<boolean>())

export const GetReports = action('GetReports', payload<IPayload<IReport[]>>())

export const GetReportsErrors = action('GetReportsErrors', payload<IError>())

export const ResetReports = action('ResetReports')
