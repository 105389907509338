import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import { IPayload } from 'models/Global'
import {
  IShiftAutoAcceptCertificate,
  IGetShiftAutoAcceptCertificatesParams,
  IPutShiftAutoAcceptCertificateParams,
  IPostShiftAutoAcceptCertificateParams,
} from 'models/ShiftAutoAcceptCertificate'

export class ShiftAutoAcceptCertificatesApi {
  constructor(private axios: AxiosService) {}

  getShiftAutoAcceptCertificates = async (
    params?: IGetShiftAutoAcceptCertificatesParams,
  ) => {
    const response = await this.axios.get<
      IPayload<IShiftAutoAcceptCertificate[]>
    >(Endpoints.getShiftAutoAcceptCertificates, params)
    return response
  }

  getShiftAutoAcceptCertificate = async (
    id: number,
    params?: IGetShiftAutoAcceptCertificatesParams,
  ) => {
    const response = await this.axios.get<
      IPayload<IShiftAutoAcceptCertificate>
    >(Endpoints.getShiftAutoAcceptCertificate(id), params)
    return response
  }

  postShiftAutoAcceptCertificate = async (
    params: IPostShiftAutoAcceptCertificateParams,
  ) => {
    const response = await this.axios.post<
      IPayload<IShiftAutoAcceptCertificate>
    >(Endpoints.postShiftAutoAcceptCertificate, params)
    return response
  }

  putShiftAutoAcceptCertificate = async (
    id: number,
    params?: IPutShiftAutoAcceptCertificateParams,
  ) => {
    const response = await this.axios.put<
      IPayload<IShiftAutoAcceptCertificate>
    >(Endpoints.putShiftAutoAcceptCertificate(id), params)
    return response
  }

  deleteShiftAutoAcceptCertificate = async (id: number) => {
    const response = await this.axios.delete<{}>(
      Endpoints.deleteShiftAutoAcceptCertificate(id),
    )
    return response
  }
}
