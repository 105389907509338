import { Action } from 'redux'
import { isType } from 'ts-action'
import { deleteFromArray } from 'helpers/helperFuncs'
import {
  GetAssignedManagers,
  AssignedManagersFetching,
  AddAssignedManager,
  DeleteAssignedManager,
  GetAssignedManagersError,
} from './actions'
import { IUser } from 'models/User'
import { IBaseReducer } from 'models/BaseReducer'

interface IAssignedManagersReducer extends IBaseReducer<IUser[]> {}

const initialState: IAssignedManagersReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const assignedManagersReducer = (
  state = initialState,
  action: Action,
): IAssignedManagersReducer => {
  if (isType(action, GetAssignedManagers)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
      error: undefined,
    }
  }
  if (isType(action, AddAssignedManager)) {
    const { payload } = action
    return {
      ...state,
      data: [payload, ...state.data],
    }
  }
  if (isType(action, DeleteAssignedManager)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IUser>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, GetAssignedManagersError)) {
    const { payload } = action
    return {
      ...state,
      error: payload,
    }
  }
  if (isType(action, AssignedManagersFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
