import { action, payload } from 'ts-action'
import { IUser } from 'models/User'
import { IError, IPayload } from 'models/Global'

export const UpshifterFetching = action('UpshifterFetching', payload<boolean>())

export const GetUpshifter = action('GetUpshifter', payload<IPayload<IUser>>())

export const AddUpshifter = action('AddUpshifter', payload<IPayload<IUser>>())

export const UpdateUpshifter = action(
  'UpdateUpshifter',
  payload<IPayload<IUser>>(),
)

export const DeleteUpshifter = action('DeleteUpshifter', payload<number>())

export const GetUpshifterErrors = action(
  'GetUpshifterErrors',
  payload<IError>(),
)

export const ClearUpshifter = action('ClearUpshifter')
