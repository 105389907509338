import { IUser } from 'models/User'
import { TypeOrNull } from 'models/Common'
import { IAuthData, IAuthPayload, USER_ROLE } from 'models/Auth'
import { IBusinessEntity } from 'models/BusinessEntity'
import { IIndustry } from 'models/Industry'
import { IIncompleteWorkflows } from 'models/OnboardingWorkflow'
import { IBusinessPaymentInfo } from 'layouts/Business/utils'

export default class AuthService {
  /**
   * Gets the token from the localStorage
   */
  static getBearerToken = (): TypeOrNull<string> => {
    const token = localStorage.getItem('token')
    return token
  }

  /**
   * Set the token in localStorage
   */
  static setBearerToken = (payload: Omit<IAuthPayload, 'data'>) => {
    const { token, expires_at } = payload.meta.auth
    localStorage.setItem('token', token)
    localStorage.setItem('token:expires_at', expires_at)
  }

  /**
   * Gets the loggedInUser from the localStorage
   */
  static getLoggedInUser = (): TypeOrNull<IUser> => {
    const loggedInUser = localStorage.getItem('upshiftLoggedInUser')
    if (loggedInUser) {
      return JSON.parse(loggedInUser) as IUser
    }
    return null
  }
  /**
   * Gets the latestVaccinationInfo from the localStorage
   */
  static getLatestVaccinationInfo = (): TypeOrNull<IUser> => {
    const latestVaccinationInfo = localStorage.getItem('latestVaccinationInfo')
    if (latestVaccinationInfo) {
      return JSON.parse(latestVaccinationInfo)
    }
    return null
  }

  /**
   *
   * @param timestamp The user that should be stored in localStorage
   * Sets the user passed as argument in localStorage
   */
  static setLatestVaccinationInfo = (timestamp: Date): void => {
    localStorage.setItem('latestVaccinationInfo', JSON.stringify(timestamp))
  }

  /**
   * Gets the businessEntity from the localStorage
   */
  static getBusinessEntity = (): TypeOrNull<IBusinessEntity> => {
    const businessEntity = localStorage.getItem('businessEntity')
    if (businessEntity) {
      return JSON.parse(businessEntity) as IBusinessEntity
    }
    return null
  }

  /**
   * Gets the payment method from the localStorage
   */
  static getUserPaymentMethodFetched = (): TypeOrNull<IBusinessPaymentInfo> => {
    const userPaymentMethodFetched = localStorage.getItem(
      'userPaymentMethodFetched',
    )
    if (userPaymentMethodFetched) {
      return JSON.parse(userPaymentMethodFetched)
    }
    return null
  }

  /**
   * Gets the industry from the localStorage
   */
  static getIndustryDetails = (): TypeOrNull<IIndustry> => {
    const businessEntity = localStorage.getItem('industryDetails')
    if (businessEntity) {
      return JSON.parse(businessEntity) as IIndustry
    }
    return null
  }

  /**
   * Gets the user roles from the loggedInUser object in localStorage
   */
  static getLoggedInUserRole = (): TypeOrNull<USER_ROLE> => {
    const loggedInUser = AuthService.getLoggedInUser()
    return loggedInUser ? loggedInUser.role : null
  }

  /**
   * Gets the user incomplete workflows from the upshiftLoggedInUser object in localStorage
   */
  static getLoggedInUserIncompleteWorkflows = ():
    | boolean
    | IIncompleteWorkflows => {
    const loggedInUser = AuthService.getLoggedInUser()
    return loggedInUser ? loggedInUser.incomplete_workflows : false
  }

  /**
   *
   * @param loggedInUser The user that should be stored in localStorage
   * Sets the user passed as argument in localStorage
   */
  static setLoggedInUser = (loggedInUser: IUser | Partial<IAuthData>): void => {
    localStorage.setItem('upshiftLoggedInUser', JSON.stringify(loggedInUser))
  }

  /**
   *
   * @param businessEntity The user that should be stored in localStorage
   * Sets the user passed as argument in localStorage
   */
  static setBusinessEntity = (businessEntity: IBusinessEntity): void => {
    localStorage.setItem('businessEntity', JSON.stringify(businessEntity))
    localStorage.setItem('businessEntityLastUpdate', Date.now().toString())
  }

  /**
   *
   * @param industryDetails The industry that should be stored in localStorage
   * Sets the industry passed as argument in localStorage
   */
  static setIndustry = (industryDetails: IIndustry): void => {
    localStorage.setItem('industryDetails', JSON.stringify(industryDetails))
  }

  /**
   *
   * Get user traits from localStorage (This field is used by Segment)
   */
  static getUserTraits = (): TypeOrNull<string> => {
    const userTraits = localStorage.getItem('ajs_user_traits')
    return userTraits ?? null
  }

  /**
   * TODO: We need to change this and place into separate service for clearing clear()
   * Clear state from localStorage
   */
  static clearStorage = () => {
    localStorage.clear()
  }
}
