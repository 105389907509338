import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import { IPayload } from 'models/Global'
import { IMagicLink, IPostMagicLinkParams } from 'models/MagicLink'

export class MagicLinkApi {
  constructor(private axios: AxiosService) {}

  postMagicLink = async (params: IPostMagicLinkParams) => {
    const response = await this.axios.post<IPayload<IMagicLink>>(
      Endpoints.postMagicLink,
      params,
    )
    return response
  }
}
