import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import { IPayload } from 'models/Global'
import {
  IGigType,
  IGetGigTypesParams,
  IPutGigTypeParams,
  IPostGigTypeParams,
} from 'models/GigType'

export class GigTypesApi {
  constructor(private axios: AxiosService) {}

  getGigTypes = async (params?: IGetGigTypesParams) => {
    const response = await this.axios.get<IPayload<IGigType[]>>(
      Endpoints.getGigTypes,
      params,
    )
    return response
  }

  getGigType = async (id: number, params?: IGetGigTypesParams) => {
    const response = await this.axios.get<IPayload<IGigType>>(
      Endpoints.getGigType(id),
      params,
    )
    return response
  }

  postGigType = async (params: IPostGigTypeParams) => {
    const response = await this.axios.post<IPayload<IGigType>>(
      Endpoints.postGigType,
      params,
    )
    return response
  }

  putGigType = async (id: number, params?: IPutGigTypeParams) => {
    const response = await this.axios.put<IPayload<IGigType>>(
      Endpoints.putGigType(id),
      params,
    )
    return response
  }

  deleteGigType = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteGigType(id))
    return response
  }
}
