import {
  IShiftIdentifierPolicy,
  IShiftIdentifierPolicyReducer,
} from 'models/ShiftIdentifierPolicy'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  ClearShiftIdentifierPolicy,
  DeleteShiftIdentifierPolicy,
  GetShiftIdentifierPolicy,
  GetShiftIdentifierPolicyErrors,
  ShiftIdentifierPolicyFetching,
} from './actions'

const initialState: IShiftIdentifierPolicyReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: {} as IShiftIdentifierPolicy,
}

export const shiftIdentifierPolicyReducer = (
  state = initialState,
  action: Action,
): IShiftIdentifierPolicyReducer => {
  if (isType(action, GetShiftIdentifierPolicy)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, GetShiftIdentifierPolicyErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }

  if (isType(action, DeleteShiftIdentifierPolicy)) {
    return {
      meta: {
        code: 0,
        message: '',
      },
      isFetching: false,
      data: {} as IShiftIdentifierPolicy,
    }
  }
  if (isType(action, ClearShiftIdentifierPolicy)) {
    return {
      meta: {
        code: 0,
        message: '',
      },
      isFetching: false,
      data: {} as IShiftIdentifierPolicy,
    }
  }

  if (isType(action, ShiftIdentifierPolicyFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  return state
}
