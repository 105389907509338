import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from '../../models/Global'
import {
  ICalendar,
  IGetCalendarsParams,
  IPutCalendarParams,
  IPostCalendarParams,
} from '../../models/Calendar'

export class CalendarsApi {
  constructor(private axios: AxiosService) {}

  getCalendars = async (params?: IGetCalendarsParams) => {
    const response = await this.axios.get<IPayload<ICalendar[]>>(
      Endpoints.getCalendars,
      params,
    )
    return response
  }

  getCalendar = async (id: number, params?: IGetCalendarsParams) => {
    const response = await this.axios.get<IPayload<ICalendar>>(
      Endpoints.getCalendar(id),
      params,
    )
    return response
  }

  postCalendar = async (params: IPostCalendarParams) => {
    const response = await this.axios.post<IPayload<ICalendar>>(
      Endpoints.postCalendar,
      params,
    )
    return response
  }

  putCalendar = async (id: number, params?: IPutCalendarParams) => {
    const response = await this.axios.put<IPayload<ICalendar>>(
      Endpoints.putCalendar(id),
      params,
    )
    return response
  }

  deleteCalendar = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteCalendar(id))
    return response
  }
}
