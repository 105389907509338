import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import { IPayload } from 'models/Global'
import {
  IActivityLog,
  IGetActivityLogParams,
  IGetActivityLogsParams,
} from 'models/ActivityLog'

export class ActivityLogsApi {
  constructor(private axios: AxiosService) {}

  getActivityLogs = async <T>(params?: IGetActivityLogsParams) => {
    const response = await this.axios.get<IPayload<IActivityLog<T>[]>>(
      Endpoints.getActivityLogs,
      params,
    )
    return response
  }

  getActivityLog = async <T>(id: number, params?: IGetActivityLogParams) => {
    const response = await this.axios.get<IPayload<IActivityLog<T>>>(
      Endpoints.getActivityLog(id),
      params,
    )
    return response
  }
}
