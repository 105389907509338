import { ILoadingAction } from 'models/Loading'
import { action, payload } from 'ts-action'

export const SetAcceptLoading = action(
  'SetAcceptLoading',
  payload<ILoadingAction>(),
)

export const SetDeclineLoading = action(
  'SetDeclineLoading',
  payload<ILoadingAction>(),
)

export const SetFavoriteLoading = action(
  'SetFavoriteLoading',
  payload<ILoadingAction>(),
)

export const SetReviewLoading = action(
  'SetReviewLoading',
  payload<ILoadingAction>(),
)

export const SetNotificationPreferenceLoading = action(
  'SetNotificationPreferenceLoading',
  payload<ILoadingAction>(),
)

export const SetManagerActiveStatusLoading = action(
  'SetManagerActiveStatusLoading',
  payload<ILoadingAction>(),
)

export const SetAssignedManagerLoading = action(
  'SetAssignedManagerLoading',
  payload<ILoadingAction>(),
)

export const SetUnassignedManagerLoading = action(
  'SetUnassignedManagerLoading',
  payload<ILoadingAction>(),
)

export const SetPayInvoiceLoading = action(
  'SetPayInvoiceLoading',
  payload<ILoadingAction>(),
)
