import { action, payload } from 'ts-action'
import { IProperty } from 'models/Property'
import { IError, IPayload } from 'models/Global'

export const PropertiesFetching = action(
  'PropertiesFetching',
  payload<boolean>(),
)

export const GetProperties = action(
  'GetProperties',
  payload<IPayload<IProperty[]>>(),
)

export const AddProperty = action('AddProperty', payload<IPayload<IProperty>>())

export const UpdateProperty = action(
  'UpdateProperty',
  payload<IPayload<IProperty>>(),
)

export const DeleteProperty = action('DeleteProperty', payload<number>())

export const GetPropertiesErrors = action(
  'GetPropertiesErrors',
  payload<IError>(),
)

export const ResetProperties = action('ResetProperties')
