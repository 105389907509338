import { action, payload } from 'ts-action'
import { IError, IPayload } from 'models/Global'
import { IUser } from 'models/User'

export const UnassignedManagersFetching = action(
  'UnassignedManagersFetching',
  payload<boolean>(),
)
export const GetUnassignedManagers = action(
  'GetUnassignedManagers',
  payload<IPayload<IUser[]>>(),
)
export const GetUnassignedManagersError = action(
  'GetUnassignedManagersError',
  payload<IError>(),
)

export const DeleteUnassignedManager = action(
  'DeleteUnassignedManager',
  payload<number>(),
)

export const AddUnassignedManager = action(
  'AddUnassignedManager',
  payload<IUser>(),
)
