export const ENV_NAME = import.meta.env.VITE_ENV_NAME
export const API_HOST = import.meta.env.VITE_API_HOST
export const API_VERSION = import.meta.env.VITE_API_VERSION
export const SLACK_CHANNEL = import.meta.env.VITE_SLACK_CHANNEL
export const INTERCOM_URL = import.meta.env.VITE_INTERCOM_URL
export const ADMIN_PORTAL_LINK = import.meta.env.VITE_ADMIN_PORTAL_LINK
export const BUSINESS_PORTAL_LINK = import.meta.env.VITE_BUSINESS_PORTAL_LINK
export const UPSHIFTER_PORTAL_LINK = import.meta.env.VITE_UPSHIFTER_PORTAL_LINK
export const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID
export const TEMPLATES_ALLOWED = import.meta.env.VITE_TEMPLATES_ALLOWED
export const DELETED_BUSINESS_IDS_S3_URL = import.meta.env
  .VITE_DELETED_BUSINESS_IDS_S3_URL
export const PUSHER_KEY = import.meta.env.VITE_PUSHER_KEY
export const PUSHER_AUTHORIZATION_ENDPOINT = import.meta.env
  .VITE_PUSHER_AUTHORIZATION_ENDPOINT
export const PUSHER_CLUSTER = import.meta.env.VITE_PUSHER_CLUSTER
export const DATADOG_APP_KEY = import.meta.env.VITE_DATADOG_APP_KEY
export const DATADOG_CLIENT_TOKEN = import.meta.env.VITE_DATADOG_CLIENT_TOKEN
export const STRIPE_PUBLISHABLE_KEY = import.meta.env
  .VITE_STRIPE_PK_KEY as string
export const NODE_ENV = import.meta.env.NODE_ENV
export const VERSION_COMMIT_HASH = import.meta.env.VITE_VERSION_COMMIT_HASH
export const INTERCOM_SURVEY_ID = import.meta.env.VITE_INTERCOM_SURVEY_ID
export const SLACK_WEB_HOOK_URL = import.meta.env.VITE_SLACK_WEB_HOOK_URL
export const SLACK_LONG_REQUESTS_CHANNEL_WEB_HOOK_URL = import.meta.env
  .VITE_SLACK_LONG_REQUESTS_CHANNEL_WEB_HOOK_URL
export const REQUEST_HEADER_APPLICATION_NAME_BUSINESS = import.meta.env
  .VITE_REQUEST_HEADER_APPLICATION_NAME_BUSINESS
export const REQUEST_HEADER_APPLICATION_NAME_LABOR = import.meta.env
  .VITE_REQUEST_HEADER_APPLICATION_NAME_LABOR
