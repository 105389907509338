import { action, payload } from 'ts-action'
import { IShift, IShiftApplicants } from 'models/Shift'
import { IError, IPayload } from 'models/Global'

interface IUpdateShiftsApplicants {
  applicants: {
    pending_count: number
    accepted_count: number
    confirmed_count: number
  }
  shiftId: number
}

interface IShiftsDecrementConfirmedIncrementPending {
  shiftId: number
  confirmed: number
}

interface IShiftsUpdateApplicants {
  applicants: IShiftApplicants
  shiftId: number
}

interface IShiftsIncrementAccepted {
  increment_by: number
  shiftId: number
}

export const ShiftsFetching = action('ShiftsFetching', payload<boolean>())

export const GetShifts = action('GetShifts', payload<IPayload<IShift[]>>())

export const GetShiftsErrors = action('GetShiftsErrors', payload<IError>())

export const ClearShiftsReducer = action('ClearShiftsReducer')

export const ShiftsSetHasMore = action('ShiftsSetHasMore', payload<boolean>())

export const ShiftsUpdateShiftApplicants = action(
  'ShiftsUpdateShiftApplicants',
  payload<IUpdateShiftsApplicants>(),
)

export const ShiftsDecrementConfirmedIncrementPending = action(
  'ShiftsDecrementConfirmedIncrementPending',
  payload<IShiftsDecrementConfirmedIncrementPending>(),
)

export const ShiftsDecrementPendingIncrementConfirmed = action(
  'ShiftsDecrementPendingIncrementConfirmed',
  payload<number>(),
)

export const ShiftsIncrementAccepted = action(
  'ShiftsIncrementAccepted',
  payload<IShiftsIncrementAccepted>(),
)

export const ShiftsUpdateApplicants = action(
  'ShiftsUpdateApplicants',
  payload<IShiftsUpdateApplicants>(),
)

export const ShiftsUpdateShift = action('ShiftsUpdateShift', payload<IShift>())

export const ShiftsRemoveShift = action('ShiftsRemoveShift', payload<number>())
