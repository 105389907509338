import { IBaseReducer } from '../BaseReducer'
import { IUser } from 'models/User'
import { IIncompleteWorkflows } from 'models/OnboardingWorkflow'

interface IRegionData {
  id: number
  name: string
  code: string
  latitude: string
  longitude: string
  status: number
}

interface IStatData {
  notifications_count: number
  active_punch_cards_count: number
  active_strikes_count: number
  expired_strikes_count: number
  pending_gigs_count: number
  accepted_gigs_count: number
  confirmed_gigs_count: number
  active_gigs_count: number
  finished_gigs_count: number
  finished_punch_cards_count: number
  hours_worked: number
  total_hours_worked: number
}

export interface IRoleData {
  id: number
  name: USER_ROLE
}

export interface IAuthData {
  id: number
  uuid: string
  email: string
  full_name: string
  first_name: string
  last_name: string
  zip_code: string
  phone: string
  description: string
  timezone: string
  avatar_url: string
  avatar_thumb_url: string
  date_of_birth: string | boolean
  age: number
  rating: string
  confirmed: boolean
  status: number
  ec_full_name: string
  ec_phone: number
  ec_relationship: string
  created_at: Date
  password_last_changed_at: Date
  changed_password: boolean
  prompt_password_change_after_login: boolean
  new_app: boolean
  roles: IRoleData[]
  regions: IRegionData[]
  can_login: boolean
  ssn_last_four: boolean
  stats: IStatData[]
  should_update_location_data: boolean
  phone_type: string
  business_id: number
  role?: string
  incomplete_workflows: IIncompleteWorkflows | boolean
}

export interface IAuthReducer extends IBaseReducer<IAuth> {}

export interface IAuth {
  user: IAuthData | IUser
  role: USER_ROLE | string
  badges: any
  isLoggedIn: boolean
  imageLoader: boolean
  info: { message: string; type: string }
  latitude: number
  longitude: number
  locationAllowed: boolean
  gettingLocation: boolean
}

export interface IAuthLogin {
  email: string
  password: string
  timezone?: string
}

export interface IMagicLogin {
  token: string
  timezone?: string
  ssn_last_four?: string
}

export interface IAuthPayload {
  data: IAuthData
  meta: {
    auth: {
      token: string
      expires_at: string
    }
  }
}

export interface IMagicToken {
  expires_at: string
  role: USER_ROLE
  ssn_last_four: string
}

export enum USER_ROLE {
  UPSHIFTER = 'upshifter',
  LABOR = 'labor',
  BUSINESS = 'business',
  EMPLOYEE = 'employee',
  APPROVER = 'approver',
  VIEWER = 'viewer',
  FINANCE_MANAGER = 'finance_manager',
}

export enum ADMIN_ROLE {
  ADMIN = 'admin',
  ONBOARDER = 'onboarder',
  SALES = 'sales',
  ACCOUNT_MANAGER_LEVEL_1 = 'account_manager_level_one',
  ACCOUNT_MANAGER_LEVEL_2 = 'account_manager_level_two',
}

export enum USER_ROLE_IN_APP {
  UPSHIFTER = 'UPSHIFTER',
  BUSINESS_ADMIN = 'BUSINESS_ADMIN',
  BUSINESS_MANAGER = 'BUSINESS_MANAGER',
  BUSINESS_SITE_MANAGER = 'BUSINESS_SITE_MANAGER',
  BUSINESS_VIEWER = 'BUSINESS_VIEWER',
  BUSINESS_APPROVER = 'BUSINESS_APPROVER',
  BUSINESS_FINANCE_MANAGER = 'BUSINESS_FINANCE_MANAGER',
}

export const UPSHIFTER_ROLES: USER_ROLE_IN_APP[] = [USER_ROLE_IN_APP.UPSHIFTER]

export const BUSINESS_ROLES: USER_ROLE_IN_APP[] = [
  USER_ROLE_IN_APP.BUSINESS_ADMIN,
  USER_ROLE_IN_APP.BUSINESS_MANAGER,
  USER_ROLE_IN_APP.BUSINESS_SITE_MANAGER,
  USER_ROLE_IN_APP.BUSINESS_VIEWER,
  USER_ROLE_IN_APP.BUSINESS_APPROVER,
  USER_ROLE_IN_APP.BUSINESS_FINANCE_MANAGER,
]
