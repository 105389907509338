import { IShift } from 'models/Shift'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFuncs'
import {
  GetShifts,
  ShiftsFetching,
  ShiftsSetHasMore,
  ClearShiftsReducer,
  ShiftsUpdateShiftApplicants,
  ShiftsDecrementConfirmedIncrementPending,
  ShiftsDecrementPendingIncrementConfirmed,
  ShiftsUpdateApplicants,
  ShiftsUpdateShift,
  ShiftsRemoveShift,
  ShiftsIncrementAccepted,
} from './actions'
import { IBaseReducer } from 'models/BaseReducer'

interface IShiftsReducer extends IBaseReducer<IShift[]> {
  hasMore: boolean
}

const initialState: IShiftsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
  hasMore: false,
}

export const businessShiftsReducer = (
  state = initialState,
  action: Action,
): IShiftsReducer => {
  if (isType(action, GetShifts)) {
    const { data, meta } = action.payload
    const currentShiftIds = state.data.map(shift => shift.id)
    const filteredShifts = data.filter(
      element => !currentShiftIds.includes(element.id),
    )
    const hasMore = meta.pagination
      ? meta.pagination.total_pages > meta.pagination.current_page
      : false
    return {
      ...state,
      meta: meta,
      data: [...state.data, ...filteredShifts],
      hasMore,
      error: undefined,
    }
  }
  if (isType(action, ShiftsUpdateShift)) {
    const { payload } = action
    const formattedArray = updateArray<IShift>(state.data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, ShiftsRemoveShift)) {
    const { payload: id } = action
    const formattedArray = deleteFromArray<IShift>(state.data, id)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, ShiftsUpdateShiftApplicants)) {
    const { applicants, shiftId } = action.payload
    const updatedShifts = state.data.map(shift => {
      if (shift.id === shiftId) {
        const newShift = { ...shift }
        newShift.applicants.accepted_count = applicants.accepted_count
        newShift.applicants.confirmed_count = applicants.confirmed_count
        newShift.applicants.pending_count = applicants.pending_count
        return newShift
      }
      return shift
    })
    return { ...state, data: updatedShifts }
  }
  if (isType(action, ShiftsDecrementConfirmedIncrementPending)) {
    const { shiftId, confirmed } = action.payload
    const newShifts = state.data.map(shift => {
      if (shift.id === shiftId) {
        const newShift = { ...shift }
        newShift.applicants.pending_count += 1
        newShift.applicants.accepted_count -= 1
        if (confirmed) {
          newShift.applicants.confirmed_count -= 1
        }
        return newShift
      }
      return shift
    })
    return { ...state, data: newShifts }
  }
  if (isType(action, ShiftsDecrementPendingIncrementConfirmed)) {
    const { payload: shiftId } = action
    const newShifts = state.data.map(shift => {
      if (shift.id === shiftId) {
        const newShift = { ...shift }
        newShift.applicants.pending_count -= 1
        newShift.applicants.accepted_count += 1
        return newShift
      }
      return shift
    })
    return { ...state, data: newShifts }
  }
  if (isType(action, ShiftsIncrementAccepted)) {
    const { shiftId, increment_by } = action.payload
    const newShifts = state.data.map(shift => {
      if (shift.id === shiftId) {
        const newShift = { ...shift }
        newShift.applicants.accepted_count += increment_by
        return newShift
      }
      return shift
    })
    return { ...state, data: newShifts }
  }
  if (isType(action, ShiftsUpdateApplicants)) {
    const { shiftId, applicants } = action.payload
    const newShifts = state.data.map(shift => {
      if (shift.id === shiftId) {
        const newShift = { ...shift, applicants }
        return newShift
      }
      return shift
    })
    return { ...state, data: newShifts }
  }
  if (isType(action, ShiftsSetHasMore)) {
    const { payload } = action
    return {
      ...state,
      hasMore: payload,
    }
  }
  if (isType(action, ShiftsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, ClearShiftsReducer)) {
    return initialState
  }
  return state
}
