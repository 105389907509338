import { Action } from 'redux'
import { isType } from 'ts-action'
import { deleteFromArray } from 'helpers/helperFuncs'
import {
  GetUnassignedManagers,
  UnassignedManagersFetching,
  AddUnassignedManager,
  DeleteUnassignedManager,
  GetUnassignedManagersError,
} from './actions'
import { IUser } from 'models/User'
import { IBaseReducer } from 'models/BaseReducer'

interface IUnassignedManagersReducer extends IBaseReducer<IUser[]> {}

const initialState: IUnassignedManagersReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const unassignedManagersReducer = (
  state = initialState,
  action: Action,
): IUnassignedManagersReducer => {
  if (isType(action, GetUnassignedManagers)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
      error: undefined,
    }
  }
  if (isType(action, AddUnassignedManager)) {
    const { payload } = action
    return {
      ...state,
      data: [payload, ...state.data],
    }
  }
  if (isType(action, DeleteUnassignedManager)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IUser>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, GetUnassignedManagersError)) {
    const { payload } = action
    return {
      ...state,
      error: payload,
    }
  }
  if (isType(action, UnassignedManagersFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
