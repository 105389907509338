import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import { IPayload } from 'models/Global'
import {
  IInvoiceGroup,
  IGetInvoiceGroupsParams,
  IPostInvoiceGroupParams,
  IPutInvoiceGroupParams,
} from 'models/InvoiceGroup'

export class InvoiceGroupsApi {
  constructor(private axios: AxiosService) {}

  getInvoiceGroups = async (params?: IGetInvoiceGroupsParams) => {
    const response = await this.axios.get<IPayload<IInvoiceGroup[]>>(
      Endpoints.getInvoiceGroups,
      params,
    )
    return response
  }

  getInvoiceGroup = async (id: number) => {
    const response = await this.axios.get<IPayload<IInvoiceGroup>>(
      Endpoints.getInvoiceGroup(id),
    )
    return response
  }

  postInvoiceGroup = async (params: IPostInvoiceGroupParams) => {
    const response = await this.axios.post<IPayload<IInvoiceGroup>>(
      Endpoints.postInvoiceGroup,
      params,
    )
    return response
  }

  putInvoiceGroup = async (id: number, params: IPutInvoiceGroupParams) => {
    const response = await this.axios.put<IPayload<IInvoiceGroup>>(
      Endpoints.putInvoiceGroup(id),
      params,
    )
    return response
  }

  deleteInvoiceGroup = async (id: number) => {
    const response = await this.axios.delete<{}>(
      Endpoints.deleteInvoiceGroup(id),
    )
    return response
  }
}
