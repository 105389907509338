import { action, payload } from 'ts-action'
import { IMyUpshiftersUser } from 'models/User'
import { IError, IPayload } from 'models/Global'

export const UpshiftersFetching = action(
  'UpshiftersFetching',
  payload<boolean>(),
)

export const GetUpshifters = action(
  'GetUpshifters',
  payload<IPayload<IMyUpshiftersUser[]>>(),
)

export const GetBlockedUpshifters = action(
  'GetUpshifters',
  payload<IPayload<IMyUpshiftersUser[]>>(),
)

export const GetBlockPropertiesUpshifters = action(
  'GetUpshifters',
  payload<IPayload<IMyUpshiftersUser[]>>(),
)

export const AddUpshifter = action(
  'AddUpshifter',
  payload<IPayload<IMyUpshiftersUser>>(),
)

export const UpshiftersUpdateUpshifter = action(
  'UpshiftersUpdateUpshifter',
  payload<IPayload<IMyUpshiftersUser>>(),
)

export const UpshiftersDeleteUpshifter = action(
  'UpshiftersDeleteUpshifter',
  payload<number>(),
)

export const GetUpshiftersErrors = action(
  'GetUpshiftersErrors',
  payload<IError>(),
)
