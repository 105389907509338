import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import { IPayload } from 'models/Global'
import {
  INetOption,
  IGetNetOptionsParams,
  IPostPutNetOptionParams,
} from 'models/NetOption'

export class NetOptionsApi {
  constructor(private axios: AxiosService) {}

  getNetOptions = async (params?: IGetNetOptionsParams) => {
    const response = await this.axios.get<IPayload<INetOption[]>>(
      Endpoints.getNetOptions,
      params,
    )
    return response
  }

  getNetOption = async (id: number) => {
    const response = await this.axios.get<IPayload<INetOption>>(
      Endpoints.getNetOption(id),
    )
    return response
  }

  postNetOption = async (params: IPostPutNetOptionParams) => {
    const response = await this.axios.post<IPayload<INetOption>>(
      Endpoints.postNetOption,
      params,
    )
    return response
  }

  putNetOption = async (id: number, params: IPostPutNetOptionParams) => {
    const response = await this.axios.put<IPayload<INetOption>>(
      Endpoints.putNetOption(id),
      params,
    )
    return response
  }

  deleteNetOption = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteNetOption(id))
    return response
  }
}
