import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from '../../models/Global'
import {
  IShiftIdentifierPolicy,
  IGetShiftIdentifierPoliciesParams,
  IPostShiftIdentifierPolicyParams,
  IPutShiftIdentifierPolicyParams,
} from '../../models/ShiftIdentifierPolicy'

export class ShiftIdentifierPolicyApi {
  constructor(private axios: AxiosService) {}

  getShiftIdentifierPolicies = async (
    params?: IGetShiftIdentifierPoliciesParams,
  ) => {
    const response = await this.axios.get<IPayload<IShiftIdentifierPolicy[]>>(
      Endpoints.getShiftIdentifierPolicies,
      params,
    )
    return response
  }

  getShiftIdentifierPolicy = async (id: number) => {
    const response = await this.axios.get<IPayload<IShiftIdentifierPolicy>>(
      Endpoints.getShiftIdentifierPolicy(id),
    )
    return response
  }

  postShiftIdentifierPolicy = async (
    params: IPostShiftIdentifierPolicyParams,
  ) => {
    const response = await this.axios.post<IPayload<IShiftIdentifierPolicy>>(
      Endpoints.postShiftIdentifierPolicy,
      params,
    )
    return response
  }

  putShiftIdentifierPolicy = async (
    id: number,
    params: IPutShiftIdentifierPolicyParams,
  ) => {
    const response = await this.axios.put<IPayload<IShiftIdentifierPolicy>>(
      Endpoints.putShiftIdentifierPolicy(id),
      params,
    )
    return response
  }

  deleteShiftIdentifierPolicy = async (id: number) => {
    const response = await this.axios.delete<{}>(
      Endpoints.deleteShiftIdentifierPolicy(id),
    )
    return response
  }
}
