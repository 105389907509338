import { IInvoicesReducer } from 'models/Invoice'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  GetInvoices,
  GetOverdueInvoices,
  InvoicesFetching,
  GetInvoicesErrors,
} from './actions'

const initialState: IInvoicesReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
  overdueInvoices: 0,
}

export const invoicesReducer = (
  state = initialState,
  action: Action,
): IInvoicesReducer => {
  if (isType(action, GetInvoices)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, GetOverdueInvoices)) {
    const { payload } = action
    return {
      ...state,
      overdueInvoices: payload,
    }
  }

  if (isType(action, GetInvoicesErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }

  if (isType(action, InvoicesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  return state
}
