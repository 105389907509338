import {
  IScheduledDate,
  IScheduledGigType,
  IScheduledGigTypeApplicant,
  IScheduledUpshifter,
} from 'models/ScheduledUpshifter'

/**
 * Removes the target applicant from the scheduledUpshifters array
 * Also takes care of cleaning the array in case of empty subarrays
 * @param {Array} data Array of scheduled upshifters
 * @param {Number} propertyId Id of the property needed to narrow down the applicant
 * @param {Number} gigTypeId Id of the gigtype within the target property
 * @param {Number} upshifterId Id of the applicant that should be removed
 * @param {Number} gigId Id of the shift whose day(s) we are removing the applicant from
 */
export const filterUpshifters = (
  data: IScheduledUpshifter[],
  propertyId: number,
  gigTypeId: number,
  upshifterId: number,
  gigId: number,
) => {
  const internalCleanProperties = (properties: IScheduledUpshifter[]) =>
    properties.filter(property => property.dates.length > 0)

  const internalCleanDates = (dates: IScheduledDate[]) =>
    dates.filter(date => date.gig_types.length > 0)

  const internalCleanGigTypes = (gigTypes: IScheduledGigType[]) =>
    gigTypes.filter(gigType => gigType.applicants.length > 0)

  const internalFilterApplicants = (applicants: IScheduledGigTypeApplicant[]) =>
    applicants.filter(
      applicant =>
        !(
          Number(applicant.user_id) === upshifterId &&
          gigId === Number(applicant.gig_id)
        ),
    )

  const internalMapGigTypes = (gigTypes: IScheduledGigType[]) =>
    internalCleanGigTypes(
      gigTypes.map(gType => ({
        ...gType,
        ...(gigTypeId === Number(gType.gig_type_id) && {
          applicants: internalFilterApplicants(gType.applicants),
        }),
      })),
    )

  const internalMapDates = (dates: IScheduledDate[]) =>
    internalCleanDates(
      dates.map(date => ({
        ...date,
        gig_types: internalMapGigTypes(date.gig_types),
      })),
    )

  const internalMapProperties = (properties: IScheduledUpshifter[]) =>
    internalCleanProperties(
      properties.map(property => ({
        ...property,
        ...(Number(property.property_id) === propertyId && {
          dates: internalMapDates(property.dates),
        }),
      })),
    )

  const filteredData = internalMapProperties(data)

  return filteredData
}

/**
 * Removes the review action for the specific upshifter
 * @param {Array} data Array of scheduled upshifters
 * @param {Number} propertyId Id of the property needed to narrow down the applicant
 * @param {Number} gigTypeId Id of the gigtype within the target property
 * @param {Number} upshifterId Id of the applicant whose Review action object should be removed
 * @param {Number} gigId Id of the shift whose day(s) we are removing the applicant's Review action obj from
 */
export const removeReviewActionForUpshifter = (
  data: IScheduledUpshifter[],
  propertyId: number,
  gigTypeId: number,
  upshifterId: number,
  gigId: number,
) => {
  const internalFilterActions = (
    actions: IScheduledGigTypeApplicant['actions'],
  ) => actions && actions.filter(action => action && action.name !== 'review')

  const internalMapApplicants = (applicants: IScheduledGigTypeApplicant[]) =>
    applicants.map(applicant =>
      !(
        Number(applicant.user_id) === upshifterId &&
        gigId === Number(applicant.gig_id)
      )
        ? applicant
        : {
            ...applicant,
            actions: internalFilterActions(applicant.actions),
          },
    )

  const internalMapGigTypes = (gigTypes: IScheduledGigType[]) =>
    gigTypes.map(gType => ({
      ...gType,
      ...(gigTypeId === Number(gType.gig_type_id) && {
        applicants: internalMapApplicants(gType.applicants),
      }),
    }))

  const internalMapDates = (dates: IScheduledDate[]) =>
    dates.map(date => ({
      ...date,
      gig_types: internalMapGigTypes(date.gig_types),
    }))

  const internalMapProperties = (properties: IScheduledUpshifter[]) =>
    properties.map(property => ({
      ...property,
      ...(Number(property.property_id) === propertyId && {
        dates: internalMapDates(property.dates),
      }),
    }))

  const filteredData = internalMapProperties(data)

  return filteredData
}
