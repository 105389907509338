import { Dispatch } from 'redux'
import {
  GetShifts,
  GetShiftsErrors,
  ShiftsFetching,
  ClearShiftsReducer,
  ShiftsSetHasMore,
  ShiftsUpdateShiftApplicants,
} from './actions'
import { IGetShiftsParams } from 'models/Shift'

import { API } from 'network'

export type ShiftsActions =
  | ReturnType<typeof GetShifts>
  | ReturnType<typeof GetShiftsErrors>
  | ReturnType<typeof ShiftsFetching>
  | ReturnType<typeof ClearShiftsReducer>
  | ReturnType<typeof ShiftsSetHasMore>
  | ReturnType<typeof ShiftsUpdateShiftApplicants>

export const getShifts = (params: IGetShiftsParams) => async (
  dispatch: Dispatch<ShiftsActions>,
) => {
  if (!params.page) {
    dispatch(ClearShiftsReducer())
    dispatch(ShiftsSetHasMore(true))
  }
  dispatch(ShiftsFetching(true))
  try {
    const response = await API.Shifts.getShifts(params)
    dispatch(GetShifts(response))
    return response
  } catch (error) {
    dispatch(GetShiftsErrors(error))
    return error
  } finally {
    dispatch(ShiftsFetching(false))
  }
}
