import {
  IAchAccount,
  IPostAchAccountInitParams,
  IGetAchAccountsParams,
  IAchAccountBillingRequestId,
  IPostAchAccountCustomerDetailsParams,
  IPostAchAccountBankDetailsParams,
  IPostAchAccountConfirmParams,
} from 'models/AchAccount'
import { IPayload } from 'models/Global'
import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'

export class AchAccountsApi {
  constructor(private axios: AxiosService) {}

  postAchAccountInit = async (params: IPostAchAccountInitParams) => {
    const response = await this.axios.post<
      IPayload<IAchAccountBillingRequestId[]>
    >(Endpoints.postAchAccountInit, params)
    return response
  }

  postAchAccountCustomerDetails = async (
    params: IPostAchAccountCustomerDetailsParams,
  ) => {
    const response = await this.axios.post<IPayload<{}>>(
      Endpoints.postAchAccountCustomerDetails,
      params,
    )
    return response
  }

  postAchAccountBankDetails = async (
    params: IPostAchAccountBankDetailsParams,
  ) => {
    const response = await this.axios.post<IPayload<{ bank_name: string }>>(
      Endpoints.postAchAccountBankDetails,
      params,
    )
    return response
  }

  postAchAccountConfirm = async (params: IPostAchAccountConfirmParams) => {
    const response = await this.axios.post<IPayload<IAchAccount>>(
      Endpoints.postAchAccountConfirm,
      params,
    )
    return response
  }

  getAchAccounts = async (params: IGetAchAccountsParams) => {
    const response = await this.axios.get<IPayload<IAchAccount[]>>(
      Endpoints.getAchAccounts,
      params,
    )
    return response
  }

  deleteAchAccount = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteAchAccount(id))
    return response
  }
}
