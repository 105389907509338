// Root
const ROOT = '/'

//Contact
const CONTACT_US = '/contact_us'

//Help
const HELP = '/help'

// Timesheets
const TIMESHEETS_LIST = '/timesheets'
const TIMESHEETS_VIEW = '/timesheets/:id'

// Invoices
const INVOICES_LIST = '/invoices'
const INVOICES_VIEW = '/invoices/:id'

// Shifts
// TODO Should be changed to '/active_shifts'
const ACTIVE_SHIFTS = '/active_shift'
const SHIFTS = '/shifts'
const CONSOLIDATED_VIEW = '/consolidated_view'
// TODO Should be changes to /shifts/edit/:id
const EDIT_SHIFT = '/shift/edit/:id'
// TODO Should be changes to /shifts/duplicate/:id
const DUPLICATE_SHIFT = '/shift/duplicate/:id'
const FEATURED_SHIFTS = '/featured_shifts/:campaignId'
const PENDING_REVIEW_SHIFTS = '/pending_reviews'

// Templates
const TEMPLATE_LIST = '/templates'
const TEMPLATE_EDIT = '/templates/edit/:id'
const TEMPLATE_ADD = '/templates/add'

// Users
const MY_MANAGERS = '/my_managers'
const MY_MANAGERS_ADD = '/my_managers/add'
const MY_MANAGERS_EDIT = '/my_managers/edit/:id'

const USERS = '/users'
const USERS_ADD = '/users/add'
const USERS_EDIT = '/users/:id'
// TODO Should be changed to /upshifters
const MY_UPSHIFTERS = '/my_upshifters'

// Locations
const LOCATIONS_LIST = '/locations'
const LOCATIONS_VIEW = '/locations/:id'
const LOCATIONS_ADD = '/locations/add'

// Reports
const REPORTS = '/reports'
// TODO Should be changed to other route name
const REPORTS_MY_REPORTS = '/reports/my_reports'
const REPORTS_RETURN_RATE_REPORT = '/reports/return_rate'

// Acknowledged Documents
const ACKNOWLEDGED_DOCUMENTS = '/acknowledged_documents'

// User
const NOTIFICATIONS = '/notifications'
const SETTINGS = '/settings'

// Punch Cards
const PUNCH_CARDS = '/punch_cards'

// Pending verifications
const PENDING_VERIFICATIONS = '/pending_verifications'

// Authentication
const AUTHENTICATION = '/authentication'
const LOGIN = '/login'
const LOGOUT = '/logout'
const PASSWORD_RECOVERY = '/password_recovery'
const MAGIC_LINK = '/magic_link'
const MAGIC_LOGIN = '/magic_login'
const GOOGLE_AUTHENTICATION = '/google_authentication'

// Acknowledge Documents
const ACKNOWLEDGE_DOCUMENTS = '/acknowledge_documents'

// Access Restricted
const ACCESS_RESTRICTED = '/access_restricted'

const ROUTES = {
  ROOT,
  AUTHENTICATION,
  SETTINGS,
  NOTIFICATIONS,
  TIMESHEETS_LIST,
  TIMESHEETS_VIEW,
  INVOICES_LIST,
  INVOICES_VIEW,
  TEMPLATE_LIST,
  TEMPLATE_EDIT,
  TEMPLATE_ADD,
  ACTIVE_SHIFTS,
  LOCATIONS_LIST,
  LOCATIONS_ADD,
  LOCATIONS_VIEW,
  LOGIN,
  LOGOUT,
  MAGIC_LINK,
  MAGIC_LOGIN,
  GOOGLE_AUTHENTICATION,
  PASSWORD_RECOVERY,
  SHIFTS,
  CONSOLIDATED_VIEW,
  EDIT_SHIFT,
  DUPLICATE_SHIFT,
  FEATURED_SHIFTS,
  PENDING_REVIEW_SHIFTS,
  MY_MANAGERS,
  MY_MANAGERS_ADD,
  MY_MANAGERS_EDIT,
  USERS,
  USERS_ADD,
  USERS_EDIT,
  MY_UPSHIFTERS,
  REPORTS,
  HELP,
  REPORTS_MY_REPORTS,
  REPORTS_RETURN_RATE_REPORT,
  ACKNOWLEDGED_DOCUMENTS,
  PUNCH_CARDS,
  PENDING_VERIFICATIONS,
  CONTACT_US,
  ACKNOWLEDGE_DOCUMENTS,
  ACCESS_RESTRICTED,
} as const

export default ROUTES

type TRouteKeys = keyof typeof ROUTES
type TRouteValues = typeof ROUTES[TRouteKeys]
type TRouteDynamicParams = {
  id?: number
  campaignId?: number
}

const ROUTE_DYNAMIC_SEGMENTS = {
  ID: ':id',
  CAMPAIGN_ID: ':campaignId',
}

/**
 * Resolves route by replacing route dynamic segments with specific values
 * @param route
 * @param params
 * @returns resolved route
 */
export const resolveRoute = (
  route: TRouteValues,
  dynamicParams: TRouteDynamicParams,
) => {
  const { id, campaignId } = dynamicParams
  let resolvedRoute: string = route

  if (id) {
    resolvedRoute = resolvedRoute.replace(
      ROUTE_DYNAMIC_SEGMENTS.ID,
      id.toString(),
    )
  }

  if (campaignId) {
    resolvedRoute = resolvedRoute.replace(
      ROUTE_DYNAMIC_SEGMENTS.CAMPAIGN_ID,
      campaignId.toString(),
    )
  }

  return resolvedRoute
}
