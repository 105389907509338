import { IReportsReducer } from 'models/Report'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  GetReports,
  ReportsFetching,
  GetReportsErrors,
  ResetReports,
} from './actions'

const initialState: IReportsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const reportsReducer = (
  state = initialState,
  action: Action,
): IReportsReducer => {
  if (isType(action, GetReports)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, GetReportsErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }

  if (isType(action, ReportsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, ResetReports)) {
    return initialState
  }
  return state
}
