import { IPropertiesReducer, IProperty } from 'models/Property'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFuncs'
import {
  GetProperties,
  PropertiesFetching,
  UpdateProperty,
  DeleteProperty,
  ResetProperties,
} from './actions'

const initialState: IPropertiesReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const propertiesReducer = (
  state = initialState,
  action: Action,
): IPropertiesReducer => {
  if (isType(action, GetProperties)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UpdateProperty)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IProperty>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, DeleteProperty)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IProperty>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, PropertiesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, ResetProperties)) {
    return initialState
  }
  return state
}
