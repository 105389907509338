import { action, payload } from 'ts-action'
import { IExperience } from 'models/Experience'
import { IError, IPayload } from 'models/Global'

export const ExperiencesFetching = action(
  'ExperiencesFetching',
  payload<boolean>(),
)

export const GetExperiences = action(
  'GetExperiences',
  payload<IPayload<IExperience[]>>(),
)

export const GetExperiencesErrors = action(
  'GetExperiencesErrors',
  payload<IError>(),
)
