import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from '../../models/Global'
import { ISuggestedGroupName } from 'models/SuggestedGroupName'

export class SuggestedGroupNameApi {
  constructor(private axios: AxiosService) {}

  getSuggestedGroupNames = async () => {
    const response = await this.axios.get<IPayload<ISuggestedGroupName[]>>(
      Endpoints.getSuggestedGroupNames,
    )
    return response
  }

  getSuggestedGroupName = async (id: number) => {
    const response = await this.axios.get<IPayload<ISuggestedGroupName>>(
      Endpoints.getSuggestedGroupName(id),
    )
    return response
  }
}
