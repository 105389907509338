import { updateArray } from 'helpers/helperFunctions'
import { INotificationsReducer, NOTIFICATION_STATUS } from 'models/Notification'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  GetUnreadNotifications,
  UnreadNotificationsFetching,
  GetUnreadNotificationsErrors,
  ClearUnreadNotifications,
  DeleteUnreadNotification,
  UpdateAllUnreadNotificationsStatus,
  UpdateUnreadNotification,
} from './actions'

const initialState: INotificationsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const unreadNotificationsReducer = (
  state = initialState,
  action: Action,
): INotificationsReducer => {
  if (isType(action, GetUnreadNotifications)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: [...payload.data],
    }
  }

  if (isType(action, GetUnreadNotificationsErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }

  if (isType(action, UnreadNotificationsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, UpdateUnreadNotification)) {
    const { payload } = action
    return {
      ...state,
      data: updateArray(state.data, payload.data),
    }
  }

  if (isType(action, UpdateAllUnreadNotificationsStatus)) {
    const { data } = state

    const updatedNotifications = data.map(notification => ({
      ...notification,
      status: NOTIFICATION_STATUS.READ,
    }))

    return {
      ...state,
      data: updatedNotifications,
    }
  }

  if (isType(action, DeleteUnreadNotification)) {
    const { payload: id } = action
    const { data } = state

    const filteredNotifications = data.filter(
      notification => notification.id !== id,
    )

    return {
      ...state,
      data: filteredNotifications,
    }
  }

  if (isType(action, ClearUnreadNotifications)) {
    return initialState
  }

  return state
}
