import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import { IAuthLogin, IAuthPayload, IMagicLogin } from 'models/Auth'
import { AxiosRequestConfig } from 'axios'
import AuthService from 'helpers/authService'
import MonitoringService from 'helpers/monitoringService'

export class AuthApi {
  constructor(private axios: AxiosService) {}

  login = async (params: IAuthLogin) => {
    const response = await this.axios.post<IAuthPayload>(Endpoints.login, {
      ...params,
    })
    AuthService.setBearerToken(response)

    return response
  }

  magicLogin = async (params: IMagicLogin) => {
    const response = await this.axios.post<IAuthPayload>(
      Endpoints.magic_login,
      {
        ...params,
      },
    )
    AuthService.setBearerToken(response)
    return response
  }

  logout = async () =>
    await this.axios.post(Endpoints.logout).then(() => {
      MonitoringService.clearUser()
    })

  me = async (include = ['google_calendar', 'incomplete_workflows']) =>
    await this.axios.get<IAuthPayload>(Endpoints.me, {
      include,
    })

  refresh = async (config?: AxiosRequestConfig) =>
    await this.axios.post<IAuthPayload>(
      Endpoints.refreshToken,
      undefined,
      config,
    )

  validateToken = async (config?: AxiosRequestConfig) => {
    const response = await this.axios.get<{}>(
      Endpoints.validateToken,
      undefined,
      config,
    )
    return response
  }
}
