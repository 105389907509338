import {
  GetPunchCards,
  SetTotalPendingVerifications,
  ClearPunchCards,
  PunchCardsFetching,
} from './actions'
import { IBusinessPunchCardsReducer } from 'models/PunchCard'
import { Action } from 'redux'
import { isType } from 'ts-action'

const initialState: IBusinessPunchCardsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
  totalPendingVerifications: 0,
}

export const punchCardsReducer = (
  state = initialState,
  action: Action,
): IBusinessPunchCardsReducer => {
  if (isType(action, GetPunchCards)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, SetTotalPendingVerifications)) {
    const { payload } = action
    return {
      ...state,
      totalPendingVerifications: payload,
    }
  }

  if (isType(action, PunchCardsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  // Clear everything but the totalPendingVerifications field
  if (isType(action, ClearPunchCards)) {
    return {
      ...initialState,
      totalPendingVerifications: state.totalPendingVerifications,
    }
  }

  return state
}
