import {
  API_HOST,
  API_VERSION,
  BUSINESS_PORTAL_LINK,
  UPSHIFTER_PORTAL_LINK,
  REQUEST_HEADER_APPLICATION_NAME_BUSINESS,
  REQUEST_HEADER_APPLICATION_NAME_LABOR,
  SLACK_LONG_REQUESTS_CHANNEL_WEB_HOOK_URL,
  SLACK_WEB_HOOK_URL,
} from 'config/envVariables'
import AuthService from 'helpers/authService'
import { isProduction } from 'helpers/environmentHelpers'
import { logLongRequestsToSlack, logToSlack } from 'network/config/log'
import { Error, STATUS_CODES } from 'network/config/error'
import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import store from 'store'
import { UserLogout } from 'data/common/Auth/actions'
import history from 'helpers/history'
import ROUTES from 'routes/routes'
import { Endpoints } from 'network/config/endpoints'

export const BASE_PATH = `${API_HOST}${API_VERSION}/`
export const slackWebHook = axios.create({
  baseURL: SLACK_WEB_HOOK_URL,
})
export const slackLongRequestsChannelWebHook = axios.create({
  baseURL: SLACK_LONG_REQUESTS_CHANNEL_WEB_HOOK_URL,
})

interface ExtendedAxiosRequestConfig extends InternalAxiosRequestConfig {
  meta?: {
    requestStartedAt?: number
  }
}

interface ExtendedAxiosResponse extends AxiosResponse {
  config: ExtendedAxiosRequestConfig
}

/**
 * Logs long requests to Slack (if they are on production)
 * @param response
 * @returns
 */
export const largeRequestLogger = (response: ExtendedAxiosResponse) => {
  // Only execute on production
  if (!isProduction()) {
    return
  }
  if (response.config.meta && response.config.meta.requestStartedAt) {
    const executionTime =
      new Date().getTime() - response.config.meta.requestStartedAt
    // Only execute when the request is larger than 4 seconds
    if (executionTime < 4000) {
      return
    }
    const loggedError = {
      status: response.status,
      AuthServiceGetLoggedInUser: AuthService.getLoggedInUser(),
      headers: {
        ...response.headers,
      },
      config: {
        ...response.config,
      },
    }

    const urlIsNotLoginOrForgotPassword =
      response.config.url !== `${BASE_PATH}${Endpoints.login}` &&
      response.config.url !== `${BASE_PATH}${Endpoints.forgotPassword}`

    if (urlIsNotLoginOrForgotPassword) {
      logLongRequestsToSlack({
        title: `Execution time for: ${response.config.url} - ${executionTime} ms`,
        color: 'danger',
        text: JSON.stringify(loggedError, null, 2),
      })
    }
  }
}

/**
 * Logs errors to Slack
 * @param error
 */
export const errorLogger = (error: any) => {
  const loggedError = {
    status: error.response.status,
    user: AuthService.getLoggedInUser(),
    responseData: {
      ...error.response.data,
    },
    headers: {
      ...error.response.headers,
    },
    config: {
      ...error.response.config,
    },
  }

  const urlIsNotLoginOrForgotPassword =
    error.response.config.url !== `${BASE_PATH}${Endpoints.login}` &&
    error.response.config.url !== `${BASE_PATH}${Endpoints.forgotPassword}`

  if (urlIsNotLoginOrForgotPassword) {
    logToSlack({
      title: `Error in Axios Interceptor : ${error.response.config.url}`,
      color: 'danger',
      text: JSON.stringify(loggedError, null, 2),
    })
  }
}

/**
 * Handles errors from API
 * @param response
 * @returns
 */
export const errorHandling: (response: any) => Promise<Error> = response => {
  const { status, data } = response

  if (status >= 200 && status <= 299) {
    return data
  }

  const errorData = data.data
  const { message, code, action } = data.meta

  if (status === STATUS_CODES.UNPROCESSABLE_ENTITY) {
    handleUnprocessableEntity(errorData, code, message)
  }
  throw new Error(code, message, errorData, action)
}

const handleUnprocessableEntity = (errorData: any, code: any, message: any) => {
  if (errorData) {
    let errorMessage = ''
    if (errorData && Object.entries(errorData).length > 0) {
      Object.entries(errorData).forEach(data => {
        if (data[1]) {
          errorMessage = `${errorMessage}${data[1]}`
        }
      })
      throw new Error(code, message, errorMessage)
    } else {
      throw new Error(code, message, errorMessage)
    }
  }
  const errorMessage = 'Oops, something went wrong. Try again.'
  throw new Error(code, message, errorMessage)
}

export const addRequestInterceptors = (request: ExtendedAxiosRequestConfig) => {
  const currentToken = AuthService.getBearerToken()

  request.headers['Authorization'] = `Bearer ${currentToken}`
  request.headers['x-upshift-application-version'] = `${
    import.meta.env.VITE_VERSION
  }`

  // Sending 'Error-Action-Response' header to backend to get an action in case of 481 error
  request.headers['Error-Action-Response'] = true

  request.meta = request.meta || {}
  request.meta.requestStartedAt = new Date().getTime()

  setApplicationNameHeader(request)

  return request
}

/**
 * Function that sets the application name header depending on the origin of the application
 * @param request
 */
const setApplicationNameHeader = (request: ExtendedAxiosRequestConfig) => {
  if (window.location.origin === BUSINESS_PORTAL_LINK) {
    request.headers[
      'x-upshift-application-name'
    ] = REQUEST_HEADER_APPLICATION_NAME_BUSINESS
    return
  }
  if (window.location.origin === UPSHIFTER_PORTAL_LINK) {
    request.headers[
      'x-upshift-application-name'
    ] = REQUEST_HEADER_APPLICATION_NAME_LABOR
    return
  }
  if (AuthService.getLoggedInUser()) {
    request.headers[
      'x-upshift-application-name'
    ] = REQUEST_HEADER_APPLICATION_NAME_BUSINESS
  }
}

export const addResponseInterceptors = (response: ExtendedAxiosResponse) => {
  if (response.config.meta && response.config.meta.requestStartedAt) {
    largeRequestLogger(response)
  }
  return response
}

export const logoutUserAndRedirectToLogin = () => {
  store.dispatch(UserLogout())
  AuthService.clearStorage()
  history.push(ROUTES.LOGIN)
}
