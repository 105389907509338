import axios, { AxiosRequestConfig } from 'axios'
import AuthService from 'helpers/authService'
import { API } from '..'
import MonitoringService from 'helpers/monitoringService'
import {
  BASE_PATH,
  addRequestInterceptors,
  addResponseInterceptors,
  errorHandling,
  errorLogger,
  largeRequestLogger,
  logoutUserAndRedirectToLogin,
} from 'network/config/utils'
import { CODE_481_ACTIONS, STATUS_CODES } from 'network/config/error'

type Pending = {
  [key: string]: string
}

const pending: Pending = {}
let isRefreshing = false
let subscribers: any = []
const subscribeTokenRefresh = (cb: (token: any) => void) => {
  subscribers.push(cb)
}
const onRefreshed = (token: string) => {
  subscribers.map((cb: any) => cb(token), 2000)
  subscribers = []
}
const removePending = (config: AxiosRequestConfig, cancelToken?: any) => {
  // make sure the url is same for both request and response
  const url = (config.url as string).replace(config.baseURL as string, '/')
  // stringify whole RESTful request with URL params
  const flagUrl =
    url + '&' + config.method + '&' + JSON.stringify(config.params)
  if (flagUrl in pending) {
    if (cancelToken) {
      cancelToken() // abort the request
    } else {
      delete pending[flagUrl]
    }
  } else if (cancelToken) {
    pending[flagUrl] = cancelToken // store the cancel function
  }
}

export class AxiosService {
  instance = axios.create({
    baseURL: BASE_PATH,
  })

  constructor() {
    this.instance.interceptors.request.use(addRequestInterceptors)
    this.instance.interceptors.response.use(
      addResponseInterceptors,
      this.handleResponseError,
    )
  }

  handleResponseError(error: any) {
    const {
      config,
      response: { status },
    } = error

    const action =
      STATUS_CODES.SPECIAL_ACTIONS === status
        ? error.response.data.meta.action
        : undefined

    largeRequestLogger(error.response)
    errorLogger(error)

    if (status >= 500) {
      MonitoringService.logError(error)
    }
    const showError = error.response
    const originalRequest = config
    removePending(error.config)
    if (action === CODE_481_ACTIONS.TOKEN_EXPIRED) {
      if (!isRefreshing) {
        isRefreshing = true
        API.Auth.refresh()
          .then(response => {
            const { token } = response.meta.auth
            AuthService.setBearerToken(response)
            return token
          })
          .then(token => {
            onRefreshed(token)
          })
          .then(() => {
            isRefreshing = false
          })
      }
      const retryOrigReq = new Promise(resolve => {
        subscribeTokenRefresh(token => {
          // replace the expired token and retry
          originalRequest.headers.Authorization = `Bearer ${token}`
          resolve(this.instance(originalRequest))
        })
      })
      return retryOrigReq
    }
    if (action === CODE_481_ACTIONS.TOKEN_INVALID) {
      logoutUserAndRedirectToLogin()
    }
    return Promise.reject(showError)
  }

  get<T>(
    url: string,
    params?: any,
    config: AxiosRequestConfig = {},
  ): Promise<T> {
    return this.instance
      .get(url, { params, ...config })
      .then(({ data }) => data)
      .catch(error => errorHandling(error))
  }

  post<T>(
    url: string,
    params?: any,
    config: AxiosRequestConfig = {},
  ): Promise<T> {
    return this.instance
      .post(url, params, config)
      .then(({ data }) => data)
      .catch(error => errorHandling(error))
  }

  delete<T>(
    url: string,
    params?: any,
    config: AxiosRequestConfig = {},
  ): Promise<T> {
    return this.instance
      .delete(url, { data: params, ...config })
      .then(({ data }) => data)
      .catch(errorHandling)
  }

  put<T>(
    url: string,
    params: any,
    config: AxiosRequestConfig = {},
  ): Promise<T> {
    return this.instance
      .put(url, params, config)
      .then(({ data }) => data)
      .catch(errorHandling)
  }
}
