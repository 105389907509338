import { action, payload } from 'ts-action'
import { IError, IPayload } from 'models/Global'
import { IUser } from 'models/User'

export const AssignedManagersFetching = action(
  'AssignedManagersFetching',
  payload<boolean>(),
)
export const GetAssignedManagers = action(
  'GetAssignedManagers',
  payload<IPayload<IUser[]>>(),
)
export const GetAssignedManagersError = action(
  'GetAssignedManagersError',
  payload<IError>(),
)

export const DeleteAssignedManager = action(
  'DeleteAssignedManager',
  payload<number>(),
)

export const AddAssignedManager = action('AddAssignedManager', payload<IUser>())
