import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import { IPayload } from 'models/Global'
import {
  IScheduledUpshifter,
  IGetScheduledUpshiftersParams,
  IPutScheduledUpshifterParams,
  IPostScheduledUpshifterParams,
} from 'models/ScheduledUpshifter'

export class ScheduledUpshiftersApi {
  constructor(private axios: AxiosService) {}

  getScheduledUpshifters = async (params?: IGetScheduledUpshiftersParams) => {
    const response = await this.axios.get<IPayload<IScheduledUpshifter[]>>(
      Endpoints.getScheduledUpshifters,
      params,
    )
    return response
  }

  getScheduledUpshifter = async (
    id: number,
    params?: IGetScheduledUpshiftersParams,
  ) => {
    const response = await this.axios.get<IPayload<IScheduledUpshifter>>(
      Endpoints.getScheduledUpshifter(id),
      params,
    )
    return response
  }

  postScheduledUpshifter = async (params: IPostScheduledUpshifterParams) => {
    const response = await this.axios.post<IPayload<IScheduledUpshifter>>(
      Endpoints.postScheduledUpshifter,
      params,
    )
    return response
  }

  putScheduledUpshifter = async (
    id: number,
    params?: IPutScheduledUpshifterParams,
  ) => {
    const response = await this.axios.put<IPayload<IScheduledUpshifter>>(
      Endpoints.putScheduledUpshifter(id),
      params,
    )
    return response
  }

  deleteScheduledUpshifter = async (id: number) => {
    const response = await this.axios.delete<{}>(
      Endpoints.deleteScheduledUpshifter(id),
    )
    return response
  }
}
