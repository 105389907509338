import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import { IPayload } from 'models/Global'
import {
  INotification,
  IGetNotificationsParams,
  IPutNotificationParams,
  IPostNotificationParams,
} from 'models/Notification'

export class NotificationsApi {
  constructor(private axios: AxiosService) {}

  getNotifications = async (params?: IGetNotificationsParams) => {
    const response = await this.axios.get<IPayload<INotification[]>>(
      Endpoints.getNotifications,
      params,
    )
    return response
  }

  getNotification = async (id: number, params?: IGetNotificationsParams) => {
    const response = await this.axios.get<IPayload<INotification>>(
      Endpoints.getNotification(id),
      params,
    )
    return response
  }

  postNotification = async (params: IPostNotificationParams) => {
    const response = await this.axios.post<IPayload<INotification>>(
      Endpoints.postNotification,
      params,
    )
    return response
  }

  putNotification = async (id: number, params?: IPutNotificationParams) => {
    const response = await this.axios.put<IPayload<INotification>>(
      Endpoints.putNotification(id),
      params,
    )
    return response
  }

  deleteNotification = async (id: number) => {
    const response = await this.axios.delete<{}>(
      Endpoints.deleteNotification(id),
    )
    return response
  }

  readNotifications = async () => {
    const response = await this.axios.put<{}>(Endpoints.readNotifications, {})
    return response
  }
}
