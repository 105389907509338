import { IError, IPayload } from 'models/Global'
import { IShiftIdentifierPolicy } from 'models/ShiftIdentifierPolicy'
import { action, payload } from 'ts-action'

export const GetShiftIdentifierPolicy = action(
  'GetShiftIdentifierPolicy',
  payload<IPayload<IShiftIdentifierPolicy>>(),
)

export const AddShiftIdentifierPolicy = action(
  'AddShiftIdentifierPolicy',
  payload<IPayload<IShiftIdentifierPolicy>>(),
)

export const UpdateShiftIdentifierPolicy = action(
  'UpdateShiftIdentifierPolicy',
  payload<IPayload<IShiftIdentifierPolicy>>(),
)

export const GetShiftIdentifierPolicyErrors = action(
  'GetShiftIdentifierPolicyErrors',
  payload<IError>(),
)

export const ClearShiftIdentifierPolicy = action('ClearShiftIdentifierPolicy')

export const ShiftIdentifierPolicyFetching = action(
  'ShiftIdentifierPolicyFetching',
  payload<boolean>(),
)

export const DeleteShiftIdentifierPolicy = action(
  'DeleteShiftIdentifierPolicy',
  payload<number>(),
)
