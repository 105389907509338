import { ICalendarEventsReducer } from 'models/CalendarEvent'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  GetBusinessCalendarEvents,
  BusinessCalendarEventsFetching,
  GetBusinessCalendarEventsErrors,
} from './actions'

const initialState: ICalendarEventsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const businessCalendarEventsReducer = (
  state = initialState,
  action: Action,
): ICalendarEventsReducer => {
  if (isType(action, GetBusinessCalendarEvents)) {
    const { payload } = action
    return {
      ...state,
      data: payload,
    }
  }

  if (isType(action, GetBusinessCalendarEventsErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }

  if (isType(action, BusinessCalendarEventsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
