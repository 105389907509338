import { action, payload } from 'ts-action'
import { IActivityLog } from 'models/ActivityLog'
import { IError, IPayload } from 'models/Global'
import { IPunchCard } from 'models/PunchCard'

export const PunchCardsActivityLogsFetching = action(
  'PunchCardsActivityLogsFetching',
  payload<boolean>(),
)

export const GetPunchCardsActivityLogs = action(
  'GetPunchCardsActivityLogs',
  payload<IPayload<IActivityLog<IPunchCard>[]>>(),
)

export const AddPunchCardsActivityLog = action(
  'AddPunchCardsActivityLog',
  payload<IPayload<IActivityLog<IPunchCard>>>(),
)

export const UpdatePunchCardsActivityLog = action(
  'UpdatePunchCardsActivityLog',
  payload<IPayload<IActivityLog<IPunchCard>>>(),
)

export const DeletePunchCardsActivityLog = action(
  'DeletePunchCardsActivityLog',
  payload<number>(),
)

export const GetPunchCardsActivityLogsErrors = action(
  'GetPunchCardsActivityLogsErrors',
  payload<IError>(),
)
