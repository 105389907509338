import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from '../../models/Global'
import {
  IGroupApplication,
  IGetGroupApplicationsParams,
  IPostGroupApplicationsParams,
  IPutGroupApplicationsParams,
} from '../../models/GroupApplication'

export class GroupApplicationsApi {
  constructor(private axios: AxiosService) {}

  getGroupApplications = async (params?: IGetGroupApplicationsParams) => {
    const response = await this.axios.get<IPayload<IGroupApplication[]>>(
      Endpoints.getGroupApplications,
      params,
    )
    return response
  }

  postGroupApplication = async (params: IPostGroupApplicationsParams) => {
    const response = await this.axios.post<IPayload<IGroupApplication>>(
      Endpoints.postGroupApplication,
      params,
    )
    return response
  }

  postGroupApplicationAccept = async (params: IPostGroupApplicationsParams) => {
    const response = await this.axios.post<IPayload<IGroupApplication>>(
      Endpoints.postGroupApplicationAccept,
      params,
    )
    return response
  }

  postGroupApplicationDecline = async (
    params: IPostGroupApplicationsParams,
  ) => {
    const response = await this.axios.post<IPayload<IGroupApplication>>(
      Endpoints.postGroupApplicationDecline,
      params,
    )
    return response
  }

  putGroupApplication = async (params?: IPutGroupApplicationsParams) => {
    const response = await this.axios.put<IPayload<IGroupApplication>>(
      Endpoints.putGroupApplication,
      params,
    )
    return response
  }
}
