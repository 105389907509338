import { action, payload } from 'ts-action'
import { IFavorite } from 'models/Favorite'
import { IError, IPayload } from 'models/Global'

export const FavoritesFetching = action('FavoritesFetching', payload<boolean>())

export const AddFavorite = action('AddFavorite', payload<IPayload<IFavorite>>())

export const UpdateFavorite = action(
  'UpdateFavorite',
  payload<IPayload<IFavorite>>(),
)

export const DeleteFavorite = action('DeleteFavorite', payload<number>())

//This action is being dispatched when a Business unfavorites an Upshifters through My Upshifters/Favorites tab
export const UnfavoriteUpshifter = action(
  'UnfavoriteUpshifter',
  payload<number>(),
)

export const GetFavoritesErrors = action(
  'GetFavoritesErrors',
  payload<IError>(),
)
