import {
  IAcknowledgedDocumentReducer,
  IAcknowledgedDocument,
} from 'models/AcknowledgedDocument'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFuncs'
import {
  GetAcknowledgedDocuments,
  AcknowledgedDocumentsFetching,
  UpdateAcknowledgedDocument,
  DeleteAcknowledgedDocument,
} from './actions'

const initialState: IAcknowledgedDocumentReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const acknowledgedDocumentsReducer = (
  state = initialState,
  action: Action,
): IAcknowledgedDocumentReducer => {
  if (isType(action, GetAcknowledgedDocuments)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UpdateAcknowledgedDocument)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IAcknowledgedDocument>(
      data,
      payload.data,
    )
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, DeleteAcknowledgedDocument)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IAcknowledgedDocument>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, AcknowledgedDocumentsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
