import { Action } from 'redux'
import { isType } from 'ts-action'
import { IAuthData, IAuthReducer, IAuth } from 'models/Auth'
import {
  UserLogin,
  UserLogout,
  UserFetching,
  GetOpenUser,
  GetUserErrors,
  GetUserImageLoader,
  UserUpdateLocation,
  UserUpdateLocationStatus,
  GetUserLocation,
  UserHandleInfo,
  ResetAuthReducer,
} from './actions'

const initialState: IAuthReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: {
    user: {} as IAuthData,
    role: '',
    badges: null,
    isLoggedIn: false,
    loading: false,
    error: false,
    imageLoader: false,
    info: { message: '', type: '' },
    locationAllowed: true,
    gettingLocation: false,
    latitude: 0,
    longitude: 0,
  } as IAuth,
}

export const authReducer = (
  state = initialState,
  action: Action,
): IAuthReducer => {
  if (isType(action, UserLogin)) {
    return {
      ...state,
      error: undefined,
      isLoading: false,
      data: { ...state.data, isLoggedIn: true },
    }
  }
  if (isType(action, UserLogout)) {
    return initialState
  }
  if (isType(action, UserFetching)) {
    const { payload } = action
    return {
      ...state,
      isLoading: payload,
    }
  }
  if (isType(action, GetOpenUser)) {
    const { payload } = action
    return {
      ...state,
      isLoading: false,
      error: undefined,
      data: {
        ...state.data,
        user: payload,
        imageLoader: false,
        info: { message: '', type: '' },
      },
    }
  }
  if (isType(action, GetUserImageLoader)) {
    const { payload } = action
    return {
      ...state,
      data: { ...state.data, imageLoader: payload },
    }
  }
  if (isType(action, UserHandleInfo)) {
    const { payload } = action
    return {
      ...state,
      data: {
        ...state.data,
        info: payload,
      },
    }
  }
  if (isType(action, GetUserLocation)) {
    const { payload } = action
    return {
      ...state,
      data: {
        ...state.data,
        gettingLocation: payload,
      },
    }
  }
  if (isType(action, UserUpdateLocation)) {
    const { payload } = action
    return {
      ...state,
      data: {
        ...state.data,
        latitude: payload.latitude,
        longitude: payload.longitude,
      },
    }
  }
  if (isType(action, UserUpdateLocationStatus)) {
    const { payload } = action
    return {
      ...state,
      data: {
        ...state.data,
        locationAllowed: payload,
      },
    }
  }
  if (isType(action, GetUserErrors)) {
    const { payload } = action
    return {
      ...state,
      isLoading: false,
      meta: {
        ...state.meta,
        message: payload.message,
        code: payload.code,
      },
    }
  }
  if (isType(action, ResetAuthReducer)) {
    return initialState
  }
  return state
}
