import { action, payload } from 'ts-action'
import { ICalendarEvent } from 'models/CalendarEvent'
import { IError } from 'models/Global'

export const BusinessCalendarEventsFetching = action(
  'BusinessCalendarEventsFetching',
  payload<boolean>(),
)

export const GetBusinessCalendarEvents = action(
  'GetBusinessCalendarEvents',
  payload<ICalendarEvent[]>(),
)

export const GetBusinessCalendarEventsErrors = action(
  'GetBusinessCalendarEventsErrors',
  payload<IError>(),
)
