import { ICreditCardsReducer, ICreditCard } from 'models/CreditCard'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { deleteFromArray, addToArray } from 'helpers/helperFuncs'
import {
  GetCreditCards,
  CreditCardsFetching,
  DeleteCreditCard,
  AddCreditCard,
} from './actions'

const initialState: ICreditCardsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const creditCardsReducer = (
  state = initialState,
  action: Action,
): ICreditCardsReducer => {
  if (isType(action, GetCreditCards)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, AddCreditCard)) {
    const {
      payload: { data: item },
    } = action
    const { data } = state
    return {
      ...state,
      data: addToArray<ICreditCard>(item, data),
    }
  }
  if (isType(action, DeleteCreditCard)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<ICreditCard>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, CreditCardsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
