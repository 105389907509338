import {
  IBusinessEntityReducer,
  IBusinessEntity,
  IBusinessSetting,
} from 'models/BusinessEntity'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  BusinessEntityFetching,
  GetBusinessEntity,
  BusinessEntityHasMore,
  GetBusinessEntityErrors,
  UpdateBusinessEntitySettings,
} from './actions'
import AuthService from 'helpers/authService'

const initialState: IBusinessEntityReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: {} as IBusinessEntity,
}

export const businessEntityReducer = (
  state = initialState,
  action: Action,
): IBusinessEntityReducer => {
  if (isType(action, GetBusinessEntity)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, UpdateBusinessEntitySettings)) {
    const { payload } = action
    const updatedBusinessEntity = {
      ...state.data,
      business_setting: {
        ...state.data.business_setting,
        ...payload,
      } as IBusinessSetting,
    }
    // we need to update the business entity in local storage as well
    AuthService.setBusinessEntity(updatedBusinessEntity)
    return {
      ...state,
      data: updatedBusinessEntity,
    }
  }

  if (isType(action, GetBusinessEntityErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }
  if (isType(action, BusinessEntityHasMore)) {
    const { payload } = action
    return {
      ...state,
      hasMore: payload,
    }
  }

  if (isType(action, BusinessEntityFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
