import {
  INotificationPreferenceReducer,
  INotificationPreference,
  NOTIFICATION_CHANNEL_NAME,
  NOTIFICATION_PREFERENCE_STATUS,
} from 'models/NotificationPreference'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray } from 'helpers/helperFuncs'
import {
  GetNotificationPreferences,
  NotificationPreferencesFetching,
  UpdateNotificationPreferences,
  EnableTextNotificationPreferences,
} from './actions'

const initialState: INotificationPreferenceReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const notificationPreferencesReducer = (
  state = initialState,
  action: Action,
): INotificationPreferenceReducer => {
  if (isType(action, GetNotificationPreferences)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UpdateNotificationPreferences)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<INotificationPreference>(
      data,
      payload.data,
    )
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, EnableTextNotificationPreferences)) {
    const enabledTextNotificationPreferences: INotificationPreference[] = state.data.map(
      preference => {
        if (
          preference.notification_channel_name ===
            NOTIFICATION_CHANNEL_NAME.TEXT &&
          preference.status !== null
        ) {
          return {
            ...preference,
            status: NOTIFICATION_PREFERENCE_STATUS.ENABLED,
          }
        }
        return preference
      },
    )
    return {
      ...state,
      data: enabledTextNotificationPreferences,
    }
  }
  if (isType(action, NotificationPreferencesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
