import { action, payload } from 'ts-action'
import { IPunchCard } from 'models/PunchCard'
import { IError, IPayload } from 'models/Global'

export const PunchCardsFetching = action(
  'PunchCardsFetching',
  payload<boolean>(),
)

export const GetPunchCards = action(
  'GetPunchCards',
  payload<IPayload<IPunchCard[]>>(),
)

export const SetTotalPendingVerifications = action(
  'SetTotalPendingVerifications',
  payload<number>(),
)

export const GetPunchCardsErrors = action(
  'GetPunchCardsErrors',
  payload<IError>(),
)

export const ClearPunchCards = action('ClearPunchCards')
