import { action, payload } from 'ts-action'
import {
  IGigDayExtended,
  ITimesheet,
  IUpdateApplicationBlockPropertiesCountPayload,
} from 'models/Timesheet'
import { IError, IPayload } from 'models/Global'
import { IProperty } from 'models/Property'
import { IShift } from 'models/Shift'
import { IPunchCard } from 'models/PunchCard'
import { IBonus, IDeleteBonusParams } from 'models/Bonus'
import { IApplication } from 'models/Application'

//#region General Timesheet actions
export const TimesheetFetching = action('TimesheetFetching', payload<boolean>())

export const GetTimesheet = action(
  'GetTimesheet',
  payload<IPayload<ITimesheet>>(),
)

export const UpdateTimesheet = action(
  'UpdateTimesheet',
  payload<IPayload<ITimesheet>>(),
)

export const GetTimesheetErrors = action(
  'GetTimesheetErrors',
  payload<IError>(),
)

export const ClearTimesheet = action('ClearTimesheet')
//#endregion

//#region Timesheet property actions
export const GetTimesheetPropertyFetching = action(
  'GetTimesheetPropertyFetching',
  payload<boolean>(),
)

export const GetTimesheetProperty = action(
  'GetTimesheetProperty',
  payload<IPayload<IProperty>>(),
)
//#endregion

//#region Timesheet shifts actions
export const GetTimesheetShiftsFetching = action(
  'GetTimesheetShiftsFetching',
  payload<boolean>(),
)

export const GetTimesheetShifts = action(
  'GetTimesheetShifts',
  payload<IPayload<IShift[]>>(),
)
//#endregion

//#region Timesheet punch cards actions
export const GetTimesheetPunchCardsFetching = action(
  'GetTimesheetPunchCardsFetching',
  payload<boolean>(),
)

export const GetTimesheetPunchCards = action(
  'GetTimesheetPunchCards',
  payload<IPayload<IPunchCard[]>>(),
)

export const AddTimesheetPunchCard = action(
  'AddTimesheetPunchCard',
  payload<IPayload<IPunchCard>>(),
)

export const AddTimesheetPunchCardError = action(
  'AddTimesheetPunchCardError',
  payload<IError>(),
)

export const UpdateTimesheetPunchCard = action(
  'UpdateTimesheetPunchCard',
  payload<IPayload<IPunchCard>>(),
)

export const DeleteTimesheetPunchCard = action(
  'DeleteTimesheetPunchCard',
  payload<IPayload<IPunchCard>>(),
)
//#endregion

//#region Timesheet bonuses actions
export const GetTimesheetBonuses = action(
  'GetTimesheetBonuses',
  payload<IPayload<IBonus[]>>(),
)

export const AddTimesheetBonus = action(
  'AddTimesheetBonus',
  payload<IPayload<IBonus & { application: IApplication }>>(),
)

export const UpdateTimesheetBonus = action(
  'UpdateTimesheetBonus',
  payload<IPayload<IBonus & { application: IApplication }>>(),
)

export const DeleteTimesheetBonus = action(
  'DeleteTimesheetBonus',
  payload<IDeleteBonusParams>(),
)
//#endregion

//#region Timesheet applications actions
export const GetTimesheetApplicationsFetching = action(
  'GetTimesheetApplicationsFetching',
  payload<boolean>(),
)

export const GetTimesheetApplications = action(
  'GetTimesheetApplications',
  payload<IPayload<IApplication[]>>(),
)
//#endregion

//#region Timesheet gig days actions
export const GetTimesheetGigDays = action(
  'GetTimesheetGigDays',
  payload<IPayload<IGigDayExtended[]>>(),
)
//#endregion

export const UpdateApplicationBlockPropertiesCount = action(
  'UpdateApplicationBlockPropertiesCount',
  payload<IUpdateApplicationBlockPropertiesCountPayload>(),
)
