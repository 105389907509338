import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import { IPayload } from 'models/Global'
import {
  IGetTimesheetsParams,
  ITimesheet,
  IPostTimesheetParams,
  IPutTimesheetParams,
  IGetTimesheetParams,
} from 'models/Timesheet'

export class TimesheetsApi {
  constructor(private axios: AxiosService) {}

  getTimesheets = async (params?: IGetTimesheetsParams) => {
    const response = await this.axios.get<IPayload<ITimesheet[]>>(
      Endpoints.getTimesheets,
      params,
    )
    return response
  }

  getTimesheet = async (id: number, params?: IGetTimesheetParams) => {
    const response = await this.axios.get<IPayload<ITimesheet>>(
      Endpoints.getTimesheet(id),
      params,
    )
    return response
  }

  postTimesheet = async (params: IPostTimesheetParams) => {
    const response = await this.axios.post<IPayload<ITimesheet>>(
      Endpoints.postTimesheet,
      params,
    )
    return response
  }

  putTimesheet = async (id: number, params: IPutTimesheetParams) => {
    const response = await this.axios.put<IPayload<ITimesheet>>(
      Endpoints.putTimesheet(id),
      params,
    )
    return response
  }

  deleteTimesheet = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteTimesheet(id))
    return response
  }

  getTimesheetCSVReport = async (id: number) => {
    const response = await this.axios.get<Blob>(
      Endpoints.getTimesheetCSVReport(id),
      {},
      {
        responseType: 'blob',
      },
    )
    return response
  }
}
