import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import { IPayload } from 'models/Global'
import {
  IVaccinationType,
  IGetVaccinationTypesParams,
} from 'models/VaccinationType'

export class VaccinationTypesApi {
  constructor(private axios: AxiosService) {}

  getVaccinationTypes = async (params?: IGetVaccinationTypesParams) => {
    const response = await this.axios.get<IPayload<IVaccinationType[]>>(
      Endpoints.getVaccinationTypes,
      params,
    )
    return response
  }
}
