import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'
import { IPayload } from 'models/Global'
import {
  IGetMinimumWagesParams,
  IMinimumWage,
  IPutMinimumWageParams,
  IPostMinimumWageParams,
} from 'models/MinimumWage'

export class MinimumWagesApi {
  constructor(private axios: AxiosService) {}

  getMinimumWages = async (params?: IGetMinimumWagesParams) => {
    const response = await this.axios.get<IPayload<IMinimumWage[]>>(
      Endpoints.getMinimumWages,
      params,
    )
    return response
  }

  getMinimumWage = async (id: number) => {
    const response = await this.axios.get<IPayload<IMinimumWage>>(
      Endpoints.getMinimumWage(id),
    )
    return response
  }

  putMinimumWage = async (id: number, params?: IPutMinimumWageParams) => {
    const reponse = await this.axios.put<IPayload<IMinimumWage>>(
      Endpoints.putMinimumWage(id),
      params,
    )
    return reponse
  }

  postMinimumWage = async (params: IPostMinimumWageParams) => {
    const response = await this.axios.post<IPayload<IMinimumWage>>(
      Endpoints.postMinimumWage,
      params,
    )
    return response
  }

  deleteMinimumWage = async (id: number) => {
    const response = await this.axios.delete<{}>(
      Endpoints.deleteMinimumWage(id),
    )
    return response
  }
}
