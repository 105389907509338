import { IUserReducer, IUser } from 'models/User'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { GetUpshifter, UpshifterFetching, ClearUpshifter } from './actions'
import {
  AddFavorite as FavoriteUpshifter,
  DeleteFavorite as UnfavoriteUpshifter,
} from 'data/business/Favorite/actions'

const initialState: IUserReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: {} as IUser,
}

export const upshifterReducer = (
  state = initialState,
  action: Action,
): IUserReducer => {
  if (isType(action, GetUpshifter)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UpshifterFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, FavoriteUpshifter)) {
    const { payload } = action
    const favoritedUpshifter = {
      ...state.data,
      favorite: true,
      favorite_id: payload.data.id,
    }
    return {
      ...state,
      data: favoritedUpshifter,
    }
  }
  if (isType(action, UnfavoriteUpshifter)) {
    const unfavoritedUpshifter = {
      ...state.data,
      favorite: false,
      favorite_id: null,
    }
    return { ...state, data: unfavoritedUpshifter }
  }
  if (isType(action, ClearUpshifter)) {
    return initialState
  }
  return state
}
