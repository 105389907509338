import { ChunksLoaderWrap, Logo } from 'components/common/ChunksLoader/style'
import plainLogo from 'img/logo-gray.png'
import { useEffect } from 'react'

export const ChunksLoader = () => {
  useEffect(() => {
    if (document.getElementById('chunkLoaderWrap')) {
      const element = document.getElementById('chunkLoaderWrap')
      element!.outerHTML = ''
    }
  }, [])

  return (
    <ChunksLoaderWrap>
      <Logo src={plainLogo} />
    </ChunksLoaderWrap>
  )
}
