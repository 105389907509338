import { IPropertyReducer, IProperty } from 'models/Property'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { GetProperty, PropertyFetching, PropertyErrors } from './actions'

const initialState: IPropertyReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: {} as IProperty,
}

export const propertyReducer = (
  state = initialState,
  action: Action,
): IPropertyReducer => {
  if (isType(action, GetProperty)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, PropertyFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, PropertyErrors)) {
    const {
      payload: { code, message },
    } = action
    return {
      ...state,
      error: {
        code,
        message,
      },
    }
  }
  return state
}
