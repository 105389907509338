import { action, payload } from 'ts-action'
import { IError, IPayload } from 'models/Global'
import { ITimesheet } from 'models/Timesheet'

export const GetTimesheets = action(
  'GetTimesheets',
  payload<IPayload<ITimesheet[]>>(),
)

export const TimesheetsFetching = action(
  'TimesheetsFetching',
  payload<boolean>(),
)

export const GetTimesheetsErrors = action(
  'GetTimesheetsErrors',
  payload<IError>(),
)
