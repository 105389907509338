import { action, payload } from 'ts-action'
import { IError } from 'models/Global'
import { IUser } from 'models/User'
import { IAuthData } from 'models/Auth'

interface ILocation {
  latitude: number
  longitude: number
}

interface IInfo {
  message: string
  type: string
}

export const UserFetching = action('UserFetching', payload<boolean>())

export const UserLogin = action('UserLogin')

export const UserLogout = action('UserLogout')

export const GetOpenUser = action('GetOpenUser', payload<IAuthData | IUser>())

export const GetUserImageLoader = action(
  'GetUserImageLoader',
  payload<boolean>(),
)

export const UserHandleInfo = action('UserHandleInfo', payload<IInfo>())

export const UserUpdateLocation = action(
  'UserUpdateLocation',
  payload<ILocation>(),
)

export const UserUpdateLocationStatus = action(
  'UserUpdateLocationStatus',
  payload<boolean>(),
)

export const GetUserLocation = action('GetUserLocation', payload<boolean>())

export const GetUserErrors = action('GetUserErrors', payload<IError>())

export const ResetAuthReducer = action('ResetAuthReducer')
