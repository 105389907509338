import { action, payload } from 'ts-action'
import { IApplication } from 'models/Application'
import { IError, IPayload } from 'models/Global'
import { IGroupApplication } from 'models/GroupApplication'

export const HiddenApplicationsFetching = action(
  'HiddenApplicationsFetching',
  payload<boolean>(),
)

export const GetHiddenApplications = action(
  'GetHiddenApplications',
  payload<IPayload<IApplication[]>>(),
)

export const UnhideHiddenApplication = action(
  'UnhideHiddenApplication',
  payload<IPayload<IApplication>>(),
)

export const UnhideGroupHiddenApplication = action(
  'UnhideGroupHiddenApplication',
  payload<IPayload<IGroupApplication>>(),
)

export const GetHiddenApplicationsErrors = action(
  'GetHiddenApplicationsErrors',
  payload<IError>(),
)
