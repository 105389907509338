import { acknowledgedDocumentsReducer } from 'data/business/AcknowledgedDocuments/reducer'
import { activityLogsReducer } from 'data/business/ActivityLogs/PunchCardActivityLogs/reducer'
import { applicationsReducer } from 'data/business/Applications/reducer'
import { assignedManagersReducer } from 'data/business/AssignedManagers/reducer'
import { businessEntityReducer } from 'data/business/BusinessEntity/reducer'
import { creditCardsReducer } from 'data/business/CreditCards/reducer'
import { currentlyActiveShiftsReducer } from 'data/business/CurrentlyActiveShifts/reducer'
import { gigDaysReducer } from 'data/business/GigDays/reducer'
import { invoicesReducer } from 'data/business/Invoices/reducer'
import { businessLoadingReducer } from 'data/business/Loading/reducer'
import { managersReducer } from 'data/business/Managers/reducer'
import { netOptionsReducer } from 'data/business/NetOptions/reducer'
import { notificationPreferencesReducer as businessNotificationPreferencesReducer } from 'data/business/NotificationPreferences/reducer'
import { notificationsReducer } from 'data/business/Notifications/reducer'
import { propertiesReducer } from 'data/business/Properties/reducer'
import { propertyReducer } from 'data/business/Property/reducer'
import { punchCardsReducer as businessPunchCardsReducer } from 'data/business/PunchCards/reducer'
import { reportsReducer } from 'data/business/Reports/reducer'
import { scheduledUpshiftersReducer } from 'data/business/ScheduledUpshifters/reducer'
import { shiftAutoAcceptCertificatesReducer } from 'data/business/ShiftAutoAcceptCertificates/reducer'
import { shiftIdentifierPolicyReducer } from 'data/business/ShiftIdentifierPolicy/reducer'
import { businessShiftsReducer } from 'data/business/Shifts/reducer'
import { templateReducer } from 'data/business/Template/reducer'
import { templatesReducer } from 'data/business/Templates/reducer'
import { timesheetReducer } from 'data/business/Timesheet/reducer'
import { timesheetsReducer } from 'data/business/Timesheets/reducer'
import { unassignedManagersReducer } from 'data/business/UnassignedManagers/reducer'
import { unreadNotificationsReducer } from 'data/business/UnreadNotifications/reducer'
import { upshifterReducer } from 'data/business/Upshifter/reducer'
import { upshiftersReducer } from 'data/business/Upshifters/reducer'
import { userCertificatesReducer as businessUserCertificatesReducer } from 'data/business/UserCertificates/reducer'
import { combineReducers } from 'redux'
import { invoiceReducer } from '../../data/business/Invoice/reducer'
import { experiencesReducer } from 'data/business/UpshifterExperiences/reducer'
import { gigTypeExperiencesReducer } from 'data/business/GigTypeExperiences/reducer'
import { authReducer } from 'data/common/Auth/reducer'
import { shiftReducer as businessShiftReducer } from 'data/business/Shift/reducer'
import { shiftValuesReducer } from 'data/business/ShiftValues/reducer'
import { hiddenApplicationsReducer } from 'data/business/HiddenApplications/reducer'
import { requestApplicationsReducer } from 'data/business/RequestApplications/reducer'
import { businessCalendarEventsReducer } from 'data/business/BusinessCalendarEvents/reducer'
import { achDebitAuthorizationFlowReducer } from 'data/business/AchDebitAuthorizationFlow/reducer'
import { achAccountsReducer } from 'data/business/AchAccounts/reducer'

const rootReducer = combineReducers({
  businessShift: businessShiftReducer,
  shiftValuesReducer,
  auth: authReducer,
  businessEntityReducer,
  assignedManagersReducer,
  notifications: notificationsReducer,
  unreadNotifications: unreadNotificationsReducer,
  assignedManagers: assignedManagersReducer,
  unassignedManagers: unassignedManagersReducer,
  notificationsReducer,
  gigDays: gigDaysReducer,
  property: propertyReducer,
  properties: propertiesReducer,
  timesheets: timesheetsReducer,
  invoicesReducer,
  invoiceReducer,
  applications: applicationsReducer,
  templates: templatesReducer,
  template: templateReducer,
  upshifters: upshiftersReducer,
  experiences: experiencesReducer,
  gigTypeExperiences: gigTypeExperiencesReducer,
  upshifterReducer,
  managers: managersReducer,
  reports: reportsReducer,
  acknowledgedDocuments: acknowledgedDocumentsReducer,
  businessLoadingReducer,
  scheduledUpshifters: scheduledUpshiftersReducer,
  businessCalendarEvents: businessCalendarEventsReducer,
  netOptionsReducer,
  timesheet: timesheetReducer,
  activityLogsReducer,
  businessShifts: businessShiftsReducer,
  currentlyActiveShifts: currentlyActiveShiftsReducer,
  businessPunchCards: businessPunchCardsReducer,
  creditCards: creditCardsReducer,
  businessUserCertificatesReducer,
  businessNotificationPreferencesReducer,
  shiftAutoAcceptCertificatesReducer,
  shiftIdentifierPolicy: shiftIdentifierPolicyReducer,
  hiddenApplications: hiddenApplicationsReducer,
  requestApplications: requestApplicationsReducer,
  achDebitAuthorizationFlow: achDebitAuthorizationFlowReducer,
  achAccounts: achAccountsReducer,
})

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer
